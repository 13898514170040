import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import update from 'immutability-helper';
import {
	keyAgentAdmin,
	keyAgentAdminList,
	keyAgentAreas,
	keyAgentNew,
	keyAgentAdminSalutations,
	keyAgentAdminSignatures,
	keyAddAgentMP,
	keyDeleteReviewAddress,
	keyDeleteReviewAgents,
	keyGetOneReviewAddress,
	keyGetReviewAddresses,
	keyGetReviewAgents,
	keyRemoveAgentMP,
	keyAddAgentAvatar,
	keyRemoveAgentAvatar,
	keyAgentWordlist,
	keyEditAgentWordlist,
	keyGetOneTemplate,
	keyGetSignatures,
	keyGetSalutations,
	keyGetTemplates,
	keyGetOneSignature,
	keyGetOneSalutation,
	keyGetAddressbook,
	keyGetErrandInternalState,
	keyGetOneErrandInternalState,
	keyGetExternalExpertAddressList,
	keyGetAccountFacebook,
	keyGetOneAccountFacebook,
	keyGetAccountTwitter,
	keyGetOneAccountTwitter,
	keyGetAccountInstagram,
	keyGetOneAccountInstagram,
	keyGetAccountLine,
	keyGetOneAccountLine,
	keyGetAccountLinkedIn,
	keyGetOneAccountLinkedIn,
	keyGetAccountJira,
	keyGetOneAccountJira,
	keyGetAccountHubSpot,
	keyGetOneAccountHubSpot,
	keyGetAccountMSTeams,
	keyGetOneAccountMSTeams,
	keyGetAccountGoogleReview,
	keyGetOneAccountGoogleReview,
	keyGetAccountGoogleChat,
	keyGetOneAccountGoogleChat,
	keyGetAccountGooglePlay,
	keyGetOneAccountGooglePlay,
	keyGetAccountSMPP,
	keyGetOneAccountSMPP,
	keyGetAccountTrustpilot,
	keyGetOneAccountTrustpilot,
	keyGetAccountTelegram,
	keyGetOneAccountTelegram,
	keyGetAccountWhatsApp,
	keyGetOneAccountWhatsApp,
	keyGetAccountTwilio,
	keyGetOneAccountTwilio,
	keyGetAccountViber,
	keyGetOneAccountViber,
	keyGetAccountSlack,
	keyGetOneAccountSlack,
	keyGetAccountYoutube,
	keyGetOneAccountYoutube,
	keyGetAccountEmail,
	keyGetOneAccountEmail,
	keyGetFileArchive,
	keyGetRouteKeywords,
	keyGetOneRouteKeywords,
	keyGetRouteAutoTags,
	keyGetOneRouteAutoTags,
	keyGetRouteSip,
	keyGetOneRouteSip,
	keyGetRoutingGroups,
	keyGetOneRoutingGroups,
	keyGetAdminGroups,
	keyGetOneAdminGroups,
	keyGetGroupFolders,
	keyGetOneGroupFolders,
	keyGetGroupAgents,
	keyGetOneGroupAgents,
	keyGetCORSWhitelist,
	keyGetOneCORSWhitelist,
	keyGetChatIPAddressBlacklist,
	keyGetCallRecordings,
	keyGetOneCallRecordings,
	keyGetAgentSipLogins,
	keyGetOneAgentSipLogin,
	keyGetOneSipTrunk,
	keyGetSipPriorityNumbers,
	keyGetOneSipPrioNumber,
	keyGetContactCard,
	keyGetOneContactCard,
	keyGetContactCardNotes,
	keyRemoveAvatarEEAddressList,
	keyRemoveKnowledgeBaseNode,
	keySaveReviewAddress,
	keySaveAgentForReview,
	keySaveTemplate,
	keyUploadReviewAddressFile,
	keyAgentActivate,
	keyAgentDeactivate,
	keyAgentUnlock,
	keyAgentUploadList,
	keyAutoTagUploadList,
	keyAgentImportStatus,
	keyAgentValidate,
	keyGetOneFileArchive,
	keyGetCompanyList,
	keyGetConfigChangeLog,
	keyAddNewCompany,
	keyGetStunTurnList,
	keyGetChatWidgetCfgList,
	keyGetChatWidgetCfgDefList,
	keyGetFaqWidgetCfgList,
	keyGetFaqWidgetCfgDefList,
	keyGetVoiceWidgetCfgList,
	keyGetVoiceWidgetCfgDefList,
	keyAgentSipStatus,
	keyFetchAgentSipStatus,
	keyUpdateAgentSipStatus,
	keyUpdateStunTurnStatus,
	keyClassifier,
	keyFetchClassifier,
	keyUpdateClassifier,
	keyGetTags,
	keyGetTagsList,
	keyGetOneQuickReply,
	keyGetQuickReplies,
	keyGetOrganisations,
	keyAgentEmailAvailability,
	keyGetCallbackAPIList,
	keyGetJWTList,
	keyCallIVRList,
	keyCallSipTrunkList,
	keyCallIVRPromptUpload,
	keyCallIVRMOHUpload,
	keyTwoFANewSecret,
	keyVerifyTwoFAToken,
	keyGetSkills,
	keyGetSkillsCategory,
	keyGetSkillProficiency,
	keyGetSkillAgents,
	keyGetSkillAreas,
	keyAgentAssist,
	keyGetGenerativeAIDocument,
	keyLanguageList,
	keyPostGenerativeAIDocument,
	keyGetBlacklist,
	keyGetBlacklistResp,
	keyGetWhitelist,
	keyGetToolManager
} from '../constants/keys';
import {
	ADMIN_INSERT_EDITOR_CONTENT
	, FILTER_ADMIN_LIST
	, GET_ADMIN_DATA_FROM_LIST
	, RESET_ADMIN_INPUT
	, SET_ADMIN_INPUT
	, SET_ADMIN_LIST_FILTER
	, SET_ADMIN_VIEW
	, SET_AGENT_AVATAR_PREVIEW
	, SET_AGENT_INPUT
	, TOGGLE_ADMIN_EDIT
	, TOGGLE_ADMIN_FORM_TYPE
	, SET_ADMIN_COLUMN_WIDTH
	, TOGGLE_ADMIN_SIDEBAR
	, TOGGLE_AGENT_EDIT
	, UPLOAD_AGENT_IN_PROGRESS
	, SET_ADMIN_CURRENT_SORT_FIELD
	, SET_ADMIN_CURRENT_SORT_ORDER
	, SET_AGENT_SOUND_OUTPUT_LIST
	, RESET_EMAIL_AVAILABILITY
	, APPEND_SUB_TAGS
	, REMOVE_SUB_TAGS
	, ADMIN_ACTION_ALERT
	, SHOW_MERGE_CONTACT_CARD_POPUP
	, SHOW_ADMIN_HIGHLIGHT
	, SIP_TRUNK_SET_MATCH
	, CALL_UPDATE_IVR_CALLFLOW
	, CALL_UPDATE_IVR_CALLFLOW_VIEW
	, CALL_REMOVE_IVR_KEY
	, UPDATE_AGENT_SKILL
	, ADMIN_ACTION_STATUS
	, RESET_FILTER_ADMIN
} from '../constants/constants';
import { allOrgAreasToAreaIDsString, csvStringToIntArray } from '../../common/helpers';
import { filePathPrefix, isBlacklist, isRoutingView, isWhitelist } from '../../common/v5/helpers';
import {
	UNSELECT,
	ADMIN_VIEW_MAP,
	M_ERRANDINTERNALSTATE,
	M_ROUTING_KEYWORDS,
	M_REVIEW_AGENTS,
	emptyObject,
	emptyArray,
	FORM_EMPTY,
	UPLOAD_AGENT_FINISH,
	M_ROUTING_AUTOTAGS,
	M_CHATWIDGETDL,
	M_FAQWIDGETDL,
	M_VOICEWIDGETDL,
	M_STUN_TURN,
	BOTTOM_RIGHT,
	WIDGET_SKIN_MODERN,
	FAQ_WIDGET_SKIN_MODERN,
	WIDGET_SKIN_FRICTIONLESS,
	M_AGENTSIPSTATUS,
	WHATSAPP_INTERACTIVE,
	INTERACTIVE_LIST,
	INTERACTIVE_BUTTON,
	QUICK_REPLY_TEMPLATE,
	QUICK_REPLY_MESSAGE,
	M_QUICK_REPLY,
	QUICK_REPLY_MESSAGE_TEXT,
	QUICK_REPLY_TYPE_MESSAGE,
	QUICK_REPLY_MESSAGE_DATA,
	M_TAG,
	M_API_CALLBACK,
	M_API_ACCESSTOKENS,
	M_CALL_IVR,
	IVR_CONNECT_ID_MAP,
	OPT_WHATSAPP_CLOUD_PREMISE,
	CHAT_PROACTIVE_DEFAULTS,
	M_SKILLS,
	M_CALL_RECORDINGS,
	M_GENERATIVE_AI_DOCUMENT,
	M_BLACKLIST,
	M_BLACKLISTRESP,
	M_LLM_TOOL_MANAGER,
	M_WHITELIST,
} from '../../common/v5/constants';
import {
	actionReducerCreator
	, createReducer
	, createSetPayloadReducer
	, defAsyncReducer
	, asyncInitState
	, asyncRequestActionType
	, asyncDoneActionType
	, asyncFailActionType
} from '../util';
import { admin as AdminsMap, stateName, getConnTagsList, getUniqueChannels } from '../selectors/admin';
import { allAndOnlyActiveOrgAreas } from '../selectors/workflow';
import {
	BRANCH_NAME as AGENT_ACCESS_KEY_BRANCH
} from '../selectors/agentAccessKey';
import { BRANCH_NAME as AWSSES_BRANCH } from '../selectors/awsses';
import { BRANCH_NAME as CHATBOT_BRANCH } from '../selectors/chatbot';
import { BRANCH_NAME as RECEIPT_GREETING_BRANCH } from '../selectors/receiptGreeting';
import { BRANCH_NAME as SMTP_BRANCH } from '../selectors/smtp';
import { admin as adminAgentAccessKey } from './agentAccessKey';
import { done as workflowDone } from './workflow';
import { admin as adminChatbot } from './chatbot';
import { admin as adminReceiptGreeting } from './receiptGreeting';
import { admin as adminSmtp } from './smtp';
import { admin as adminAWSSES } from './awsses';

const done = key => asyncDoneActionType(AdminsMap[key]);
const request = key => asyncRequestActionType(AdminsMap[key]);
const fail = key => asyncFailActionType(AdminsMap[key]);

const DEFAULT_CHAT_TRANSLATION_JSON = {
"A copy of the chat will be sent to {CLIENT_EMAIL}": "",
"Agent {AGENT_NAME} is accepting video call from {CLIENT_NAME}": "",
"Agent {AGENT_NAME} is unable to accept screen sharing at the moment.": "",
"Agent {AGENT_NAME} is unable to accept video call at the moment.": "",
"Agent {AGENT_NAME} starting screen sharing with {CLIENT_NAME}": "",
"Agent {AGENT_NAME} stopped a co-browsing session.": "",
"Agent {AGENT_NAME} stopped screen sharing.": "",
"Agent {AGENT_NAME} stopped screen sharing with {CLIENT_NAME}": "",
"Agent {AGENT_NAME} stopped the video call": "",
"Agent is requesting a co-browsing session with you, do you want to accept?": "",
"Agent is typing a message...": "",
"{AGENT_NAME} ended the chat.": "",
"{AGENT_NAME} has left the chat.": "",
"{AGENT_NAME} initiated a co-browsing session.": "",
"{AGENT_NAME} joins the chat.": "",
"Agent(s) added: {AGENT_NAMES}": "",
"Agent(s) removed: {AGENT_NAMES}": "",
"Client ended the chat.": "",
"Client is not accepting video chat.": "",
"Client is not enabling video chat.": "",
"{CLIENT_NAME} activated co-browsing": "",
"{CLIENT_NAME} have requested a callback through phone number {PHONE}": "",
"{CLIENT_NAME} have requested a callback through phone number {PHONE} at {TIME}, we will reach you at the appointed time.": "",
"{CLIENT_NAME} is accepting video call from agent {AGENT_NAME}": "",
"{CLIENT_NAME} is not accepting video call from agent {AGENT_NAME}": "",
"{CLIENT_NAME} is starting a co-browsing with agent {AGENT_NAME}": "",
"{CLIENT_NAME} is unable to accept screen sharing at the moment.": "",
"{CLIENT_NAME} starting screen sharing with agent {AGENT_NAME}": "",
"{CLIENT_NAME} stopped a co-browsing session with agent {AGENT_NAME}": "",
"{CLIENT_NAME} stopped screen sharing": "",
"{CLIENT_NAME} stopped screen sharing with agent {AGENT_NAME}": "",
"{CLIENT_NAME} stopped the video call": "",
"Co-browsing session status": "",
"Do you want to close current co-browsing session ?": "",
"Enter full screen": "",
"Exit full screen": "",
"{FROM_AGENT} forwarded the chat to area {AREA_NAME}": "",
"{FROM_AGENT} forwarded the chat to {TO_AGENT}": "",
"{FROM_AGENT} handover the chat to agent": "",
"Hide video preview": "",
"{NAME} ended the chat.": "",
"* Please allow cookie from {CENTION_BASE_URL} to make sure your chat continues if you browse to other pages during the chat.": "",
"Please hold while we find an agent to assist you. Your current queue position is:": "",
"Reconnecting, please wait ...": "",
"Show video preview": "",
"System": "",
"The agent is requesting a video call with you, do you want to accept?": "",
"The agent is unable to accept screen sharing at the moment": "",
"The agent is unable to accept video call at the moment": "",
"The agent wants to share screen with you, do you want to accept?": "",
"The uploaded file has exceeded the max allowed size.": "",
"This chat has expired.": "",
"Video call request already accepted in another window": "",
"We\"re sorry, an error occurred while uploading the file.": "",
"Write your question...": ""
}

//set default widget colors
const DEFAULT_CHAT_WIDGET_COLORS = {
	headerColor: "#eeeeee",
	headerTextColor: "#000000",
	mainColor: "#FFFFFF",
	fontColor: "#000000",
	warningTextColor: "#da5f62",
	buttonStartColor: "#0000001a",
	buttonStartBorderColor: "#00000000",
	buttonStartTextColor: "#000000",
	buttonAwayColor: "#FFFFFF",
	buttonAwayTextColor: "#000000",
	buttonAwayBorderColor: "#00000033",
	buttonRetryTextColor: "#000000",
	chatConversationBgColor: "#FFFFFF",
	chatConversationTextColor: "#000000",
	buttonToggleMenuColor: "#FFFFFF",
	messageBackgroundColor: "#def8f9",
	messageBackgroundColorAgent: "#f2f2f2",
	chatConversationInfoTextColor: '#888888',
	messageAwayTextColor: "#000000",
	toggleMenuTextColor: "#000000",
	messageSendIconColor: "#000000",
	menuTextColor: "#000000",
	footerTextColor: "#000000",
	askEULATextColor: "#000000",
	feedbackBgColor: "#7fcdffe0",
	feedbackTextColor: "#FFFFFF",
	cookieWarningBgColor: "#F5DEB3",
	cookieWarningTextColor: "#da5f62",
	cookieWarningSymbolColor: "#da5f62",
	feedbackBoxBgColor: '#FFBF19',
	feedbackBoxTitleColor: "#FFFFFF",
	feedbackBoxTextareaBgColor: "#00000033",
	feedbackBoxTextareaTextColor: "#FFFFFF",
	feedbackBoxBtnBgColor: "#FFFFFF",
	feedbackBoxBtnTextColor: "#555555",
	chatConversationTextareaBgColor: "#eaeaea",
	chatConversationTextareaColor: "#000000",
	chatToggleMenuIconsColor: "#000000",
	chatToggleMenuIconsTextColor: "#000000",
	tickUnsentColor: '#D3D3D3',
	tickUnreadColor: '#D3D3D3',
	tickSentColor: '#008000',
	tickReadColor: '#008000',
	proactiveTitleTextColor: '#1f3c56',
	proactiveDescTextColor: '#1f3c56',
	proactiveDialogBgColor: '#FFFFFF',
};

const DEFAULT_FAQ_WIDGET_COLORS = {
	mainBgColor: "#FFFFFF",
	mainTextColor: "#000000",
	headerBgColor: "#FFBF19",
	headerTextColor: "#3F3F3F",
	iconSearchColor: "#808080",
	iconContactColor: "#00000080",
	btnContactBgColor: "#424242", //background color of the contact button
	btnContactTextColor: "#FFFFFF", //text color of the contact button
	btnFeedbackBgColor: "#FFBF19",
	btnFeedbackTextColor: "FFFFFF",
	btnLaunchChatBgColor: "#FFBF19",
	btnLaunchChatTextColor: "#FFFFFF",
	triggerPanelBgColor: "#FFBF19",
	triggerPanelTextColor: "#FFFFFF",
	footerTextColor: "#CCCCCC",
	footerLogoTextColor: "#000000",
	searchBarTextColor: "#000000",
	searchBarBgColor: "#FFFFFF",
	questionListBgColor: "#F0F0F0", //for top questions
	questionListTextColor: "#000000", //for top questions
	libraryListBgColor: "#F0F0F0",
	libraryListTextColor: "#000000",
	tabIconTextColor: "#FFBF19", //icons color on main page
	tabIconBgColor: "#FFF7E5", //icons bg color on main page
	faqChatFooterBgColor: "#FFFFFF",
}

const DEFAULT_CHAT_WIDGET_TEXTS = {
	textButtonStart: "",
	textTitle: "Chat",
	textTitleProactive: "Need a little help?",
	textButtonAway: "Contact us",
	textButtonRetry: "Retry",
	textButtonProactiveStart: "Yes",
	textButtonProactiveLater: "No",
	textAvailableNote: "We're available",
	textNoSelectArea: "Please select",
	textInputName: "Name",
	textInputEmail: "Email",
	textInputPhone: "Phone number",
	textInputQuestion: "Question",
	textScreenSharing: "You are sharing your screen",
	textStatusConnecting: "Connecting...",
	textStatusQueue: "Please wait, an agent will assist you shortly ...",
	textStatusConnected: "You have been connected to",
	textMessageQueueing: "Please hold while we find an agent to assist you. Your current queue position is:",
	textMessageCurrentQueue: "Your current queue position is:",
	textMessageAgentBusy: "We are sorry all our agents are busy at the moment.",
	textMessageAway: "We are sorry either the chat is closed or we don't have any agents available at the moment.",
	textNameInvalid: "You must enter a valid name",
	textMessageEmailInvalid: "You must enter a valid email address",
	textQuestionInvalid: "You must enter a valid question",
	textMessageChatEnded: "Your chat has ended.",
	textMenuSendFile: "Attach",
	textMenuNewChat: "New",
	textMenuFinishChat: "End",
	textMenuPrintChat: "Print",
	textMenuSaveChat: "Save",
	textMenuVideoChat: "Video",
	textTickSent: "✓",
	textTickRead: "✓",
	textEULA: "I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.",
	textCookieWarning: "* Please allow cookie from {CENTION_BASE_URL} to make sure your chat continues if you browse to other pages during the chat.",
	textSatisfactionMessage: "Enjoying the chat? Please rate it.",
	textSatisfactionReceived: "Thank you!",
	textFeedbackButton: "Leave a comment",
	textCommentBoxTitle: "Leave a comment (optional)",
	textFeedbackBtnSend: "Send",
	textFeedbackBtnCancel: "Cancel",
	textContactFormSentNotice: "Thank you <br>Your message has been successfully sent. <br>We will get back to you as soon as possible.",
	textTitleProactive: "Hello, may I help you?",
	textMessageProactive: "Ask me about our products",
	textVideoCallHeader: "Video",
	textMsgWhereToCall: "Where would you like me to call you?",
	textMsgCallNow: "Call me on the phone",
	textMsgCallSchedule: "Schedule a call",
	textMsgToCall: "When to call",
	textBtnSend: "Send request",
	textBtnCancel: "Cancel",
	textDateError: "Please select another date or time"
};

const DEFAULT_FAQ_WIDGET_TEXTS = {
	textPanelTrigger: "Need help?", //text on the trigger panel
	textHeaderTitle: "Hello, how can I help you?", //text on the widget header
	textInputSearch : "Search for a question..", //placeholder label for the search input
	textFeatured : "Frequently asked questions in all categories", //text heading on the featured section
	textBrowseKnowledgebase: "Browse knowledgebase", //text heading on the browse section
	textSearchResult: "Showing all related results for", //heading text of the search
	textBtnContact: "Contact Us", //text label for the contact button on featured page
	textFeedbackQuestion : "Did you get the answer to your question?", //feedback label at bottom of answer
	textBtnUpvote: "Yes", //button label for upvote
	textBtnDownvote: "No - Contact Us", //button label for downvote
	textFeedbackMsg: "Thank you! Glad it helped", //text pop-out after upvote
	textCategoryHeading: "Categories",
	textContactHeading: "Contact Us", //heading text on contact section
	textEULA: 'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.',
	textCookieWarning: "* Please allow cookie from {CENTION_BASE_URL} to make sure your chat continues if you browse to other pages during the chat.",
	//contact no details
	textContactNumTitle: "Contact Us", //text title for call
	textContactNumLine1: "tel-no +60 12345789", //text for contact no
	//operating hours details
	textContactHoursTitle: "Operating Hours",
	textContactHoursLine1: "Monday-Friday: 9-5pm", //time or any description 1
	textContactHoursLine2: "Saturday-Sunday: 9-5pm", //time or any description 2
	//chat details
	textContactChatTitle: "Chat with Us", //text title for chat
	textFAQChatLauncher: "Open Chat", // text for chat launcher
	textContactChatLine1: "Weekdays: 9 - 5pm", //time or any description 1
	textContactChatLine2: "Saturday-Sunday: 00:00-12:00", //time or any description 2
	//email details
	textContactEmailTitle: "Email Us", // text tittle for email
	textContactEmailLine1: "support@example.com",
	textContactEmailLine2: "We will get to you as soon as we can in 24 hours",
	textContactEmailLine3: "",
	//not found items
	textListNotFoundDesc1: "Oops! It looks like there is nothing available at the moment",
	textListNotFoundDesc2: "Not to worry, you can still contact us",
	textButtonStart: "",
	//top buttons/links
	textFAQButton1:"TOP FAQ",
	textFAQButton2:"Browse",
	textFAQButton3:"Chat",
	//for callback request
	textCallbackTitle: 'Request a call back',
	textCallbackWorkingHours: "Monday - Friday: 9:00am - 6:00pm",
	textCallbackInputName: "Name",
	textCallbackInputEmail: "Email",
	textCallbackInputQuestion: "Question",
	textCallbackInputPhone: "Phone",
	textNameInvalid: "You must enter a valid name",
	textMessageEmailInvalid: "You must enter a valid email address",
	textMsgToCall: "When to call",
	textBtnSend: "Send request",
	textBtnCancel: "Cancel",
	textDateError: "Please select another date or time",
	textMsgSendSuccess: 'You have requested a callback through phone number at {SCHEDULE_TIME}, we will reach you at the appointed time.',
}

const DEFAULT_CHAT_TRANSLATION = JSON.stringify(DEFAULT_CHAT_TRANSLATION_JSON)
.replace('{"', '"').replace('"}', '"').split("\",").join("\",\n");

const uiInitState = {
	view: "",
	showSideBar: true,
	currentSortField: "id",
	currentSortOrder: "asc"
};

const agentInputsInitState = {
	id: 0,
	loginName: "",
	userName: "",
	password: "",
	phoneNumber: "",
	markAsCentionUser: false,
	adminLevel: 3,
	avatarLocalName: "",
	avatarContentType: "",
	email: "",
	solidusAgentID: 0,
	doQuickSearch: false,
	quickSearchAgentReferenceCode: "",
	externalID: "",
	brightAgentReferenceCode: "",
	sipDeviceId: 0,
	sipExtensionId: 0,
	sipPassword: "",
	hotkeys: 0,
	hotkeys_markers: 0,
	hotkeys_ctrl: 0,
	hotkeys_alt: 0,
	hotkeys_shift: 0,
	hotkeys_meta: 0,
	salutationPreview: 0,
	previewSummary: false,
	signaturePreview: 0,
	preferredArea: "",
	preferredManualArea: 0,
	showOnlyWarnings: 0,
	salutation: 0,
	signature: 0,
	signatureName: "",
	chatName: "",
	playSound: 0,
	allowedChatAmount: 0,
	chatMessagePreview: 0,
	openExternalSystem: 0,
	interfaceLanguage: 1,
	spellingLanguage: 1,
	sortHistoryAscending: 1,
	timezoneID:14,
	autoInsertPublishedAnswer: 0,
	listSize: 10,
	inboxPreview: 0,
	inboxPreviewShowInstantly: 0,
	inboxPreviewTimeDelay: 0,
	allowedIp: "",
	blueBubbleNotification: 1,
	browserOsNotification: 0,
	autoAnswer: false,
	autoPickSuggestion: false,
	automatedConversationBot: 0,
	automatedConversationBotLibraries: '',
	// darkMode: 0,
	darkModeAppearance: 0,
	selectedSipRingOutput: "",
	twofa_flag: 0,
	twofa_secret: "",
	skills: [],
};

const emptyInput = {
	keywords: ""
	, reviewType: 1
	, emailAddress: ""
	, selectedArea: []
	, selectedChannel: UNSELECT
	, selectedErrandInternalState: ""
	, routeSipId: ""
	, cbEnabled: true
	, jwtCreateErrandChannel: 0
	, jwtCreateErrandAcc: 0
	, jwtCreateErrandArea: 0
	, jwtAPIVersion: 1
	, jwtAllowAttachment: false
	, jwtAllowAccessLib: false
	, jwtProgressStatus: false
	, jwtErrandData: false
	, jwtCreateCustContact: false
	, jwtIntegrateAgentLogin: false
	, jwtAccessExportAPI: false
	, jwtLogioAPI: false
	, jwtStatsAPI: false
	, jwtChatMessages: false
	, proactivePopupType: 1 //1: portrait, 2: landscape
	, proactiveTitleTextColor: '#1f3c56'
	, proactiveDescTextColor: '#1f3c56'
	, proactiveDialogBgColorPicker: '#FFFFFF'
	, ivrName: ""
	, ivrDesc: ""
	, ivrConnLevel: 0
	, ivrPromptId: "0"
	, ivrQueueMusicFile: ""
	, ivrQueueMusicFileDownload: ""
	, ivrOffhoursMsgFile: ""
	, ivrOffhoursMsgFileDownload: ""
	, ivrBreakhoursMsgFile: ""
	, ivrBreakhoursMsgFileDownload: ""
	, ivrCallflow: [{
		level: 0,
		id: "0",
		parentid: "",
		type: "singledtmfprompt",
		prompt: "", //node greeting file name
		promptdownload: "",
		dtmftobehere: "",
		actiontype: "dtmf",
		connecttoqueue: "",
		name: "",
		description: ""
	}]
	, siptrunkmatches: []
	, prionumberid: 0
	, sipprionumber: ""
	, prionumberactive: false
	, api: true
	, waBusinessId: ""
	, skillName: ""
	, skillDesc: ""
	, skillCategoryId: 0
};

const intSections = [
	{ id: 1, value: 1, title:"", description:"" },
	{ id: 2, value: 2, title:"", description:"" },
	{ id: 3, value: 3, title:"", description:"" },
	{ id: 4, value: 4, title:"", description:"" },
	{ id: 5, value: 5, title:"", description:"" },
	{ id: 6, value: 6, title:"", description:"" },
	{ id: 7, value: 7, title:"", description:"" },
	{ id: 8, value: 8, title:"", description:"" },
	{ id: 9, value: 9, title:"", description:"" },
	{ id: 10, value: 10, title:"", description:"" }
      ];


const btnSections = [
	{ id: 1, value: 1, title:"", type:"" },
	{ id: 2, value: 2, title:"", type:"" },
	{ id: 3, value: 3, title:"", type:"" },
	{ id: 4, value: 4, title:"", type:"" },
	{ id: 5, value: 5, title:"", type:"" },
	{ id: 6, value: 6, title:"", type:"" },
	{ id: 7, value: 7, title:"", type:"" },
	{ id: 8, value: 8, title:"", type:"" },
	{ id: 9, value: 9, title:"", type:"" },
	{ id: 10, value: 10, title:"", type:"" }
      ];

const emptyInteractive = {
	isInteractive: false
	, types: 0
	, header: ""
	, body: ""
	, footer:""
	, numberOption: 1
	, buttonText: ""
	, sectionTitle:""
	, sections: intSections
	, buttons: btnSections
	, id: 0 //create new
};

const quickReplyMsgData = {
	id: 0,
	contentType: QUICK_REPLY_MESSAGE_TEXT, //by default text
	//isFBTemplate: false,
	numberOption: 1,
	title: "",
	messageList: intSections,
	templateList: intSections
};
const emptyQuickReply = {
	id: 0,
	name: "",
	types: QUICK_REPLY_TYPE_MESSAGE, //type text,phone, email
	msgData: quickReplyMsgData,
	archiveImages: [],
};

const adminInitState = {
	activeId: "",
	show: false,
	showInline: false,
	showList: true,
	input: emptyInput,
	dataSrc: {},
	contactNotesList: [],
	insertionText: "",
	filter: {
		selectedLangFilter: "",
		selectedAreaFilter: "", //unset
		selectedErrandInternalState: "",
		wordFilter: "",
		agentTypeFilter: "",
		selectedAgentFilter: "",
		agentSipStatusFilter: 0,
		dateFromFilter:"",
		dateToFilter:"",
		lastFieldChange:""
	},
	langSrc: [],
	areaList: [],
	formType: FORM_EMPTY,
	columnWidth: "",
	//agents
	selectedAreaSalutationList: [],
	selectedAreaSignatureList: [],
	showMergeContactPopup: false,
	highlight: false,
	avatarPreview: "",
	uploadingAgent: UPLOAD_AGENT_FINISH,
	validLoginName: "",
	//whatsapp interactive
	interactive: emptyInteractive,
	listOfSoundOutputDevices: [],
	quickReply: emptyQuickReply,
	agentEmailAvailability: true,
	adminAlert: {
		show: false,
		type: "",
		msg: ""
	},
	adminStatus: {
		status: 0,
		msg: "initial status"
	}
};

const agentInitState = {
	showEdit: false,
	prefMode: false,
	selectedAreaSalutationList: [],
	selectedAreaSignatureList: [],
	avatarPreview: "",
	input: agentInputsInitState
};

const [ adminActionMap, adminReducerMap ] = actionReducerCreator([
	[SET_ADMIN_LIST_FILTER, ({ field, value }) => ({filter: {[field]: {$set: value}}})]
]);

export const adminStateActionMap = adminActionMap;

const uiReducers = (st = uiInitState, act) => {
	const p = act.payload;
	if(act.type === TOGGLE_ADMIN_SIDEBAR) {
		return update(st, {showSideBar: {$set: p}});
	}else if(act.type === SET_ADMIN_VIEW) {
		return update(st, {
			view: {$set: p},
			currentSortField: {$set: "id"}
		});
	} else if(act.type === SET_ADMIN_CURRENT_SORT_FIELD){
		return update (st,{currentSortField: {$set: p}})
	} else if(act.type === SET_ADMIN_CURRENT_SORT_ORDER){
		return update (st,{currentSortOrder: {$set: p}})
	}
	return st;
}

const createAdminEditReducer = (field, plainField) => (state, { payload }) => {
	if (!payload.data) {
		return state;
	}
	const { data } = payload;
	return update(state, {
		input: {
			selectedArea: {$set: data.activeAreas}
			, name: {$set: data[field].name}
			, plainContent: {$set: data[field][plainField]}
			, htmlContent: {$set: data[field].htmlContent}
		}
		, interactive: {$set: data[field].interactive ? data[field].interactive : emptyInteractive}
		, langSrc: {$set: data[field].langs ? data[field].langs : emptyArray}
		, archiveImages: {$set: data[field].archiveImages ? data[field].archiveImages : emptyArray}
	});
};

const createPersonalisationEditReducer = field => createAdminEditReducer(field, "content")

const editReviewAddressReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.areas}
		, keywords: {$set: data.keywords}
	}});
};

const fileArchiveEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.selectedAreas},
		fileArchiveName: {$set: data.archive.fileName},
		fileArchiveDescription: {$set: data.archive.description}
	}});
}

const adminAccountEmailEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		name: {$set: data.name},
		accountTypes: {$set: data.accountType},
		username: {$set: data.username},
		host: {$set: data.host},
		enabled: {$set: data.enabled},
		id: {$set: data.id},
		normalizedAccountType: {$set: data.normalizedAccountType},
		wantSSL: {$set: data.wantSSL},
		emailAddressSearchTerm: {$set: data.emailAddressSearchTerm},
		contentTransformation: {$set: data.contentTransformation},
		contentTransformationSubject: {$set: data.contentTransformationSubject},
		typeId: {$set: data.typeId},
		retainFetchedEmail: {$set: data.retainFetchedEmail},
		allowImapDelete: {$set: data.allowImapDelete},
		fetchSeenMessages: {$set: data.fetchSeenMessages},
		allowDelEmptyEmail: {$set: data.allowDelEmptyEmail},
		centionDelete: {$set: data.centionDelete},
		readTimeout: {$set: data.readTimeout},
		useSecureMessage: {$set: data.useSecureMessage},
		},
		showInline: {$set: true},
		dataSrc: {$set: data},
	});
}
const adminAccountLineEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		// selectedArea: {$set: parseInt(data.connectedArea)},
		// connectedArea: {$set: parseInt(data.connectedArea)},
		areas: {$set: data.areas},
		id: {$set: data.accountId},
		channelID: {$set: data.channelID},
		channelSecret: {$set: data.channelSecret},
		enable: {$set: data.enable},
		name: {$set: data.name},
		token: {$set: data.token},
		},
		showInline: {$set: true},
	});
}

const adminAccountFacebookEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	let canFetchAd = false, canFetchEvent = false, canFetchPM = true, canFetchWall = true

	if(data.id) {
		canFetchAd = data.canFetchAd
		canFetchEvent = data.canFetchEvent
		canFetchPM = data.canFetchPM
		canFetchWall = data.canFetchWall
	}

	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		areas: {$set: data.areas},
		id: {$set: data.id},
		canFetchAd: {$set: canFetchAd},
		canFetchEvent: {$set: canFetchEvent},
		canFetchPM: {$set: canFetchPM},
		canFetchWall: {$set: canFetchWall},
		adAccountId: {$set: data.adAccountId},
		enable: {$set: data.enable},
		name: {$set: data.name},
		pageID: {$set: data.pageID},
		pageName: {$set: data.pageName},
		},
		showInline: {$set: true},
	});
}
const adminAccountInstagramEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		areas: {$set: data.areas},
		id: {$set: data.id},
		accountID: {$set: data.accountID},
		accountName: {$set: data.accountName},
		enable: {$set: data.enable},
		name: {$set: data.name},
		},
		showInline: {$set: true},
	});
}
const adminAccountLinkedInEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		areas: {$set: data.areas},
		id: {$set: data.id},
		accountName: {$set: data.accountName},
		companyId: {$set: data.companyId},
		companyName: {$set: data.companyName},
		enable: {$set: data.enable},
		name: {$set: data.name},
		},
		showInline: {$set: true},
	});
}
const adminAccountJiraEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		accountName: {$set: data.accountName},
		accountId: {$set: data.accountId},
		areas: {$set: data.areas},
		cloud: {$set: data.cloud},
		enable: {$set: data.enable},
		id: {$set: data.id},
		jiraUrl: {$set: data.jiraUrl},
		name: {$set: data.name},
		priority: {$set: data.priority},
		token: {$set: data.token},
		type: {$set: data.type},
		userAccountID: {$set: data.userAccountID},
		userName: {$set: data.userName},
		},
		showInline: {$set: true},
	});
}
const adminAccountHubSpotEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		areas: {$set: data.areas},
		enable: {$set: data.enable},
		id: {$set: data.id},
		name: {$set: data.name},
		ownerEmail: {$set: data.ownerEmail},
		ownerName: {$set: data.ownerName},
		},
		showInline: {$set: true},
	});
}
const adminAccountMSTeamsEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
			selectedArea: {$set: data.connectedArea},
			connectedArea: {$set: data.connectedArea},
			accountId: {$set: data.accountId},
			areas: {$set: data.areas},
			enable: {$set: data.enable},
			id: {$set: data.id},
			name: {$set: data.name},
			ownerEmail: {$set: data.ownerEmail},
			ownerName: {$set: data.ownerName},
			appId: {$set: data.appId},
			tenantId: {$set: data.tenantId},
			secretId: {$set: data.secretId},
		},
		showInline: {$set: true},
	});
}
const adminAccountGoogleReviewEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		channelId: {$set: data.channelId},
		channelName: {$set: data.channelName},
		areas: {$set: data.areas},
		enable: {$set: data.enable},
		id: {$set: data.id},
		name: {$set: data.name},
		},
		showInline: {$set: true},
	});
}
const adminAccountGoogleChatEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		areas: {$set: data.areas},
		enable: {$set: data.enable},
		id: {$set: data.id},
		name: {$set: data.name},
		path: {$set: data.path},
		},
		showInline: {$set: true},
	});
}
const adminAccountGooglePlayEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		areas: {$set: data.areas},
		enable: {$set: data.enable},
		id: {$set: data.id},
		name: {$set: data.name},
		packagename: {$set: data.packagename},
		channelName: {$set: data.channelName}, //todo:seems not used/relevant? probably can remove
		},
		showInline: {$set: true},
	});
}
const adminAccountTrustpilotEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		accountName: {$set: data.accountName},
		areas: {$set: data.areas},
		enable: {$set: data.enable},
		id: {$set: data.id},
		name: {$set: data.name},
		},
		showInline: {$set: true},
	});
}
const adminAccountTelegramEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		accountId: {$set: data.accountId},
		accountName: {$set: data.accountName},
		areas: {$set: data.areas},
		enable: {$set: data.enable},
		id: {$set: data.id},
		name: {$set: data.name},
		token: {$set: data.token},
		},
		showInline: {$set: true},
	});
}
const adminAccountWhatsAppEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		accountId: {$set: data.accountId},
		areas: {$set: data.areas},
		enable: {$set: data.enable},
		expireAt: {$set: data.expireAt},
		name: {$set: data.name},
		notificationInterval: {$set: data.notificationInterval},
		password: {$set: data.password},
		phone: {$set: data.phone},
		token: {$set: data.token},
		url: {$set: data.url},
		api: {$set: data.api !== undefined ? data.api : true},
		waBusinessId: {$set: data.waBusinessId},
		username: {$set: data.username},
		},
		showInline: {$set: true},
	});
}
const adminAccountSMPPEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data || typeof data.smppServer === 'undefined') {
		return state;
	}
	let s = data.smppServer;
	return update(state, {input: {
		smppRoles: {$set: data.smppRoles},
		selectedRole: {$set: data.selectedRole},
		hostAddress: {$set: s.hostAddress},
		name: {$set: s.name},
		portNumber: {$set: s.portNumber},
		loginName: {$set: s.loginName},
		password: {$set: s.password},
		systemType: {$set: s.systemType},
		id: {$set: s.id},
		},
		showInline: {$set: true},
	});
}
const adminAccountTwilioEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.area},
		connectedArea: {$set: data.area},
		// accountName: {$set: data.accountName},
		area: {$set: data.area},
		enable: {$set: data.enable},
		friendlyName: {$set: data.friendlyName},
		id: {$set: data.id},
		inbound: {$set: data.inbound},
		name: {$set: data.name},
		number: {$set: data.number},
		outbound: {$set: data.outbound},
		pid: {$set: data.pid},
		record: {$set: data.record},
		warm: {$set: data.warm},
		},
		showInline: {$set: true},
	});
}
const adminAccountViberEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		accountId: {$set: data.accountId},
		accountName: {$set: data.accountName},
		areas: {$set: data.areas},
		enable: {$set: data.enable},
		id: {$set: data.id},
		name: {$set: data.name},
		token: {$set: data.token},
		webhookUrl: {$set: data.webhookUrl},
		},
		showInline: {$set: true},
	});
}
const adminAccountSlackEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		appId: {$set: data.appId},
		appToken: {$set: data.appToken},
		areas: {$set: data.areas},
		connectedArea: {$set: data.connectedArea},
		selectedArea: {$set: data.connectedArea},
		enabled: {$set: data.enabled},
		id: {$set: data.id},
		name: {$set: data.name},
		webhookUrl: {$set: data.webhookUrl}, //?
		},
		showInline: {$set: true},
	});
}
const adminAccountYoutubeEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		areas: {$set: data.areas},
		channelId: {$set: data.channelId},
		channelName: {$set: data.channelName},
		connectedArea: {$set: data.connectedArea},
		enable: {$set: data.enable},
		id: {$set: data.id},
		name: {$set: data.name},
		selectedArea: {$set: data.connectedArea},
		},
		showInline: {$set: true},
	});
}
const adminAccountTwitterEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	let hashTags = false, canFetchPM = true, canFetchWall = true

	if(data.id) {
		canFetchPM = data.canFetchPM
		canFetchWall = data.canFetchWall
		hashTags = data.hashTags
	}
	return update(state, {input: {
		selectedArea: {$set: data.connectedArea},
		connectedArea: {$set: data.connectedArea},
		areas: {$set: data.areas},
		id: {$set: data.id},
		accountName: {$set: data.accountName},
		enable: {$set: data.enable},
		name: {$set: data.name},
		canFetchPM: {$set: canFetchPM},
		canFetchWall: {$set: canFetchWall},
		hashTags: {$set: hashTags},
		tags: {$set: data.tags}, //hashTagString
	},
		showInline: {$set: true},
	});
}

const routeKeywordsEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.areas}, //TODO: add multiple area support
		content: {$set: data.content},
		type: {$set: data.type},
	}});
}

const routingGroupsEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		areas: {$set: data.areas},
		currentAreas: {$set: data.currentAreas},
		description: {$set: data.description},
		name: {$set: data.name},
		},
		showInline: {$set: true},
	});
}

const routeAutoTagsEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input:{
		selectedArea: {$set: data.areas},
		content: {$set: data.content},
		type: {$set: data.type},
		tagVIPColor: {$set: data.tagColor},
		tagLevel: {$set: data.tagLevel}
	}});
}

const callRecordingsEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	let dt = data.result.data;

	return update(state, {input: {
		agentName: {$set: dt.agentName},
		agentid: {$set: dt.agentid},
		createtime: {$set: dt.createtime},
		download: {$set: dt.download},
		duration: {$set: dt.duration},
		errandid: {$set: dt.errandid},
		description: {$set: data.description},
		name: {$set: dt.name},
		type: {$set: dt.type},
		},
		showInline: {$set: true},
		ex: {$set: true},
	});
}

const callAgentSipLoginEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	let dt = data.result.data;

	return update(state, {input: {
		loginname: {$set: dt.loginname},
		id: {$set: dt.id},
		password: {$set: dt.password},
		description: {$set: dt.description},
		linkedextension: {$set: dt.linkedextension},
		},
	});
}

const callOneSipPrioEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	let dt = data.result.data;

	if(typeof dt === "undefined" || dt == null) {
		return update(state, {input: {
			prionumberid: {$set: "0"},
			sipprionumber: {$set: ""},
			prionumberactive: {$set: false},
			},
		});
	}
	return update(state, {input: {
		prionumberid: {$set: dt[0].id},
		sipprionumber: {$set: dt[0].sipnumber},
		prionumberactive: {$set: dt[0].active},
		},
	});
}

const callSipTrunkEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	let dt = data.result.data;

	if(typeof dt === "undefined" || dt == null) {
		return update(state, {input: {
			id: {$set: "0"},
			trunkname: {$set: ""},
			outboundcallerid: {$set: ""},
			siptrunkenabled: {$set: false},
			username:{ $set: ""},
			secret: {$set: ""},
			sipserver: {$set: ""},
			serverport: {$set: ""},
			transport: {$set: ""},
			context: {$set: ""},
			codecs: {$set: []},
			identifyby: {$set: ""},
			clienturi: {$set: ""},
			serveruri: {$set: ""},
			outboundproxy: {$set: ""},
			contactuser: {$set: ""},
			sipregenabled: {$set: false},
			registrationretryinterval: {$set: "60"},
			regforbidretryinterval: {$set: "0"},
			regfatalretryinterval: {$set: "0"},
			regexpiration: {$set: "3600"},
			},
		});
	}
	return update(state, {input: {
		id: {$set: dt.id},
		trunkname: {$set: dt.trunkname},
		outboundcallerid: {$set: dt.outboundcallerid},
		siptrunkenabled: {$set: dt.enabled},
		username:{ $set: dt.username},
		secret: {$set: dt.secret},
		sipserver: {$set: dt.sipserver},
		serverport: {$set: dt.serverport},
		transport: {$set: dt.transport},
		context: {$set: dt.context},
		codecs: {$set: dt.codecs},
		siptrunkmatches: {$set: dt.siptrunkmatches},
		identifyby: {$set: dt.identifyby},
		clienturi: {$set: dt.clienturi},
		serveruri: {$set: dt.serveruri},
		outboundproxy: {$set: dt.outboundproxy},
		contactuser: {$set: dt.contactuser},
		sipregenabled: {$set: dt.sipregenabled},
		registrationretryinterval: {$set: dt.registrationretryinterval},
		regforbidretryinterval: {$set: dt.regforbidretryinterval},
		regfatalretryinterval: {$set: dt.regfatalretryinterval},
		regexpiration: {$set: dt.regexpiration},
		},
	});
}

const adminGroupsEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	let dt = data.group;

	return update(state, {input: {
		description: {$set: dt.description},
		name: {$set: dt.name},
		selectedArea: {$set: data.selectedAreas},
		},
		showInline: {$set: true},
	});
}

const adminGroupFolderEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	let dt = data.folder;

	return update(state, {input: {
		folder_description: {$set: dt.description},
		folder_name: {$set: dt.name},
		folder_id: {$set: dt.id},
		},
		showInline: {$set: true},
		folderReady: {$set: true}
	});
}

const contactCardEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (data.customer) {
		let customer = data.customer
		return update(state, {input: {
			name: {$set: customer.name},
			avatar: {$set: customer.avatar},
			company: {$set: customer.company},
			companyName: {$set: customer.companyName},
			id: {$set: customer.id},
			externalId: {$set: customer.externalId},
			city: {$set: customer.city},
			PostCode: {$set: customer.PostCode},
			date: {$set: customer.date},
			channels: {$set: getUniqueChannels(data.list)}
			},
			showInline: {$set: true},
			dataSrc: {$set: data},
		});
	} else {
		return update(state, {
			showInline: {$set: true},
			dataSrc: {$set:data}
		});
	}
}
const contactCardNotesReducer = (state, { payload }) => {
	const { data } = payload;
	if (data.data) {
		let notes = data.data.notes
		return update(state, {
			contactNotesList: {$set: notes}
		});
	}
}

const routeSipEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedArea: {$set: data.areas}
		, routeSipId: {$set: data.routeSipId}
	}});
}

const errandInternalStateEditReducer = (state, { payload }) => {
	const { data } = payload;
	if (!data) {
		return state;
	}
	return update(state, {input: {
		selectedErrandInternalState: {$set: data.data}
	}});
};

const createAdminQuickReplyEditReducer = (field) => (state, { payload }) => {
	if (!payload.data) {
		return state;
	}
	const { data } = payload;
	return update(state, {
		input: {
			selectedArea: {$set: data.activeAreas}
			, name: {$set: data[field].name}
		}
		, quickReply: {$set: data[field]}
	});
};

const salutationEditReducer = createPersonalisationEditReducer("salutation");

const signatureEditReducer = createPersonalisationEditReducer("signature");

const templateEditReducer = createAdminEditReducer("template", "template");
const quickRepliesEditReducer = createAdminQuickReplyEditReducer("quickReply");


//folders
const setFieldValueReducer = (field, value) => state => update(
	state
	, {[field]: {$set: value}}
);

const adminFolderReducer = {
	[request(keyGetOneGroupFolders)]: setFieldValueReducer("folderReady",false)
	, [done(keyGetOneGroupFolders)]: adminGroupFolderEditReducer
	, [fail(keyGetOneGroupFolders)]: setFieldValueReducer("folderReady",true)
	, [request(keyGetGroupFolders)]: setFieldValueReducer("folderListReady",false)
	, [done(keyGetGroupFolders)]: setFieldValueReducer("folderListReady",true)
	, [fail(keyGetGroupFolders)]: setFieldValueReducer("folderListReady",true)
}

const adminGroupAgentReducer = {
	[request(keyGetGroupAgents)]: setFieldValueReducer("groupAgentListReady",false)
	, [done(keyGetGroupAgents)]: setFieldValueReducer("groupAgentListReady",true)
	, [fail(keyGetGroupAgents)]: setFieldValueReducer("groupAgentListReady",true)
}

const getColorOrDefault = (inputData, key) => {
    return inputData && inputData[key] ? inputData[key] : DEFAULT_CHAT_WIDGET_COLORS[key];
};

const getFAQColorOrDefault = (inputData, key) => {
    return inputData && inputData[key] ? inputData[key] : DEFAULT_FAQ_WIDGET_COLORS[key];
};

const getChatTextOrDefault = (inputData, key) => {
	return inputData && inputData[key] ? inputData[key] : DEFAULT_CHAT_WIDGET_TEXTS[key];
}

const getFAQTextOrDefault = (inputData, key) => {
	return inputData && inputData[key] ? inputData[key] : DEFAULT_FAQ_WIDGET_TEXTS[key];
}

const _adminReducers = {
	[ADMIN_INSERT_EDITOR_CONTENT]: createSetPayloadReducer("insertionText")
	, [done(keyGetOneReviewAddress)]: editReviewAddressReducer
	, [done(keyGetOneSignature)]: signatureEditReducer
	, [done(keyGetOneSalutation)]: salutationEditReducer
	, [done(keyGetOneErrandInternalState)]: errandInternalStateEditReducer
	, [done(keyGetOneTemplate)]: templateEditReducer
	, [done(keyGetOneQuickReply)]: quickRepliesEditReducer
	, [done(keyGetOneFileArchive)]: fileArchiveEditReducer
	, [done(keyGetOneAccountEmail)]: adminAccountEmailEditReducer
	, [done(keyGetOneAccountLine)]: adminAccountLineEditReducer
	, [done(keyGetOneAccountLinkedIn)]: adminAccountLinkedInEditReducer
	, [done(keyGetOneAccountJira)]: adminAccountJiraEditReducer
	, [done(keyGetOneAccountHubSpot)]: adminAccountHubSpotEditReducer
	, [done(keyGetOneAccountMSTeams)]: adminAccountMSTeamsEditReducer
	, [done(keyGetOneAccountGoogleReview)]: adminAccountGoogleReviewEditReducer
	, [done(keyGetOneAccountGoogleChat)]: adminAccountGoogleChatEditReducer
	, [done(keyGetOneAccountGooglePlay)]: adminAccountGooglePlayEditReducer
	, [done(keyGetOneAccountTrustpilot)]: adminAccountTrustpilotEditReducer
	, [done(keyGetOneAccountTelegram)]: adminAccountTelegramEditReducer
	, [done(keyGetOneAccountWhatsApp)]: adminAccountWhatsAppEditReducer
	, [done(keyGetOneAccountTwilio)]: adminAccountTwilioEditReducer
	, [done(keyGetOneAccountViber)]: adminAccountViberEditReducer
	, [done(keyGetOneAccountSlack)]: adminAccountSlackEditReducer
	, [done(keyGetOneAccountYoutube)]: adminAccountYoutubeEditReducer
	, [done(keyGetOneAccountFacebook)]: adminAccountFacebookEditReducer
	, [done(keyGetOneAccountInstagram)]: adminAccountInstagramEditReducer
	, [done(keyGetOneAccountSMPP)]: adminAccountSMPPEditReducer
	, [done(keyGetOneAccountTwitter)]: adminAccountTwitterEditReducer
	, [done(keyGetOneRouteKeywords)]: routeKeywordsEditReducer
	, [done(keyGetOneRouteAutoTags)]: routeAutoTagsEditReducer
	, [done(keyGetOneRoutingGroups)]: routingGroupsEditReducer
	, [done(keyGetOneCallRecordings)]: callRecordingsEditReducer
	, [done(keyGetOneAgentSipLogin)]: callAgentSipLoginEditReducer
	, [done(keyGetOneSipPrioNumber)]: callOneSipPrioEditReducer
	, [done(keyGetOneSipTrunk)]: callSipTrunkEditReducer
	, [done(keyGetOneAdminGroups)]: adminGroupsEditReducer
	, [done(keyGetOneContactCard)]: contactCardEditReducer
	, [done(keyGetContactCardNotes)]: contactCardNotesReducer
	, [done(keyGetOneRouteSip)]: routeSipEditReducer
	, [workflowDone(keyAgentAreas)]: (state, { payload }) => {
		const { data, param } = payload
			, { active } = allAndOnlyActiveOrgAreas(data.areas, param)
			;
		return update(state, {filter: {selectedAreaFilter: {
			$set: allOrgAreasToAreaIDsString(active)
		}}});
	}
	, ...adminReducerMap
	, ...adminFolderReducer
	, ...adminGroupAgentReducer
};

const adminReducers = (st = adminInitState, act) => {
	const p = act.payload;
	if(act.type === TOGGLE_ADMIN_EDIT) {
		return update(st, {
			show: {$set: p.pop},
			showInline: {$set: p.show},
			showList: {$set: p.list},
			activeId: {$set: p.id}
		});
	}else if(act.type === SET_ADMIN_VIEW) {
		return update(st, {
			input: {$set: emptyInput},
			langSrc: {$set: []},
			showInline: {$set: false},
			showList: {$set: true}
		});
	}else if(act.type === done(keyEditAgentWordlist)) {
		if(p.data) {
			let inpt = p.data.wordlist, id = st.activeId, langSrc = inpt.langSrc;
			return  update(st, {
				input: {
					language: {$set: inpt.language},
					wordListname: {$set: inpt.name},
					itemId: {$set: id}
				},
				langSrc: {$set: (langSrc ? langSrc : [])}
			});
		}
		return st;
	}else if(act.type === SET_ADMIN_INPUT) {
		const { field, value, key } = p;
		if(field == "selectedArea"){
			if(typeof value === "number"){
				return update(st, {
					input: {selectedArea: {$set: [value]}
						}
					})
			} else if(typeof value === "string") {
				return update(st, {
					input: {selectedArea: {$set: csvStringToIntArray(value)}
						}
					})
			} else if(Array.isArray(value)){
				return update(st, {
					input: {selectedArea: {$set: value}
						}
					})
			}
		}
		if(key === OPT_WHATSAPP_CLOUD_PREMISE){ // mak: seems redundant, already taken care by default handling
			return update(st, {
				input: {
					[field]: {$set: value},
				}
			})
		} else if (key == M_ERRANDINTERNALSTATE){
			return update(st, {
				input: {
					selectedErrandInternalState: {
						[field]: {$set: value}
					}
				}
			})
		} else if (key == M_ROUTING_KEYWORDS) { // mak: seems redundant, already taken care by default handling
			return update(st, {
				input: {
					[field]: {$set: `${value}`},
				}
			})
		} else if (key == M_REVIEW_AGENTS) { // mak: semms redundant, already taken care by default handling
			return update(st, {
				input: {
					[field]: {$set: value},
				}
			})
		} else if (key == M_ROUTING_AUTOTAGS) {
			if(field == "selectedArea"){
				//reset tagLevel value whenever selecting area, to prevent previous tag submission
				return update(st, {
					input: {
						[field]: {$set: `${value}`},
						tagLevel: {$set: ""},
						tagVIPColor: {$set: ""}
					}
				})
			}
			if(field === "tagLevel"){
				return update(st, {
					input: {
						[field]: {$set: `${value}`},
					}
				})
			}
		} else if( key === M_QUICK_REPLY ){
			const { extraKey } = p;
			//key express as super level key
			//extraKey adds here to identify the separation value of QuickReply 
			//second level is either message or template
			if(extraKey === QUICK_REPLY_MESSAGE){
				if(field === "messageList"){
					let msgSections = st.quickReply.msgData.messageList;
					let lsc = []; 
					$.each(msgSections, (k, v)=>{
						let vid = value.id;
						if( v.id === vid ){
							let sec = msgSections[vid-1];
							if(typeof value.name !== 'undefined' && value.name === `title-${vid}`){
								sec.title = value.value;
							}else if (typeof value.name !== 'undefined' && value.name === `description-${vid}`){
								sec.description = value.value;
								sec['origName'] = value.origName;
							}
							lsc.push(sec);
						}else{
							lsc.push(v);
						}
					});
				
					return update(st, {
						quickReply: {
							msgData: {
								messageList: {$set: lsc}
							}
						}
					});
				}
				return update(st, {
					quickReply: {
						msgData: {
							[field]: {$set: value}
						}
					}
				});
			}else if(extraKey === QUICK_REPLY_TEMPLATE){
				if(field === "messageList"){
					let buttons = st.quickReply.msgData.templateList;
					let lb = []; 
					$.each(buttons, (k, v)=>{
						let vid = value.id;
						if( v.id === vid ){
							let sec = buttons[vid-1];
							if(typeof value.name !== 'undefined' && value.name === `title-${vid}`){
								sec.title = value.value;
							}else if (typeof value.name !== 'undefined' && value.name === `types-${vid}`){
								sec.value = value.value;
							}
							lb.push(sec);
						}else{
							lb.push(v);
						}
					});
					return update(st, {
						quickReply: {
							msgData: {
								templateList: {$set: lb}
							}
						}
					});
				}
				return update(st, {quickReply: {
						msgData: {
							[field]: {$set: value}
						}
					}
				});
			}
			return update(st, { quickReply:{ [field]: {$set: value}}});			
		} else if (key === WHATSAPP_INTERACTIVE){
			if(field === INTERACTIVE_LIST){
				let sections = st.interactive.sections;
				let lsc = [];
				$.each(sections, (k, v)=>{
					let vid = value.id;
					if( v.id === vid ){
						let sec = sections[vid-1];
						if(typeof value.name !== 'undefined' && value.name === `title-${vid}`){
							sec.title = value.value;
						}else if (typeof value.name !== 'undefined' && value.name === `description-${vid}`){
							sec.description = value.value;
						}
						lsc.push(sec);
					}else{
						lsc.push(v);
					}
				});
				return update(st, {interactive: {sections: {$set: lsc}}});
			}else if(field === INTERACTIVE_BUTTON){
				let buttons = st.interactive.buttons;
				let lb = [];
				$.each(buttons, (k, v)=>{
					let vid = value.id;
					if( v.id === vid ){
						let sec = buttons[vid-1];
						if(typeof value.name !== 'undefined' && value.name === `title-${vid}`){
							sec.title = value.value;
						}else if (typeof value.name !== 'undefined' && value.name === `types-${vid}`){
							sec.type = value.value;
						}
						lb.push(sec);
					}else{
						lb.push(v);
					}
				});
				return update(st, {interactive: {buttons: {$set: lb}}});
			}
			return update(st, { interactive:{ [field]: {$set: value}}});
		} else if(field === "resetWidget"){
			if(key == M_CHATWIDGETDL){
				return update(st, {
					input: {
						headerColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.headerColor},
						headerTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.headerTextColor},
						mainColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.mainColor},
						warningTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.warningTextColor},
						buttonStartColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonStartColor},
						buttonStartBorderColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonStartBorderColor},
						buttonStartTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonStartTextColor},
						buttonAwayColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonAwayColor},
						buttonAwayTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonAwayTextColor},
						buttonAwayBorderColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonAwayBorderColor},
						buttonAwayCustomClass: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonAwayCustomClass},
						buttonRetryTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonRetryTextColor},
						buttonToggleMenuColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonToggleMenuColor},
						messageBackgroundColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.messageBackgroundColor},
						messageAwayTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.messageAwayTextColor},
						toggleMenuTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.toggleMenuTextColor},
						messageSendIconColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.messageSendIconColor},
						footerTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.footerTextColor},
						askEULATextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.askEULATextColor},
						feedbackBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBgColor},
						feedbackTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackTextColor},
						tickUnsentColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.tickUnsentColor},
						tickUnreadColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.tickUnreadColor},
						tickSentColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.tickSentColor},
						tickReadColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.tickReadColor},
						chatConversationBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationBgColor},
						chatConversationTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationTextColor},
						chatConversationTextareaBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationTextareaBgColor},
						chatConversationTextareaColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationTextareaColor},
						chatConversationInfoTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationInfoTextColor},
						chatToggleMenuIconsColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatToggleMenuIconsColor},
						chatToggleMenuIconsTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatToggleMenuIconsTextColor},
						messageBackgroundColorAgent: {$set: DEFAULT_CHAT_WIDGET_COLORS.messageBackgroundColorAgent},
						cookieWarningBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.cookieWarningBgColor},
						cookieWarningTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.cookieWarningTextColor},
						cookieWarningSymbolColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.cookieWarningSymbolColor},
						feedbackBoxBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxBgColor},
						feedbackBoxTitleColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxTitleColor},
						feedbackBoxTextareaBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxTextareaBgColor},
						feedbackBoxTextareaTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxTextareaTextColor},
						feedbackBoxBtnBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxBtnBgColor},
						feedbackBoxBtnTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxBtnTextColor},
						proactiveTitleTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.proactiveTitleTextColor},
						proactiveDescTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.proactiveDescTextColor},
						proactiveDialogBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.proactiveDialogBgColor},
					}
				});
			} else if(key == M_FAQWIDGETDL){
				//todo @sue,
				//for faq skin frictionless, the background for tab button is transparent by default
				return update(st, {
					input: {
						mainBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.mainBgColor},
						mainTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.mainTextColor},
						headerBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.headerBgColor},
						headerTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.headerTextColor},
						iconSearchColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.iconSearchColor},
						iconContactColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.iconContactColor},
						btnContactBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnContactBgColor},
						btnContactTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnContactTextColor},
						btnFeedbackBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnFeedbackBgColor},
						btnFeedbackTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnFeedbackTextColor},
						btnLaunchChatBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnLaunchChatBgColor},
						btnLaunchChatTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnLaunchChatTextColor},
						triggerPanelBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.triggerPanelBgColor},
						triggerPanelTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.triggerPanelTextColor},
						footerTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.footerTextColor},
						searchBarTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.searchBarTextColor},
						searchBarBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.searchBarBgColor},
						questionListBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.questionListBgColor},
						questionListTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.questionListTextColor},
						libraryListBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.libraryListBgColor},
						libraryListTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.libraryListTextColor},
						tabIconTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.tabIconTextColor},
						tabIconBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.tabIconBgColor},
						faqChatFooterBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.faqChatFooterBgColor},
					}
				});
			} else if(key == M_VOICEWIDGETDL){
				return update(st, {
					input: {
						headerColor: {$set: ""},
						headerTextColor: {$set: ""},
						mainColor: {$set: ""},
						warningTextColor: {$set: ""},
						footerTextColor: {$set: ""},
						askEULATextColor: {$set: ""},
					}
				});
			}
		}
		return update(st, {
			input: {
				[field]: {$set: value}
			}
		})
	}else if(act.type === RESET_ADMIN_INPUT) {
		return update(st, {
			input: {$set: emptyInput},
			selectedAreaSalutationList: {$set: emptyArray},
			selectedAreaSignatureList: {$set: emptyArray},
			avatarPreview: {$set: emptyArray},
		});
	}else if(act.type === done(keyGetAddressbook)){
		return update(st, {
			input: {
				addressBookName: {$set: ""},
				selectedChannel: {$set: Workflow.Errand.SERVICE_EMAIL},
				addressBookEntry: {$set: ""}
			}
		});
	}else if(act.type === GET_ADMIN_DATA_FROM_LIST) {
		const {id, list, view} = p;
		let inputData;
		if(id === 0){
			if(view === ADMIN_VIEW_MAP["addressbook-personal"] || view === ADMIN_VIEW_MAP["addressbook"]) {
				return update(st, {
					input: {
						addressBookName: {$set: ""},
						selectedChannel: {$set: Workflow.Errand.SERVICE_EMAIL},
						addressBookEntry: {$set: ""}
					}
				});
			}else if(view === ADMIN_VIEW_MAP["externalexpert-personal"] || view === ADMIN_VIEW_MAP["externalexpert"]){
				return update(st, {
					input: {
						selectedArea: {$set: []},
						emailAddress: {$set:""},
						questionType: {$set:""},
						eeAvatar: {$set: ""},
						serviceType: {$set: 0},
						avatarPreview: {$set: ""}
					}
				});
			}else if(view === ADMIN_VIEW_MAP["companies"]) {
				return update(st, {
					input: {
						companyName: {$set: ""},
						companyEmail: {$set: ""},
						companyAddress: {$set: ""},
						companyCity: {$set: ""},
						companyZipcode: {$set: ""},
						companyPhone: {$set: ""},
						companyMobile: {$set: ""},
						companyFax: {$set: ""}
					}
				});
			}else if(view == M_CHATWIDGETDL){
				if(list.length > 0){
					let data = list[0];
					if(data.id == id){
						inputData = data;
					}
				}
				let allActiveAreas = "";
				if(typeof inputData !== "undefined"){
					allActiveAreas = inputData.allActiveAreas;
				}
				return update(st, {
					input: {
						id: {$set: 0},
						name: {$set: ""},
						activeAreas: {$set: ""},
						allActiveAreas: {$set: allActiveAreas},
						baseURL: {$set: ""},
						widgetId: {$set: ""},
						customize: {$set: false},
						widgetPosition: {$set: BOTTOM_RIGHT},
						chatZIndex: {$set: ""},
						integrityAttr: {$set: false},
						adminChatMaximize: {$set: false},
						adminChatMinimize: {$set: false},
						adminWidgetLoad: {$set: false},
						logo: {$set: ""},
						headerColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.headerColor},
						headerTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.headerTextColor},
						mainColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.mainColor},
						warningTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.warningTextColor},
						buttonStartColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonStartColor},
						buttonStartBorderColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonStartBorderColor},
						buttonStartTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonStartTextColor},
						buttonAwayColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonAwayColor},
						buttonAwayTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonAwayTextColor},
						buttonAwayBorderColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonAwayBorderColor},
						buttonAwayCustomClass: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonAwayCustomClass},
						buttonRetryTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonRetryTextColor},
						buttonToggleMenuColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.buttonToggleMenuColor},
						messageBackgroundColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.messageBackgroundColor},
						messageAwayTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.messageAwayTextColor},
						toggleMenuTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.toggleMenuTextColor},
						messageSendIconColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.messageSendIconColor},
						footerTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.footerTextColor},
						askEULATextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.askEULATextColor},
						feedbackBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBgColor},
						feedbackTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackTextColor},
						tickUnsentColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.tickUnsentColor},
						tickUnreadColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.tickUnreadColor},
						tickSentColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.tickSentColor},
						tickReadColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.tickReadColor},
						chatConversationBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationBgColor},
						chatConversationTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationTextColor},
						chatConversationTextareaBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationTextareaBgColor},
						chatConversationTextareaColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationTextareaColor},
						chatConversationInfoTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatConversationInfoTextColor},
						chatToggleMenuIconsColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatToggleMenuIconsColor},
						chatToggleMenuIconsTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.chatToggleMenuIconsTextColor},
						messageBackgroundColorAgent: {$set: DEFAULT_CHAT_WIDGET_COLORS.messageBackgroundColorAgent},
						cookieWarningBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.cookieWarningBgColor},
						cookieWarningTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.cookieWarningTextColor},
						cookieWarningSymbolColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.cookieWarningSymbolColor},
						feedbackBoxBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxBgColor},
						feedbackBoxTitleColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxTitleColor},
						feedbackBoxTextareaBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxTextareaBgColor},
						feedbackBoxTextareaTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxTextareaTextColor},
						feedbackBoxBtnBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxBtnBgColor},
						feedbackBoxBtnTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.feedbackBoxBtnTextColor},
						proactiveTitleTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.proactiveTitleTextColor},
						proactiveDescTextColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.proactiveDescTextColor},
						proactiveDialogBgColor: {$set: DEFAULT_CHAT_WIDGET_COLORS.proactiveDialogBgColor},
						textCookieWarning: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textCookieWarning},
						tickKerningOffset: {$set: ""},
						textTitle: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textTitle},
						textAvailableNote:{$set: DEFAULT_CHAT_WIDGET_TEXTS.textAvailableNote},
						textTitleProactive: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textTitleProactive},
						textButtonAway: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textButtonAway},
						textButtonRetry: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textButtonRetry},
						textButtonProactiveStart: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textButtonProactiveStart},
						textButtonProactiveLater: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textButtonProactiveLater},
						textInputName: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textInputName},
						textInputEmail: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textInputEmail},
						textInputPhone: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textInputPhone},
						textInputQuestion: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textInputQuestion},
						textNoSelectArea: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textNoSelectArea},
						textStatusConnecting: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textStatusConnecting},
						textStatusQueue: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textStatusQueue},
						textStatusConnected: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textStatusConnected},
						textMessageQueueing: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMessageQueueing},
						textMessageCurrentQueue: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMessageCurrentQueue},
						textMessageAgentBusy: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMessageAgentBusy},
						textMessageAway: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMessageAway},
						textMessageEmailInvalid: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMessageEmailInvalid},
						textMessageChatEnded: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMessageChatEnded},
						textMessageProactive: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMessageProactive},
						textMenuSendFile: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMenuSendFile},
						textMenuNewChat: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMenuNewChat},
						textMenuFinishChat: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMenuFinishChat},
						textMenuPrintChat: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMenuPrintChat},
						textMenuSaveChat: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMenuSaveChat},
						textMenuVideoChat: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textMenuVideoChat},
						textTickSent: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textTickSent},
						textTickRead: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textTickRead},
						textEULA: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textEULA},
						textButtonStart: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textButtonStart},
						messengerChat: {$set: ""},
						whatsAppChat: {$set: ""},
						telegramChat: {$set: ""},
						textSatisfactionMessage: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textSatisfactionMessage},
						textSatisfactionReceived: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textSatisfactionReceived},
						textFeedbackButton: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textFeedbackButton},
						textCommentBoxTitle: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textCommentBoxTitle},
						textFeedbackBtnSend: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textFeedbackBtnSend},
						textFeedbackBtnCancel: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textFeedbackBtnCancel},
						textContactFormSentNotice: {$set: DEFAULT_CHAT_WIDGET_TEXTS.textContactFormSentNotice},
						adminChatButtonAwayEnable: {$set: false},
						buttonAwayLink: {$set: ""},
						bottomPosition: {$set: "0"},
						leftPosition: {$set: "0"},
						adminChatExtraFieldEnabled: {$set: false},
						adminChatExtraFieldMandatory: {$set: false},
						adminChatQuestionFieldMandatory: {$set: false},
						extraFieldDisplayName: {$set: ""},
						adminChatChatRatingsEnabled: {$set: false},
						adminChatProactiveEnable: {$set: CHAT_PROACTIVE_DEFAULTS.enable},
						proactiveDelayTime: {$set: CHAT_PROACTIVE_DEFAULTS.delayTime},
						proactiveTimeAfterDecline: {$set: CHAT_PROACTIVE_DEFAULTS.timeAfterDecline},
						proactiveRepeatedContact: {$set: CHAT_PROACTIVE_DEFAULTS.repeatedContact},
						proactiveTimeout: {$set: CHAT_PROACTIVE_DEFAULTS.timeout},
						adminChatProactiveImmediateStart: {$set: CHAT_PROACTIVE_DEFAULTS.immediateStart},
						proactivePopupType: {$set: CHAT_PROACTIVE_DEFAULTS.popupType},
						proactiveImageURL: {$set: CHAT_PROACTIVE_DEFAULTS.imageURL},
						adminChatAskEULA: {$set: false},
						adminChatHideChatWhenOffline: {$set: false},
						adminChatShowMenuAsDefault: {$set: false},
						adminChatNoPreselectArea: {$set: false},
						adminChatShowAllAreaList: {$set: false},
						adminChatShowContactFormWhenAway: {$set: false},
						adminChatEnableVideoChat: {$set: false},
						adminChatEnableCallbackRequest: {$set: false},
						adminChatHideAgentAvatarList: {$set: false},
						adminChatFullScreenOnSmallScreen: {$set: false},
						translation: {$set: DEFAULT_CHAT_TRANSLATION},
						skin: { $set: ( cflag.IsActive("2023-11-23.CEN-1888-new-chat-widget-skin") ? WIDGET_SKIN_FRICTIONLESS : WIDGET_SKIN_MODERN ) }
					}
				});
			}else if(view == M_FAQWIDGETDL){
				if(list.length > 0){
					let data = list[0];
					if(data.id == id){
						inputData = data;
					}
				}
				let allActiveAreas = "";
				if(typeof inputData !== "undefined"){
					allActiveAreas = inputData.allActiveAreas;
				}
				return update(st, {
					input: {
						id: {$set: 0},
						name: {$set: ""},
						activeAreas: {$set: ""},
						allActiveAreas: {$set: allActiveAreas},
						activeLibraries: {$set: ""},
						topList: {$set: true},
						topListLimit: {$set: "5"},
						topListMinVotes: {$set: "0"},
						browseLibrary: {$set: true},
						featuredFirstLibrary: {$set: false},
						skin: {$set: FAQ_WIDGET_SKIN_MODERN},
						faqWithChat: {$set: true},
						baseURLFAQ: {$set: ""},
						widgetId: {$set: ""},
						customize: {$set: true},
						mainBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.mainBgColor},
						mainTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.mainTextColor},
						headerBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.headerBgColor},
						headerTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.headerTextColor},
						iconSearchColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.iconSearchColor},
						iconContactColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.iconContactColor},
						btnContactBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnContactBgColor},
						btnContactTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnContactTextColor},
						btnFeedbackBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnFeedbackBgColor},
						btnFeedbackTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnFeedbackTextColor},
						btnLaunchChatBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnLaunchChatBgColor},
						btnLaunchChatTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.btnLaunchChatTextColor},
						triggerPanelBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.triggerPanelBgColor},
						triggerPanelTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.triggerPanelTextColor},
						footerTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.footerTextColor},
						textPanelTrigger: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textPanelTrigger},
						textHeaderTitle: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textHeaderTitle},
						textInputSearch: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textInputSearch},
						textFeatured: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textFeatured},
						textBrowseKnowledgebase: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textBrowseKnowledgebase},
						textSearchResult: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textSearchResult},
						textBtnContact: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textBtnContact},
						textFeedbackQuestion: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textFeedbackQuestion},
						textBtnUpvote: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textBtnUpvote},
						textBtnDownvote: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textBtnDownvote},
						textFeedbackMsg: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textFeedbackMsg},
						textCategoryHeading: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textCategoryHeading},
						textContactHeading: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactHeading},
						textEULAFAQ: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textEULAFAQ},
						textButtonStartFAQ: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textButtonStartFAQ},
						textContactNumTitle: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactNumTitle},
						textContactNumLine1: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactNumLine1},
						textContactHoursTitle:{$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactHoursTitle},
						textContactHoursLine1: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactHoursLine1},
						textContactHoursLine2: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactHoursLine2},
						textContactChatTitle: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactChatTitle},
						textFAQChatLauncher: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textFAQChatLauncher},
						textContactChatLine1: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactChatLine1},
						textContactChatLine2: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactChatLine2},
						textContactEmailTitle: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactEmailTitle},
						textContactEmailLine1: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactEmailLine1},
						textContactEmailLine2: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactEmailLine2},
						textContactEmailLine3: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textContactEmailLine3},
						textListNotFoundDesc1: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textListNotFoundDesc1},
						textListNotFoundDesc2: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textListNotFoundDesc2},
						textFAQButton1: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textFAQButton1},
						textFAQButton2: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textFAQButton2},
						textFAQButton3: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textFAQButton3},
						messengerFAQ: {$set: ""},
						whatsAppFAQ: {$set: ""},
						telegramFAQ: {$set: ""},
						textCallbackTitle: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textCallbackTitle},
						textCallbackWorkingHours: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textCallbackWorkingHours},
						textCallbackInputName: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textCallbackInputName},
						textCallbackInputEmail: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textCallbackInputEmail},
						textCallbackInputPhone: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textCallbackInputPhone},
						textCallbackInputQuestion: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textCallbackInputQuestion},
						textNameInvalid: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textNameInvalid},
						textMessageEmailInvalid: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textMessageEmailInvalid},
						textMsgToCall: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textMsgToCall},
						textBtnSend: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textBtnSend},
						textBtnCancel: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textBtnCancel},
						textDateError: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textDateError},
						textMsgSendSuccess: {$set: DEFAULT_FAQ_WIDGET_TEXTS.textMsgSendSuccess},
						adminFaqHasChat: {$set: true},
						adminFaqShowContactPage: {$set: true},
						adminFaqShowMenuAsDefaultFAQ: {$set: false},
						adminFaqAskEULAFAQ: {$set: false},
						adminFaqButtonAwayEnableFAQ: {$set: false},
						adminFaqChatRatingsEnabledFAQ: {$set: false},
						adminFaqEnableVideoChatFAQ: {$set: false},
						adminFaqEnableCallbackRequest: {$set: false},
						adminFaqHideAgentAvatarList: {$set: false},
						adminFaqFullScreenOnSmallScreen: {$set: false},
						position: {$set: "bottomRight"},
						faqZIndex: {$set: ""},
						integrityAttr: {$set: false},
						adminChatMaximize: {$set: false},
						adminChatMinimize: {$set: false},
						adminWidgetLoad: {$set: false},
						translationFAQ: {$set: ""},
						searchBarTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.searchBarTextColor},
						searchBarBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.searchBarBgColor},
						questionListBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.questionListBgColor},
						questionListTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.questionListTextColor},
						libraryListBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.libraryListBgColor},
						libraryListTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.libraryListTextColor},
						tabIconTextColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.tabIconTextColor},
						tabIconBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.tabIconBgColor},
						faqChatFooterBgColor: {$set: DEFAULT_FAQ_WIDGET_COLORS.faqChatFooterBgColor},
					}
				});
			} else if(view == M_VOICEWIDGETDL){
				if(list.length > 0){
					let data = list[0];
					if(data.id == id){
						inputData = data;
					}
				}
				let allActiveAreas = "";
				if(typeof inputData !== "undefined"){
					allActiveAreas = inputData.allActiveAreas;
				}
				return update(st, {
					input: {
						id: {$set: 0},
						name: {$set: ""},
						activeAreas: {$set: ""},
						allActiveAreas: {$set: allActiveAreas},
						baseURL: {$set: ""},
						customize: {$set: false},
						widgetPosition: {$set: BOTTOM_RIGHT},
						voiceZIndex: {$set: ""},
						integrityAttr: {$set: false},
						adminChatMaximize: {$set: false},
						adminChatMinimize: {$set: false},
						adminWidgetLoad: {$set: false},
						logo: {$set: ""},
						headerColor: {$set: ""},
						headerTextColor: {$set: ""},
						mainColor: {$set: ""},
						warningTextColor: {$set: ""},
						askEULATextColor: {$set: ""},
						footerTextColor: {$set: ""},
						textTitle: {$set: ""},
						textInputName: {$set: ""},
						textInputEmail: {$set: ""},
						textInputPhone: {$set: ""},
						textInputQuestion: {$set: ""},
						textDateError: {$set: ""},
						textMsgSendSuccess: {$set: ""},
						textEULAVoice: {$set: ""},
						bottomPosition: {$set: "0"},
						leftPosition: {$set: "0"},
						rightPosition: {$set: "0"},
						translation: {$set: ""},
						askEULA: {$set: false}
					}
				});
			} else if(view === M_TAG){
				const currLvl = st.input.level;
				return update(st, {
					input: {
						id: {$set: 0},
						level: {$set: (currLvl > 1 ? currLvl : 1)},
						parentId: {$set: 0},
						tagName: {$set: ""},
						tagVIP: {$set: false},
						tagColor: {$set: ""},
						tagDelete: {$set: false},
						selectedArea: {$set: ""},
						connectedTagsLen: {$set: 0},
						connectedTagsList: {$set: []},
						connectedTags: {$set: []},
						newSubTags: {$set: []},
					}
				});
			} else if(view === M_CALL_IVR) {
				return update(st, {
					input: {
						id: {$set: 0},
						ivrConnLevel: {$set: 0},
					}
				});
			} else if(view === M_GENERATIVE_AI_DOCUMENT) {
				return update(st, {
					input: {
						docName: {$set: ""},
						docUrl: {$set: ""},
						docDescription: {$set: ""},
						selectedLanguage: {$set: 0},
						selectedArea: {$set: emptyArray},
					}
				});
			}else if(view === M_BLACKLIST) {
				return update(st, {
					input: {
						id: {$set: 0},
						content: {$set: ""},
						selectedArea: {$set: emptyArray},
					}
				});
			}else if(view === M_BLACKLISTRESP) {
				return update(st, {
					input: {
						id: {$set: 0},
						selectedArea: {$set:emptyArray},
						selectedLanguage: {$set: 0},
						response: {$set: ""},
					}
				});
			} else if(view === M_LLM_TOOL_MANAGER) {
				return update(st, {
					input: {
					  id: { $set: 0 }, 
					  content: { $set: "" },
					  selectedArea: { $set: "" },
					  toolName: { $set: "" },
					  toolDescription: { $set: "" },
					  parameters: { $set: [] }, 
					}
				});
			} else if(view === M_WHITELIST) {
				return update(st, {
					input: {
						id: {$set: 0},
						selectedArea: {$set:emptyArray},
						selectedLanguage: {$set: 0},
						docName: {$set: ""},
						content: {$set: ""},
					}
				});
			}
		}else {
			if(list && list.length > 0) {
				for(let i = 0; i< list.length; i++){
					let data = list[i];
					if(data.id == id){
						inputData = data;
						break;
					}
				}
				if(typeof inputData !== "undefined"){
					if(view === ADMIN_VIEW_MAP["addressbook-personal"] || view === ADMIN_VIEW_MAP["addressbook"]) {
						return update(st, {
							input: {
								addressBookName: {$set: inputData.name},
								selectedChannel: {$set: (inputData.service == 0 ? Workflow.Errand.SERVICE_EMAIL : inputData.service)},
								addressBookEntry: {$set: inputData.address}
							}
						});
					}else if(view === ADMIN_VIEW_MAP["externalexpert-personal"] || view === ADMIN_VIEW_MAP["externalexpert"]){
						let avatarPreviewUrl = "";
						if(inputData.eeAvatar){
							avatarPreviewUrl =  inputData.eeAvatar;
						}
						return update(st, {
							input: {
								selectedArea: {$set: inputData.connectedArea},
								emailAddress: {$set: inputData.emailAddress},
								questionType: {$set: inputData.questionType},
								eeAvatar: {$set: inputData.eeAvatar},
								selectedChannel: {$set: inputData.serviceType},
								avatarPreview: {$set: avatarPreviewUrl}
							}
						});
					}else if(view === ADMIN_VIEW_MAP["companies"]) {
						return update(st, {
							input: {
								companyName: {$set: inputData.companyName},
								companyEmail: {$set: inputData.companyEmail},
								companyAddress: {$set: inputData.companyAddress},
								companyCity: {$set: inputData.companyCity},
								companyZipcode: {$set: inputData.companyZipcode},
								companyPhone: {$set: inputData.companyPhone},
								companyMobile: {$set: inputData.companyMobile},
								companyFax: {$set: inputData.companyFax}
							}
						});
					}else if(view === M_STUN_TURN){
						return update(st, {
							input: {
								id: {$set: inputData.id},
								stunturnname: {$set: inputData.name},
								stunturnurl: {$set: inputData.url},
								active: {$set: inputData.status},
								stunturnusername: {$set: inputData.stunturnusername},
								stunturnpassword: {$set: inputData.stunturnpassword},
								stunturnservertype: {$set: inputData.stunturnservertype},
								selectedWebRtcService: {$set: inputData.selectedWebRtcService}
							}
						});
					}else if(view === M_CHATWIDGETDL){
						return update(st, {
							input: {
								id: {$set: inputData.id},
								name: {$set: inputData.Name},
								activeAreas: {$set: inputData.activeAreas},
								allActiveAreas: {$set: inputData.allActiveAreas},
								baseURL: {$set: inputData.baseURL},
								widgetId: {$set: inputData.widgetId},
								customize: {$set: inputData.customize},
								widgetPosition: {$set: (inputData.widgetPosition ? inputData.widgetPosition : BOTTOM_RIGHT)},
								chatZIndex: {$set: inputData.chatZIndex},
								integrityAttr: {$set: inputData.integrityAttr},
								adminChatMaximize: {$set: inputData.adminChatMaximize},
								adminChatMinimize: {$set: inputData.adminChatMinimize},
								adminWidgetLoad: {$set: inputData.adminWidgetLoad},
								logo: {$set: inputData.logo},
								headerColor: {$set: getColorOrDefault(inputData, "headerColor")},
								headerTextColor: {$set: getColorOrDefault(inputData, "headerTextColor")},
								mainColor: {$set: getColorOrDefault(inputData, "mainColor")},
								warningTextColor: {$set: getColorOrDefault(inputData, "warningTextColor")},
								buttonStartColor: {$set: getColorOrDefault(inputData, "buttonStartColor")},
								buttonStartBorderColor: {$set: getColorOrDefault(inputData, "buttonStartBorderColor")},
								buttonStartTextColor: {$set: getColorOrDefault(inputData, "buttonStartTextColor")},
								buttonAwayColor: {$set: getColorOrDefault(inputData, "buttonAwayColor")},
								buttonAwayTextColor: {$set: getColorOrDefault(inputData, "buttonAwayTextColor")},
								buttonAwayBorderColor: {$set: getColorOrDefault(inputData, "buttonAwayBorderColor")},
								buttonAwayCustomClass: {$set: inputData.buttonAwayCustomClass},
								buttonRetryTextColor: {$set: getColorOrDefault(inputData, "buttonRetryTextColor")},
								buttonToggleMenuColor: {$set: getColorOrDefault(inputData, "buttonToggleMenuColor")},
								messageBackgroundColor: {$set: getColorOrDefault(inputData, "messageBackgroundColor")},
								messageAwayTextColor: {$set: getColorOrDefault(inputData, "messageAwayTextColor")},
								toggleMenuTextColor: {$set: getColorOrDefault(inputData, "toggleMenuTextColor")},
								messageSendIconColor: {$set: getColorOrDefault(inputData, "messageSendIconColor")},
								footerTextColor: {$set: getColorOrDefault(inputData, "footerTextColor")},
								askEULATextColor: {$set: getColorOrDefault(inputData, "askEULATextColor")},
								feedbackBgColor: {$set: getColorOrDefault(inputData, "feedbackBgColor")},
								feedbackTextColor: {$set: getColorOrDefault(inputData, "feedbackTextColor")},
								tickUnsentColor: {$set: getColorOrDefault(inputData, "tickUnsentColor")},
								tickUnreadColor: {$set: getColorOrDefault(inputData, "tickUnreadColor")},
								tickSentColor: {$set: getColorOrDefault(inputData, "tickSentColor")},
								tickReadColor: {$set: getColorOrDefault(inputData, "tickReadColor")},
								chatConversationBgColor: {$set: getColorOrDefault(inputData, "chatConversationBgColor")},
								chatConversationTextColor: {$set: getColorOrDefault(inputData, "chatConversationTextColor")},
								chatConversationTextareaBgColor: {$set: getColorOrDefault(inputData, "chatConversationTextareaBgColor")},
								chatConversationTextareaColor: {$set: getColorOrDefault(inputData, "chatConversationTextareaColor")},
								chatConversationInfoTextColor: {$set: getColorOrDefault(inputData, "chatConversationInfoTextColor")},
								chatToggleMenuIconsColor: {$set: getColorOrDefault(inputData, "chatToggleMenuIconsColor")},
								chatToggleMenuIconsTextColor: {$set: getColorOrDefault(inputData, "chatToggleMenuIconsTextColor")},
								messageBackgroundColorAgent: {$set: getColorOrDefault(inputData, "messageBackgroundColorAgent")},
								cookieWarningBgColor: {$set: getColorOrDefault(inputData, "cookieWarningBgColor")},
								cookieWarningTextColor: {$set: getColorOrDefault(inputData, "cookieWarningTextColor")},
								cookieWarningSymbolColor: {$set: getColorOrDefault(inputData, "cookieWarningSymbolColor")},
								feedbackBoxBgColor: {$set: getColorOrDefault(inputData, "feedbackBoxBgColor")},
								feedbackBoxTitleColor: {$set: getColorOrDefault(inputData, "feedbackBoxTitleColor")},
								feedbackBoxTextareaBgColor: {$set: getColorOrDefault(inputData, "feedbackBoxTextareaBgColor")},
								feedbackBoxTextareaTextColor: {$set: getColorOrDefault(inputData, "feedbackBoxTextareaTextColor")},
								feedbackBoxBtnBgColor: {$set: getColorOrDefault(inputData, "feedbackBoxBtnBgColor")},
								feedbackBoxBtnTextColor: {$set: getColorOrDefault(inputData, "feedbackBoxBtnTextColor")},
								proactiveTitleTextColor: {$set: getColorOrDefault(inputData, "proactiveTitleTextColor")},
								proactiveDescTextColor: {$set: getColorOrDefault(inputData, "proactiveDescTextColor")},
								proactiveDialogBgColor: {$set: getColorOrDefault(inputData, "proactiveDialogBgColor")},
								textCookieWarning: {$set: getChatTextOrDefault(inputData, "textCookieWarning")},
								tickKerningOffset: {$set: inputData.tickKerningOffset},
								textTitle: {$set: getChatTextOrDefault(inputData, "textTitle")},
								textTitleProactive: {$set: getChatTextOrDefault(inputData, "textTitleProactive")},
								textAvailableNote:{$set: getChatTextOrDefault(inputData, "textAvailableNote")},
								textButtonAway: {$set: getChatTextOrDefault(inputData, "textButtonAway")},
								textButtonRetry: {$set: getChatTextOrDefault(inputData, "textButtonRetry")},
								textButtonProactiveStart: {$set: getChatTextOrDefault(inputData, "textButtonProactiveStart")},
								textButtonProactiveLater: {$set: getChatTextOrDefault(inputData, "textButtonProactiveLater")},
								textInputName: {$set: getChatTextOrDefault(inputData, "textInputName")},
								textInputEmail: {$set: getChatTextOrDefault(inputData, "textInputEmail")},
								textInputPhone: {$set: getChatTextOrDefault(inputData, "textInputPhone")},
								textInputQuestion: {$set: getChatTextOrDefault(inputData, "textInputQuestion")},
								textNoSelectArea: {$set: getChatTextOrDefault(inputData, "textNoSelectArea")},
								textStatusConnecting: {$set: getChatTextOrDefault(inputData, "textStatusConnecting")},
								textStatusQueue: {$set: getChatTextOrDefault(inputData, "textStatusQueue")},
								textStatusConnected: {$set: getChatTextOrDefault(inputData, "textStatusConnected")},
								textMessageQueueing: {$set: getChatTextOrDefault(inputData, "textMessageQueueing")},
								textMessageCurrentQueue: {$set: getChatTextOrDefault(inputData, "textMessageCurrentQueue")},
								textMessageAgentBusy: {$set: getChatTextOrDefault(inputData, "textMessageAgentBusy")},
								textMessageAway: {$set: getChatTextOrDefault(inputData, "textMessageAway")},
								textMessageEmailInvalid: {$set: getChatTextOrDefault(inputData, "textMessageEmailInvalid")},
								textMessageChatEnded: {$set: getChatTextOrDefault(inputData, "textMessageChatEnded")},
								textMessageProactive: {$set: getChatTextOrDefault(inputData, "textMessageProactive")},
								textMenuSendFile: {$set: getChatTextOrDefault(inputData, "textMenuSendFile")},
								textMenuNewChat: {$set: getChatTextOrDefault(inputData, "textMenuNewChat")},
								textMenuFinishChat: {$set: getChatTextOrDefault(inputData, "textMenuFinishChat")},
								textMenuPrintChat: {$set: getChatTextOrDefault(inputData, "textMenuPrintChat")},
								textMenuSaveChat: {$set: getChatTextOrDefault(inputData, "textMenuSaveChat")},
								textMenuVideoChat: {$set: getChatTextOrDefault(inputData, "textMenuVideoChat")},
								textTickSent: {$set: getChatTextOrDefault(inputData, "textTickSent")},
								textTickRead: {$set: getChatTextOrDefault(inputData, "textTickRead")},
								textEULA: {$set: getChatTextOrDefault(inputData, "textEULA")},
								textButtonStart: {$set: getChatTextOrDefault(inputData, "textButtonStart")},
								messengerChat: {$set: inputData.messengerChat},
								whatsAppChat: {$set: inputData.whatsAppChat},
								telegramChat: {$set: inputData.telegramChat},
								textSatisfactionMessage: {$set: getChatTextOrDefault(inputData, "textSatisfactionMessage")},
								textSatisfactionReceived: {$set: getChatTextOrDefault(inputData, "textSatisfactionReceived")},
								textFeedbackButton: {$set: getChatTextOrDefault(inputData, "textFeedbackButton")},
								textCommentBoxTitle: {$set: getChatTextOrDefault(inputData, "textCommentBoxTitle")},
								textFeedbackBtnSend: {$set: getChatTextOrDefault(inputData, "textFeedbackBtnSend")},
								textFeedbackBtnCancel: {$set: getChatTextOrDefault(inputData, "textFeedbackBtnCancel")},
								textContactFormSentNotice: {$set: getChatTextOrDefault(inputData, "textContactFormSentNotice")},
								textMsgWhereToCall: {$set: getChatTextOrDefault(inputData, "textMsgWhereToCall")},
								textMsgCallNow: {$set: getChatTextOrDefault(inputData, "textMsgCallNow")},
								textMsgCallSchedule: {$set: getChatTextOrDefault(inputData, "textMsgCallSchedule")},
								textMsgToCall: {$set: getChatTextOrDefault(inputData, "textMsgToCall")},
								textBtnSend: {$set: getChatTextOrDefault(inputData, "textBtnSend")},
								textBtnCancel: {$set: getChatTextOrDefault(inputData, "textBtnCancel")},
								textMsgSendCallbackDone: {$set: getChatTextOrDefault(inputData, "textMsgSendCallbackDone")},
								textDateError: {$set: getChatTextOrDefault(inputData, "textDateError")},
								adminChatButtonAwayEnable: {$set: inputData.buttonAwayEnable},
								buttonAwayLink: {$set: inputData.buttonAwayLink},
								bottomPosition: {$set: inputData.bottomPosition},
								leftPosition: {$set: inputData.leftPosition},
								adminChatExtraFieldEnabled: {$set: inputData.extraFieldEnabled},
								adminChatExtraFieldMandatory: {$set: inputData.extraFieldMandatory},
								adminChatQuestionFieldMandatory: {$set: inputData.questionFieldMandatory},
								extraFieldDisplayName: {$set: inputData.extraFieldDisplayName},
								adminChatChatRatingsEnabled: {$set: inputData.chatRatingsEnabled},
								adminChatProactiveEnable: {$set: inputData.proactiveEnable},
								proactiveDelayTime: {$set: inputData.proactiveDelayTime},
								proactiveTimeAfterDecline: {$set: inputData.proactiveTimeAfterDecline},
								proactiveRepeatedContact: {$set: inputData.proactiveRepeatedContact},
								proactiveTimeout: {$set: inputData.proactiveTimeout},
								adminChatProactiveImmediateStart: {$set: inputData.proactiveImmediateStart},
								proactivePopupType: {$set: inputData.proactivePopupType},
								proactiveImageURL: {$set: inputData.proactiveImageURL},
								adminChatAskEULA: {$set: inputData.askEULA},
								adminChatHideChatWhenOffline: {$set: inputData.hideChatWhenOffline},
								adminChatShowMenuAsDefault: {$set: inputData.showMenuAsDefault},
								adminChatNoPreselectArea: {$set: inputData.avoidPreselectArea},
								adminChatShowAllAreaList: {$set: inputData.showAllAreaList},
								adminChatShowContactFormWhenAway: {$set: inputData.showContactFormWhenAway},
								adminChatEnableVideoChat: {$set: inputData.enableVideoChat},
								adminChatEnableCallbackRequest: {$set: inputData.enableCallbackRequest},
								adminChatHideAgentAvatarList: {$set: inputData.hideAgentAvatarList},
								adminChatFullScreenOnSmallScreen: {$set: inputData.chatFullScreenOnSmallScreen},
								adminFaqFullScreenOnSmallScreen: {$set: inputData.faqFullScreenOnSmallScreen},
								translation: {$set: inputData.translation},
								skin: {$set: inputData.skin}
							}
						});
					}else if(view === M_FAQWIDGETDL){
						return update(st, {
							input: {
								id: {$set: inputData.id},
								name: {$set: inputData.Name},
								activeAreas: {$set: inputData.activeAreas},
								allActiveAreas: {$set: inputData.allActiveAreas},
								activeLibraries: {$set: inputData.activeLibraries},
								topList: {$set: inputData.topList},
								topListLimit: {$set: inputData.topListLimit},
								topListMinVotes: {$set: inputData.topListMinVotes},
								browseLibrary: {$set: inputData.browseLibrary},
								featuredFirstLibrary: {$set: inputData.featuredFirstLibrary},
								skin: {$set: inputData.skin},
								faqWithChat: {$set: inputData.faqWithChat},
								baseURLFAQ: {$set: inputData.baseURL},
								widgetId: {$set: inputData.widgetId},
								customize: {$set: inputData.customize},
								mainBgColor: {$set: getFAQColorOrDefault(inputData, "mainBgColor")},
								mainTextColor: {$set: getFAQColorOrDefault(inputData, "mainTextColor")},
								headerBgColor: {$set: getFAQColorOrDefault(inputData, "headerBgColor")},
								headerTextColor: {$set: getFAQColorOrDefault(inputData, "headerTextColor")},
								iconSearchColor: {$set: getFAQColorOrDefault(inputData, "iconSearchColor")},
								iconContactColor: {$set: getFAQColorOrDefault(inputData, "iconContactColor")},
								btnContactBgColor: {$set: getFAQColorOrDefault(inputData, "btnContactBgColor")},
								btnContactTextColor: {$set: getFAQColorOrDefault(inputData, "btnContactTextColor")},
								btnFeedbackBgColor: {$set: getFAQColorOrDefault(inputData, "btnFeedbackBgColor")},
								btnFeedbackTextColor: {$set: getFAQColorOrDefault(inputData, "btnFeedbackTextColor")},
								btnLaunchChatBgColor: {$set: getFAQColorOrDefault(inputData, "btnLaunchChatBgColor")},
								btnLaunchChatTextColor: {$set: getFAQColorOrDefault(inputData, "btnLaunchChatTextColor")},
								triggerPanelBgColor: {$set: getFAQColorOrDefault(inputData, "triggerPanelBgColor")},
								triggerPanelTextColor: {$set: getFAQColorOrDefault(inputData, "triggerPanelTextColor")},
								footerTextColor: {$set: getFAQColorOrDefault(inputData, "footerTextColor")},
								textPanelTrigger: {$set: getFAQTextOrDefault(inputData, "textPanelTrigger")},
								textHeaderTitle: {$set: getFAQTextOrDefault(inputData, "textHeaderTitle")},
								textInputSearch: {$set: getFAQTextOrDefault(inputData, "textInputSearch")},
								textFeatured: {$set: getFAQTextOrDefault(inputData, "textFeatured")},
								textBrowseKnowledgebase: {$set: getFAQTextOrDefault(inputData, "textBrowseKnowledgebase")},
								textSearchResult: {$set: getFAQTextOrDefault(inputData, "textSearchResult")},
								textBtnContact: {$set: getFAQTextOrDefault(inputData, "textBtnContact")},
								textFeedbackQuestion: {$set: getFAQTextOrDefault(inputData, "textFeedbackQuestion")},
								textBtnUpvote: {$set: getFAQTextOrDefault(inputData, "textBtnUpvote")},
								textBtnDownvote: {$set: getFAQTextOrDefault(inputData, "textBtnDownvote")},
								textFeedbackMsg: {$set: getFAQTextOrDefault(inputData, "textFeedbackMsg")},
								textCategoryHeading: {$set: getFAQTextOrDefault(inputData, "textCategoryHeading")},
								textContactHeading: {$set: getFAQTextOrDefault(inputData, "textContactHeading")},
								textEULAFAQ: {$set: getFAQTextOrDefault(inputData, "textEULAFAQ")},
								textButtonStartFAQ: {$set: getFAQTextOrDefault(inputData, "textButtonStartFAQ")},
								textContactNumTitle: {$set: getFAQTextOrDefault(inputData, "textContactNumTitle")},
								textContactNumLine1: {$set: getFAQTextOrDefault(inputData, "textContactNumLine1")},
								textContactHoursTitle: {$set: getFAQTextOrDefault(inputData, "textContactHoursTitle")},
								textContactHoursLine1: {$set: getFAQTextOrDefault(inputData, "textContactHoursLine1")},
								textContactHoursLine2: {$set: getFAQTextOrDefault(inputData, "textContactHoursLine2")},
								textContactChatTitle: {$set: getFAQTextOrDefault(inputData, "textContactChatTitle")},
								textFAQChatLauncher: {$set: getFAQTextOrDefault(inputData, "textFAQChatLauncher")},
								textContactChatLine1: {$set: getFAQTextOrDefault(inputData, "textContactChatLine1")},
								textContactChatLine2: {$set: getFAQTextOrDefault(inputData, "textContactChatLine2")},
								textContactEmailTitle: {$set: getFAQTextOrDefault(inputData, "textContactEmailTitle")},
								textContactEmailLine1: {$set: getFAQTextOrDefault(inputData, "textContactEmailLine1")},
								textContactEmailLine2: {$set: getFAQTextOrDefault(inputData, "textContactEmailLine2")},
								textContactEmailLine3: {$set: getFAQTextOrDefault(inputData, "textContactEmailLine3")},
								textListNotFoundDesc1: {$set: getFAQTextOrDefault(inputData, "textListNotFoundDesc1")},
								textListNotFoundDesc2: {$set: getFAQTextOrDefault(inputData, "textListNotFoundDesc2")},
								textFAQButton1: {$set: getFAQTextOrDefault(inputData, "textFAQButton1")},
								textFAQButton2: {$set: getFAQTextOrDefault(inputData, "textFAQButton2")},
								textFAQButton3: {$set: getFAQTextOrDefault(inputData, "textFAQButton3")},
								messengerFAQ: {$set: inputData.messengerFAQ},
								whatsAppFAQ: {$set: inputData.whatsAppFAQ},
								telegramFAQ: {$set: inputData.telegramFAQ},
								textCallbackTitle: {$set: getFAQTextOrDefault(inputData, "textCallbackTitle")},
								textCallbackWorkingHours: {$set: getFAQTextOrDefault(inputData, "textCallbackWorkingHours")},
								textCallbackInputName: {$set: getFAQTextOrDefault(inputData, "textCallbackInputName")},
								textCallbackInputEmail: {$set: getFAQTextOrDefault(inputData, "textCallbackInputEmail")},
								textCallbackInputPhone: {$set: getFAQTextOrDefault(inputData, "textCallbackInputPhone")},
								textCallbackInputQuestion: {$set: getFAQTextOrDefault(inputData, "textCallbackInputQuestion")},
								textNameInvalid: {$set: getFAQTextOrDefault(inputData, "textNameInvalid")},
								textMessageEmailInvalid: {$set: getFAQTextOrDefault(inputData, "textMessageEmailInvalid")},
								textMsgToCall: {$set: getFAQTextOrDefault(inputData, "textMsgToCall")},
								textBtnSend: {$set: getFAQTextOrDefault(inputData, "textBtnSend")},
								textBtnCancel: {$set: getFAQTextOrDefault(inputData, "textBtnCancel")},
								textDateError: {$set: getFAQTextOrDefault(inputData, "textDateError")},
								textMsgSendSuccess: {$set: getFAQTextOrDefault(inputData, "textMsgSendSuccess")},
								adminFaqHasChat: {$set: inputData.hasChat},
								adminFaqShowContactPage: {$set: inputData.showContactPage},
								adminFaqShowMenuAsDefaultFAQ: {$set: inputData.showMenuAsDefault},
								adminFaqAskEULAFAQ: {$set: inputData.askEULA},
								adminFaqButtonAwayEnableFAQ: {$set: inputData.buttonAwayEnable},
								adminFaqChatRatingsEnabledFAQ: {$set: inputData.chatRatingsEnabled},
								adminFaqEnableVideoChatFAQ: {$set: inputData.enableVideoChat},
								adminFaqEnableCallbackRequest: {$set: inputData.enableCallbackRequest},
								adminFaqHideAgentAvatarList: {$set: inputData.hideAgentAvatarList},
								adminFaqFullScreenOnSmallScreen: {$set: inputData.faqFullScreenOnSmallScreen},
								position: {$set: inputData.position},
								faqZIndex: {$set: inputData.faqZIndex},
								integrityAttr: {$set: inputData.integrityAttr},
								translationFAQ: {$set: inputData.translation},
								searchBarTextColor: {$set: getFAQColorOrDefault(inputData, "searchBarTextColor")},
								searchBarBgColor: {$set: getFAQColorOrDefault(inputData, "searchBarBgColor")},
								questionListBgColor: {$set: getFAQColorOrDefault(inputData, "questionListBgColor")},
								questionListTextColor: {$set: getFAQColorOrDefault(inputData, "questionListTextColor")},
								libraryListBgColor: {$set: getFAQColorOrDefault(inputData, "libraryListBgColor")},
								libraryListTextColor: {$set: getFAQColorOrDefault(inputData, "libraryListTextColor")},
								tabIconTextColor: {$set: getFAQColorOrDefault(inputData, "tabIconTextColor")},
								tabIconBgColor: {$set: getFAQColorOrDefault(inputData, "tabIconBgColor")},
								faqChatFooterBgColor: {$set: getFAQColorOrDefault(inputData, "faqChatFooterBgColor")},
							}
						});
					}else if(view === M_VOICEWIDGETDL){
						return update(st, {
							input: {
								id: {$set: inputData.id},
								name: {$set: inputData.Name},
								activeAreas: {$set: inputData.activeAreas},
								allActiveAreas: {$set: inputData.allActiveAreas},
								baseURL: {$set: inputData.baseURL},
								customize: {$set: inputData.customize},
								widgetPosition: {$set: (inputData.widgetPosition ? inputData.widgetPosition : BOTTOM_RIGHT)},
								voiceZIndex: {$set: inputData.voiceZIndex},
								integrityAttr: {$set: inputData.integrityAttr},
								logo: {$set: inputData.logo},
								headerColor: {$set: inputData.headerColor},
								headerTextColor: {$set: inputData.headerTextColor},
								mainColor: {$set: inputData.mainColor},
								warningTextColor: {$set: inputData.warningTextColor},
								footerTextColor: {$set: inputData.footerTextColor},
								textTitle: {$set: inputData.textTitle},
								textInputName: {$set: inputData.textInputName},
								textInputEmail: {$set: inputData.textInputEmail},
								textInputPhone: {$set: inputData.textInputPhone},
								textInputQuestion: {$set: inputData.textInputQuestion},
								textEULAVoice: {$set: inputData.textEULAVoice},
								textMsgToCall: {$set: inputData.textMsgToCall},
								textBtnSend: {$set: inputData.textBtnSend},
								textBtnCancel: {$set: inputData.textBtnCancel},
								textDateError: {$set: inputData.textDateError},
								textMsgSendSuccess: {$set: inputData.textMsgSendSuccess},
								bottomPosition: {$set: inputData.bottomPosition},
								leftPosition: {$set: inputData.leftPosition},
								rightPosition: {$set: inputData.rightPosition},
								translation: {$set: inputData.translation},
								askEULA: {$set: inputData.askEULA},
							}
						});
					}else if(view === M_TAG){
						const { id, level, name, tagVIP, tagColor, tagDelete, parentId, connectedAreas, connectedTagsLen, connectedTagsList, connectedTags } = inputData;
						const selectedAreas = csvStringToIntArray(connectedAreas);
						return update(st, {
							input: {
								id: {$set: id},
								level: {$set: level},
								tagName: {$set: name},
								tagVIP: {$set: (tagVIP ? true : false)},
								tagColor: {$set: tagColor},
								tagDelete: {$set: (tagDelete ? true: false)},
								parentId: {$set: parentId},
								selectedArea: {$set: selectedAreas},
								connectedTagsLen: {$set: connectedTagsLen},
								connectedTagsList: {$set: connectedTagsList},
								connectedTags: {$set: connectedTags},
								newSubTags: {$set: []},
							}
						});
					}else if(view === M_API_CALLBACK) {
						for(let i=0;i<p.list.length;i++) {
							const cbData = p.list[i];
							if(cbData.id === id) {
								return update(st, {
									input: {
										id: {$set: id},
										cbName: {$set: cbData.name},
										cbEndpoint: {$set: cbData.endpoint},
										cbSecretKey: {$set: cbData.secretKey},
										cbEvent: {$set: cbData.eventType},
										selectedArea: {$set: cbData.areaList},
										cbEnabled: {$set: cbData.enabled}
									}
								});
							}
						}

					}else if(view === M_API_ACCESSTOKENS) {
						for(let i=0;i<p.list.length;i++) {
							const jwtData = p.list[i];
							if(jwtData.id === id) {
								return update(st, {
									input: {
										id: {$set: id},
										jwtName: {$set: jwtData.name},
										jwtKey: {$set: ""},
										jwtExpiryDate: {$set: jwtData.expireDate},
										jwtAPIVersion: {$set: parseInt(jwtData.apiVersion, 10)},
										jwtToken: {$set: jwtData.token},
										jwtCreatedBy: {$set: jwtData.createdBy},
										jwtCreatedOn: {$set: jwtData.createdOn},
										jwtAllowAttachment: {$set: jwtData.allowAttachment},
										jwtAutomatedConversationUser: {$set: jwtData.automatedConversation.user},
										jwtACWebhookURL: {$set: jwtData.automatedConversation.webhook},
										jwtAllowAccessLib: {$set: jwtData.others.library},
										jwtProgressStatus: {$set: jwtData.others.errandStatus},
										jwtErrandData: {$set: jwtData.others.errandData},
										jwtCreateCustContact: {$set: jwtData.others.contact},
										jwtIntegrateAgentLogin: {$set: jwtData.others.integration},
										jwtAccessExportAPI: {$set: jwtData.others.export},
										jwtLogioAPI: {$set: jwtData.others.logio},
										jwtStatsAPI: {$set: jwtData.others.statistics},
										jwtChatMessages: {$set: jwtData.others.chatMessages},
										jwtCreateErrandChannel: {$set: jwtData.createErrand.channel},
										jwtCreateErrandArea: {$set: jwtData.createErrand.area ? jwtData.createErrand.area : ""},
										jwtCreateErrandAcc: {$set: jwtData.createErrand.account ? jwtData.createErrand.account : ""}
									}
								});
							}
						}
					}else if(view === M_CALL_IVR) {
						if(list.length > 0){
							for(let i=0;i<list.length;i++) {
								const data = list[i];
								if(data.id == id){
									inputData = data;
									let cfData = "";
									if(inputData.callflow) {
										cfData = JSON.parse(inputData.callflow);
									}
									//get root ivr for name and desc
									const main = cfData.find(obj => obj.level === 0);
									let moh, offhourprompt, breakhourprompt;
									if(inputData.moh) {
										moh = JSON.parse(inputData.moh);
									}
									if(inputData.offhourprompt) {
										offhourprompt = JSON.parse(inputData.offhourprompt);
									}
									if(inputData.breakhourprompt) {
										breakhourprompt = JSON.parse(inputData.breakhourprompt);
									}
									return update(st, {
										input: {
											id: {$set: id},
											ivrConnLevel: {$set: 0}, //when open from list, set to parent/highest ivr
											ivrCallflow: {$set: cfData},
											ivrPromptId: {$set: main.id},
											ivrName: {$set: main.name || ""},
											ivrDesc: {$set: main.description || ""},
											ivrOffhoursMsgFile: {$set: offhourprompt ? offhourprompt.localname : ""},
											ivrOffhoursMsgFileDownload: {$set: offhourprompt ? offhourprompt.downloadurl : ""},
											ivrBreakhoursMsgFile: {$set: breakhourprompt ? breakhourprompt.localname : ""},
											ivrBreakhoursMsgFileDownload: {$set: breakhourprompt ? breakhourprompt.downloadurl : ""},
											ivrQueueMusicFile: {$set: moh ? moh.localname : ""},
											ivrQueueMusicFileDownload: {$set: moh ? moh.downloadurl : ""}
										}
									});
								}
							}
						}
						return st;
					} else if(view === M_SKILLS) {
						if(list && list.length > 0) {
							for(let i=0;i<list.length;i++) {
								const data = list[i];
								if(data.id == id){
									inputData = data;
									return update(st, {
										input: {
											id: {$set: id},
											skillName: {$set: inputData.name},
											skillDesc: {$set: inputData.description},
											skillCategoryId: {$set: inputData.category},
										}
									});
								}
							}
						}
					} else if(view === M_GENERATIVE_AI_DOCUMENT) {
						if(list && list.length > 0) {
							for(let i=0;i<list.length;i++) {
								const data = list[i];
								if(data.id == id){
									inputData = data;
									let areaListStr = "";
									if(inputData.areas && inputData.areas.length > 0) {
										areaListStr = inputData.areas.join(",");
									}
									return update(st, {
										activeId: {$set: id},
										input: {
											id: {$set: id},
											docName: {$set: inputData.name},
											docUrl: {$set: ( inputData.type === "URL" ? inputData.name: "")},
											docDescription: {$set: inputData.description},
											selectedLanguage: {$set: inputData.language},
											selectedArea: {$set: areaListStr},
											docType: { $set: inputData.type },
										}
									});
								}
							}
						}
					}else if(view === M_BLACKLIST) {
						if(list && list.length > 0) {
							for(let i=0;i<list.length;i++) {
								const data = list[i];
								if(data.id == id){
									inputData = data;
									let areaListStr = "";
									if(inputData.areas && inputData.areas.length > 0) {
										areaListStr = inputData.areas.join(",");
									}
									return update(st, {
										input: {
											id: {$set: id},
											content: {$set: inputData.content},
											selectedArea: {$set: areaListStr},
										}
									});
								}
							}
						}
					}else if (view === M_LLM_TOOL_MANAGER) {

						if (list && list.length > 0) {
							for (let i = 0; i < list.length; i++) {
								const data = list[i];
								if (data.id == id) {
									inputData = data;
					
									let areaListStr = "";
									if (inputData.areas && inputData.areas.length > 0) {
										areaListStr = inputData.areas.join(",");
									}
					
									const parametersData = [];
									if (inputData.parameters && inputData.parameters.length > 0) {
										for (const param of inputData.parameters) {
											parametersData.push({
												name: param.name,
												type: param.type,
												description: param.description,
												enum: param.enumValue,
												internal: param.internal
											});
										}
									}
									return update(st, {
										input: {
											id: { $set: id },
											toolName: { $set: inputData.toolName },
											toolDescription: { $set: inputData.toolDescription },
											selectedArea: { $set: areaListStr },
											parameters: { $set: parametersData },
										}
									});
								}
							}
						}
					}else if(view === M_BLACKLISTRESP) {
						if(list && list.length > 0) {
							for(let i=0;i<list.length;i++) {
								const data = list[i];
								if(data.id == id){
									inputData = data;
									let areaListStr = "";
									if(inputData.areas && inputData.areas.length > 0) {
										areaListStr = inputData.areas.join(",");
									}
									return update(st, {
										input: {
											id: {$set: id},
											response: {$set: inputData.response},
											selectedArea: {$set: areaListStr},
											selectedLanguage: {$set: inputData.language},
										}
									});
								}
							}
						}
					}else if(view === M_WHITELIST) {
						if(list && list.length > 0) {
							for(let i=0;i<list.length;i++) {
								const data = list[i];
								if(data.id == id){
									inputData = data;
									let areaListStr = "";
									if(inputData.areas && inputData.areas.length > 0) {
										areaListStr = inputData.areas.join(",");
									}
									return update(st, {
										input: {
											id: {$set: id},
											selectedArea: {$set: areaListStr},
											selectedLanguage: {$set: inputData.language},
											content: {$set: inputData.content},
											docName: {$set: inputData.name},
											
										}
									});
								}
							}
						}
					}
				}
			}
		}
	}else if(act.type === done(keyGetExternalExpertAddressList)){
		if(p.data) {
			const data = p.data
				, currentAdminAreaFilter = st.filter.selectedAreaFilter
				;
			if (data.list) {
				const { areaList } = data
					, updater = {
						input: {
							connectedArea: {$set: ""}
							, eeAvatar: {$set: ""}
							, emailAddress: {$set: ""}
							, questionType: {$set: ""}
						}
						, areaList: {$set: areaList}
					}
					;
				if (currentAdminAreaFilter === "") {
					updater.filter = {
						selectedAreaFilter: {
							$set: allOrgAreasToAreaIDsString(areaList)
						}
					};
				}
				return update(st, updater);
			}
		}
	}else if(act.type === FILTER_ADMIN_LIST){
		const { view, value, field } = p;
		if(view == ADMIN_VIEW_MAP["externalexpert-personal"] || view == ADMIN_VIEW_MAP["externalexpert"] || isRoutingView(view) || view == M_TAG || isBlacklist(view) || isWhitelist(view)) {
			return update(st, {
				filter: {
					selectedAreaFilter: {$set: value}
				},
			});
		}else if(view === ADMIN_VIEW_MAP["wordlist"]) {
			return update(st, {
				filter: {
					selectedLangFilter: {$set: value}
				},
			});
		}else if(view === ADMIN_VIEW_MAP["addressbook-personal"] || view === ADMIN_VIEW_MAP["addressbook"] || view === ADMIN_VIEW_MAP["companies"]
			|| view === M_CHATWIDGETDL || view === M_FAQWIDGETDL || view === M_VOICEWIDGETDL) {
			return update(st, {
				filter: {
					wordFilter: {$set: value},
				},
			});
		}else if(view === ADMIN_VIEW_MAP["configchangelog"]) {
			return update(st, {
				filter: {
					[field]: {$set: value},
					lastFieldChange : {$set: field},
				},
			});
		}else if(view === ADMIN_VIEW_MAP["agents"]) {
			if(typeof value === "string") {
				return update(st, {
					filter: {
						wordFilter: {$set: value},
					},
				});
			}else {
				return update(st, {
					filter: {
						agentTypeFilter: {$set: value},
					},
				});
			}
		}else if(view === M_AGENTSIPSTATUS) {
			return update(st, {
				filter: {
					agentSipStatusFilter: {$set: value},
				},
			});
		} else if(view === M_REVIEW_AGENTS) {
			return update(st, {
				filter: {
					selectedAgentFilter: {$set: value},
				},
			});
		} else if(view === M_CALL_RECORDINGS) {
			if(field === "dateFromFilter") {
				return update (st, {
					filter: {
						dateFromFilter: {$set: value}
					}
				});
			} else if(field === "dateToFilter") {
				return update (st, {
					filter: {
						dateToFilter: {$set: value}
					}
				});
			} else if(field === "wordFilter") {
				return update (st, {
					filter: {
						wordFilter: {$set: value}
					}
				});
			} else if(field === "selectedAgentFilter") {
				return update (st, {
					filter: {
						selectedAgentFilter: {$set: value}
					}
				});
			}
		}
	} else if(act.type === RESET_FILTER_ADMIN) {
		const { view } = p;
		if (view === M_CALL_RECORDINGS) {
			return update(st, {
				filter: {
					dateFromFilter: {$set: ""},
					dateToFilter: {$set: ""},
					wordFilter: {$set: ""},
					selectedAgentFilter: {$set: ""}
				}
			});
		}
		return st;
	} else if(act.type === TOGGLE_ADMIN_FORM_TYPE){
		return update(st, {
			formType: {$set: p.formType}
		});
	}else if(act.type === SHOW_MERGE_CONTACT_CARD_POPUP){
		return update(st, {
			showMergeContactPopup: {$set: p}
		});
	}else if(act.type === SHOW_ADMIN_HIGHLIGHT){
		return update(st, {
			highlight: {$set: p}
		});
	}else if(act.type === SET_ADMIN_COLUMN_WIDTH){
		return update(st, {
			columnWidth: {$set: p}
		});
	}else if(act.type === done(keyRemoveAvatarEEAddressList)){
		const { data } = p;
		if(data && data.error !== ""){
			return update(st, {
				input: {
					avatarPreview: {$set: ""}
				}
			});
		}
	}else if(act.type === done(keyAgentAdmin)) {
		//If not agent preferences (since 'agentLoginConfig' is indicate that)
		if(p.data && !p.data.agentLoginConfig) {
			let data = p.data, inboxPreviewShowInstantly = false;
			if(data.activatePreview == 1 && data.previewDelay == 0) {
				inboxPreviewShowInstantly = true
			}
			let avatarPreviewUrl = "";
			if(data.avatarLocalName){
				avatarPreviewUrl = filePathPrefix() + "showAvatar?agent=true&localName="+ data.avatarLocalName+"&contentType="+data.avatarContentType;
			}
			return update(st, {
				selectedAreaSalutationList: {$set: p.data.PreLoadedSalutationSpecialisations},
				selectedAreaSignatureList: {$set: p.data.PreLoadedSignatureSpecialisations},
				avatarPreview: {$set: avatarPreviewUrl},
				input: {
					loginName: {$set: data.loginName},
					userName: {$set: data.username},
					phoneNumber: {$set: data.phoneNumber},
					markAsCentionUser: {$set: data.markAsCentionUser},
					adminLevel: {$set: data.adminLevel},
					avatarLocalName: {$set: data.avatarLocalName},
					avatarContentType: {$set: data.avatarContentType},
					email: {$set: data.agentEmail},
					solidusAgentID: {$set: data.solidusAgentId.toString()},
					doQuickSearch: {$set: data.doQuickSearch ? 1 : 0},
					quickSearchAgentReferenceCode: {$set: data.quickSearchAgentReferenceCode},
					externalID: {$set: data.externalId},
					brightAgentReferenceCode: {$set: data.brightRefCode},
					sipDeviceId: {$set: data.sipDeviceId},
					sipExtensionId: {$set: data.sipExtensionId},
					sipPassword: {$set: data.sipPassword},
					hotkeys: {$set: data.activateHotkeys},
					hotkeys_markers: {$set: data.hotkeysMarkers},
					hotkeys_ctrl: {$set: data.useCtrl},
					hotkeys_alt: {$set: data.useAlt},
					hotkeys_shift: {$set: data.useShift},
					hotkeys_meta: {$set: data.useMeta},
					salutationPreview: {$set: data.salutationPreview},
					previewSummary: {$set: data.previewSummary},
					signaturePreview: {$set: data.signaturePreview},
					preferredArea: {$set: data.preferredArea.toString()},
					preferredManualArea: {$set: data.preferredManualArea},
					showOnlyWarnings: {$set: data.showOnlyWarnings}, //is this still used?
					salutation: {$set: (data.preferredSalutationActive ? parseInt(data.preferredSalutationActive) : UNSELECT)},
					signature: {$set: ( data.preferredSignatureActive ? parseInt(data.preferredSignatureActive) : UNSELECT)},
					signatureName: {$set: data.signatureName},
					chatName: {$set: data.chatAgentName},
					playSound: {$set: data.playChatSound},
					allowedChatAmount: {$set: data.maximumAllowedChat},
					chatMessagePreview: {$set: data.chatMessagePreview},
					openExternalSystem: {$set: data.openExternalSystem},
					interfaceLanguage: {$set: data.interfaceLanguage},
					spellingLanguage: {$set: data.spellingLanguage},
					sortHistoryAscending: {$set: data.sortHistoryAscending},
					timezoneID:{$set: data.usetimeZone},
					autoInsertPublishedAnswer: {$set: data.autoInsertPublishedAnswer}, //is this still used?
					listSize: {$set: data.errandListSize},
					inboxPreview: {$set: data.activatePreview},
					inboxPreviewShowInstantly: {$set: inboxPreviewShowInstantly},
					inboxPreviewTimeDelay: {$set: data.previewDelay},
					allowedIp: {$set: data.allowedIp},
					blueBubbleNotification: {$set: data.blueBubbleNotification ? 1 : 0},
					browserOsNotification: {$set: data.browserOsNotification ? 1 : 0},
					autoAnswer: {$set: data.autoAnswer},
					autoPickSuggestion: {$set: data.autoPickSuggestion},
					automatedConversationBot: {$set: data.automatedConversationBot ? 1 : 0},
					// darkMode: {$set: data.darkMode ? 1 : 0},
					selectedSipRingOutput: {$set: data.selectedSipRingOutput},
					twofa_flag: {$set: data.twofa_flag ? 1 : 0},
					twofa_secret: {$set: data.twofa_secret },
					skills: {$set: data.skills},
				}
			});
		}
	}
	else if(act.type === SET_AGENT_INPUT) {
		const { field, value, isPref } = p;
		if(!isPref) {
			return update(st, {
				input: {
					[field]: {$set: value}
				}
			});
		}
	} else if(act.type === SET_AGENT_AVATAR_PREVIEW){
		const { img, isPref } = p;
		if(!isPref) {
			return update(st, {
				avatarPreview: {$set: img}
			});
		}
	} else if(act.type === done(keyAddAgentAvatar)){
		let avatarLocalName = "", avatarContentType = "";
		if(p.data) {
			avatarLocalName = p.data.id;
			avatarContentType = p.data.value;
		}
		return update(st, {
			input: {
				avatarLocalName: {$set: avatarLocalName},
				avatarContentType: {$set: avatarContentType}
			}
		});
	} else if(act.type === done(keyAddAgentMP)){
		if(p && p.data){
			let type = p.data[0].type;
			if(type === 1){
				return update(st, {
					selectedAreaSalutationList: {$set: p.data}
				});
			}else{
				return update(st, {
					selectedAreaSignatureList: {$set: p.data}
				});
			}
		}
	} else if(act.type === done(keyRemoveAgentMP)){
		if(p && p.data) {
			if(!p.data.error && p.data.mpList){
				if(typeof p.data.mpList[0] !== "undefined") {
					let type = p.data.mpList[0].type;
					if(type === 1){
						return update(st, {
							selectedAreaSalutationList: {$set: p.data.mpList}
						});
					}else{
						return update(st, {
							selectedAreaSignatureList: {$set: p.data.mpList}
						});
					}
				}
			}
		}
	} else if(act.type === UPLOAD_AGENT_IN_PROGRESS){
		return update(st, {
			uploadingAgent: {$set: p.wip}
		});
	} else if(act.type === done(keyAgentNew)){
		if(p && p.data) {
			const {
				automatedConversationBot,
				automatedConversationBotProvider
			} = p.data;
			return update(st, {
				input: {
					id: {$set: ""},
					loginName: {$set: ""},
					userName: {$set: ""},
					password: {$set: ""},
					phoneNumber: {$set: ""},
					markAsCentionUser: {$set: false},
					adminLevel: {$set: 3},
					avatarLocalName: {$set: ""},
					avatarContentType: {$set: ""},
					email: {$set: ""},
					solidusAgentID: {$set: 0},
					doQuickSearch: {$set: 0},
					quickSearchAgentReferenceCode: {$set: ""},
					externalID: {$set: ""},
					brightAgentReferenceCode: {$set: ""},
					sipDeviceId: {$set: 0},
					sipExtensionId: {$set: 0},
					sipPassword: {$set: ""},
					hotkeys: {$set: 0},
					hotkeys_markers: {$set: 0},
					hotkeys_ctrl: {$set: 0},
					hotkeys_alt: {$set: 0},
					hotkeys_shift: {$set: 0},
					hotkeys_meta: {$set: 0},
					salutationPreview: {$set: 0},
					previewSummary: {$set: false},
					signaturePreview: {$set: 0},
					preferredArea: {$set: ""},
					preferredManualArea: {$set: 0},
					showOnlyWarnings: {$set: 0},
					salutation: {$set: 0},
					signature: {$set: 0},
					signatureName: {$set: ""},
					chatName: {$set: ""},
					playSound: {$set: 0},
					allowedChatAmount: {$set: 0},
					chatMessagePreview: {$set: 0},
					openExternalSystem: {$set: 0},
					interfaceLanguage: {$set: 1},
					spellingLanguage: {$set: 1},
					sortHistoryAscending: {$set: 1},
					timezoneID:{$set: 14},
					autoInsertPublishedAnswer: {$set: 0},
					listSize: {$set: 10},
					inboxPreview: {$set: 0},
					inboxPreviewShowInstantly: {$set: 0},
					inboxPreviewTimeDelay: {$set: 0},
					allowedIp: {$set: ""},
					blueBubbleNotification: {$set: 1},
					browserOsNotification: {$set: 0},
					autoAnswer: {$set: false},
					autoPickSuggestion: {$set: false},
					// darkMode: {$set: 0},
					automatedConversationBot: { $set: automatedConversationBot },
					automatedConversationBotProvider: { $set: automatedConversationBotProvider },
					automatedConversationBotLibraries: { $set: '' },
					selectedSipRingOutput: {$set: 'default'},
					twofa_flag: {$set: 0},
					twofa_secret: {$set: ""},
					skills: {$set: []},
				},
				validLoginName: { $set: "" }
			});
		}

	} else if(act.type === done(keyAgentValidate)){
		if(p && p.data) {
			let valid = p.data.success;
			return update(st, {
				validLoginName: { $set: valid }
			});
		}
	} else if(act.type === done(keyAgentEmailAvailability)){
		if(p && p.data) {
			let allowed = p.data.allowed;
			return update(st, {
				agentEmailAvailability: {$set: allowed}
			});
		}
	} else if(act.type === done(keyPostGenerativeAIDocument)){
		if(p && p.data && p.data.status) {
			//resetting input after successful upload
			return update(st, {
				input: {
					docName: {$set: ""},
					docUrl: {$set: ""},
					docDescription: {$set: ""},
					selectedLanguage: {$set: ""},
					selectedArea: {$set: ""},
					docType: { $set: ""},
					file: { $set: ""}
				}
			});
		}
	} else if(act.type === RESET_EMAIL_AVAILABILITY){
		return update(st, {
			agentEmailAvailability: {$set: true}
		});
	} else if(act.type === TOGGLE_AGENT_EDIT){ //Toggle popup agent form
		if(p.isPref) {
			return update(st, {
				showInline: {$set: !p.show},
			});
		}else{
			if(!p.show){
				return update(st, {
					showInline: {$set: true},
				});
			}
		}
	/* no place that using routingAreaTags
	} else if (act.type === done(keyGetRouteAutoTags)){
		return update(st, {
			routingAreaTags: {$set: p.data.areaTags}
		});*/
	} else if(act.type === done(keyFetchAgentSipStatus)){
		if (p.data) {
			return update(st, {
				input: {
					name: {$set: p.data.name},
					internalName: {$set: p.data.internalName},
					className: {$set: p.data.className},
					type: {$set: p.data.type},
					active: {$set: p.data.active},
					timestampCreate: {$set: p.data.timestampCreate},
				}
			});
		}
	} else if(act.type === done(keyFetchClassifier)){
		if (p.data) {
			return update(st, {
				input: {
					name: {$set: p.data.name},
					model: {$set: p.data.model},
					selectedArea: {$set: p.data.selectedAreas}
				}
			});
		}
	} else if(act.type === SET_AGENT_SOUND_OUTPUT_LIST){
		return update(st, {
			listOfSoundOutputDevices: {$set: p}
		});
	} else if(act.type === APPEND_SUB_TAGS){
		const { subtags, list } = p;
		let connListWithTagId = [], subTagsWithTagId = [];
		let connTags = st.input.connectedTags ? st.input.connectedTags : [];

		if(connTags && connTags.length > 0){
			$.each(connTags, (i, tag) => {
				for(let i = 0; i < list.length; i++){
					if(list[i].id === tag){
						const tg = { id: list[i].id, tagId: list[i].tagId };
						connListWithTagId.push(tg);
					}
				}
			});
			$.each(subtags, (i, tag) => {
				for(let i = 0; i < list.length; i++){
					if(list[i].id === tag){
						const tg = { id: list[i].id, tagId: list[i].tagId };
						subTagsWithTagId.push(tg);
					}
				}
			});
			$.each(subTagsWithTagId, (i, tag) => {
				const checkTagExistence = t => t.tagId === tag.tagId;
				if(connListWithTagId.some(checkTagExistence)) {
					if (process.env.NODE_ENV !== 'production') {
						console.log("Tag already added");
					}
				} else {
					connTags.push(tag.id);
				}
			});
			return update(st, {
				input: {
					connectedTags: {$set: connTags},
					connectedTagsList: {$set: getConnTagsList(connTags, list)}
				}
			});
		} else {
			return update(st, {
				input: {
					connectedTags: {$set: subtags},
					connectedTagsList: {$set: getConnTagsList(subtags, list)}
				}
			});
		}
	} else if(act.type === REMOVE_SUB_TAGS) {
		const { subtag, list } = p;
		let connTags = st.input.connectedTags ? st.input.connectedTags : [];
		$.each(connTags, (i, tag) => {
			if(tag === subtag) {
				connTags.splice(i, 1);
			}
		});
		return update(st, {
			input: {
				connectedTags: {$set: connTags},
				connectedTagsList: {$set: getConnTagsList(connTags, list)}
			}
		});
	} else if(act.type === ADMIN_ACTION_ALERT){
		const {show, type, msg} = p.error;
		return update(st, {
			adminAlert: {
				show: {$set: show},
				type: {$set: type},
				msg: {$set: msg}
			}
		});
	} else if(act.type === ADMIN_ACTION_STATUS) {
		const { status, msg } = p;
		const adminStatusUpdate = {
			adminStatus: {
				status: { $set: status }
			}
		};
		if (msg) {
			adminStatusUpdate.adminStatus.msg = { $set: msg };
		}
		return update(st, adminStatusUpdate);
	} else if(act.type === done(keyGetTagsList)){
		if(p.data) {
			if(st.activeId > 0) {
				const tags = p.data.tags ? p.data.tags : [];
				if(tags.length > 0){
					for(let i = 0; i < tags.length; i++){
						if(tags[i].id === st.activeId){
							if(tags[i].connectedTags && tags[i].connectedTags.length > 0){
								return update(st, {
									input: {
										connectedTags: {$set: tags[i].connectedTags},
										connectedTagsList: {$set: getConnTagsList(tags[i].connectedTags, tags)},
										connectedTagsLen: {$set: tags[i].connectedTags.length},
									}
								});
							} else {
								return update(st, {
									input: {
										connectedTags: {$set: []},
										connectedTagsList: {$set: []},
										connectedTagsLen: {$set: 0},
									}
								});
							}
						}
					}
				}
				return st;
			}
		}
	} else if(act.type === CALL_UPDATE_IVR_CALLFLOW) {
		const { ivrId, id, field, value, level, parentId } = p;
		let val = value;
		if(field === "actiontype") {
			val = IVR_CONNECT_ID_MAP[value];
		}

		let updatedCallflow;
		if(ivrId > 0) {
			const index = st.input.ivrCallflow.findIndex(item => item.id == id);
			let newSet = false;
			let levelIvr = [];
			const cf = st.input.ivrCallflow;
			//check if id already exist in current level
			levelIvr = cf.filter(c => (c.level == level && c.parentid == parentId));
			const keyExists = levelIvr.some(key => key.id === id);
			if(!keyExists && field === "dtmftobehere") {
				newSet = true;
			}
			if(index !== -1 && !newSet) {
				//have record, just update entry
				const updatedItem = { ...st.input.ivrCallflow[index], [field]: val };
				updatedCallflow = [
					...st.input.ivrCallflow.slice(0, index),
					updatedItem,
					...st.input.ivrCallflow.slice(index + 1)
				];
			} else {
				//newly added
				if(field === "dtmftobehere") {
					const newItem = {
						level: level,
						id: id,
						name: "",
						description: "",
						parentid: parentId ? parentId : "0",
						type: "singledtmfprompt", //default
						prompt: "",
						promptdownload: "",
						dtmftobehere: val.toString(),
						actiontype: "",
						connecttoqueue: "" //default
					}
					updatedCallflow = [
						...st.input.ivrCallflow,
						newItem
					];
				}
			}
			return update(st, {
				input: {
					ivrCallflow: {$set: updatedCallflow},
				}
			});
		} else {
			//New IVR
			const index = st.input.ivrCallflow.findIndex(item => item.id == id);
			if(field !== "dtmftobehere") {
				const updatedItem = { ...st.input.ivrCallflow[index], [field]: val };
				updatedCallflow = [
					...st.input.ivrCallflow.slice(0, index),
					updatedItem,
					...st.input.ivrCallflow.slice(index + 1)
				];
			} else {
				//Adding new callflow entry (child)
				const newItem = {
					level: level,
					id: id,
					name: "",
					description: "",
					parentid: parentId ? parentId : "0",
					type: "singledtmfprompt", //default
					prompt: "",
					promptdownload: "",
					dtmftobehere: val.toString(),
					actiontype: "",
					connecttoqueue: ""
				}
				updatedCallflow = [
					...st.input.ivrCallflow,
					newItem
				];
			}
			if(level == 0) {
				return update(st, {
					input: {
						ivrCallflow: {$set: updatedCallflow},
						ivrName: {$set: (field === 'name' ? value : st.input.ivrName)},
						ivrDescription: {$set: (field === 'description' ? value : st.input.ivrDesc)},
					}
				});
			} else {
				return update(st, {
					input: {
						ivrCallflow: {$set: updatedCallflow},
					}
				});
			}
		}
	} else if(act.type === CALL_UPDATE_IVR_CALLFLOW_VIEW) {
		const { id, level } = p;
		let name = st.input.ivrName, desc = st.input.ivrDesc;
		const cf = st.input.ivrCallflow;
		cf.map(c => {
			if(c.id == id) {
				name = c.name ? c.name : "";
				desc = c.description ? c.description : "";
			}
		})
		return update(st, {
			input: {
				ivrConnLevel: {$set: level},
				ivrPromptId: {$set: id},
				ivrName: {$set: name},
				ivrDesc: {$set: desc}
			}
		});
	} else if(act.type === CALL_REMOVE_IVR_KEY) {
		const { id } = p;
		const updatedCallflow = [ ...st.input.ivrCallflow.filter(obj => (obj.id !== id && obj.parentid !== id)) ];
		return update(st, {
			input: {
				ivrCallflow: {$set: updatedCallflow},
			}
		});
	} else if(act.type === done(keyCallIVRPromptUpload)){
		const { data } = p;

		if(data.field == "ivrGreetingAudioFile") {
			//update specific node's prompt's download
			let updatedCallflow;
			const index = st.input.ivrCallflow.findIndex(item => item.id == data.callflowId);
			const updatedItem = { ...st.input.ivrCallflow[index], prompt: data.filename, promptdownload: data.download };
			updatedCallflow = [
				...st.input.ivrCallflow.slice(0, index),
				updatedItem,
				...st.input.ivrCallflow.slice(index + 1)
			];
			return update(st, {
				input: {
					ivrCallflow: {$set: updatedCallflow},
				}
			});
		} else if(data.field == "ivrOffhoursMsgFile") {
			return update(st, {
				input: {
					ivrOffhoursMsgFile: {$set: data.filename},
					ivrOffhoursMsgFileDownload: {$set: data.download}
				}
			});
		} else if(data.field == "ivrBreakhoursMsgFile") {
			return update(st, {
				input: {
					ivrBreakhoursMsgFile: {$set: data.filename},
					ivrBreakhoursMsgFileDownload: {$set: data.download}
				}
			});
		}
	} else if(act.type === done(keyCallIVRMOHUpload)){
		const { data } = p;
		return update(st, {
			input: {
				ivrQueueMusicFile: {$set: data.filename},
				ivrQueueMusicFileDownload: {$set: filePathPrefix()+data.download}
			}
		});
	} else if(act.type === SIP_TRUNK_SET_MATCH) {
		const { field, value, isRemove } = p;
		let oldTrunk = [...st.input.siptrunkmatches];
		if(isRemove == true){
			oldTrunk.splice(value, 1);
		} else {
			let found = false;
			let idx = 0;
			for(let i=0; i < oldTrunk.length; i++) {
				if(oldTrunk[i] == value) {
					found = true;
					idx = i;
					break;
				}
			}
			if(found == false){
				oldTrunk.push(value);
			}
		}
		return update (st, {
			input: {
				siptrunkmatches: {$set: oldTrunk}
			}
		});
	} else if(act.type === UPDATE_AGENT_SKILL) {
		if(p.isRemove) {
			let skills = st.input.skills;
			let newSkills = [];
			if(skills) {
				for(let i = 0; i < skills.length; i++) {
					if(skills[i].id !== p.skill.id) {
						newSkills.push(skills[i]);
					}
				}
			}
			return update(st, {
				input: {
					skills: {$set: newSkills}
				}
			});
		} else {
			let skills = st.input.skills;
			let newSkills = [];
			let found = false;
			if(skills) {
				for(let i = 0; i < skills.length; i++) {
					if(skills[i].id === p.skill.id) {
						found = true;
						newSkills.push(p.skill);
					} else {
						newSkills.push(skills[i]);
					}
				}
				if(!found) {
					newSkills.push(p.skill);
				}
			}
			return update(st, {
				input: {
					skills: {$set: newSkills}
				}
			});
		}
	} else if(act.type === done(keyAgentAssist)) {
		if(p.data && p.data.length > 0) {
			const { id, customInstruction, customResponses, customExample, customFormatting } = p.data[0];
			return update(st, {
				activeId: {$set: id},
				input: {
					agentAssistInstruction: {$set: customInstruction},
					agentAssistResponse: {$set: customResponses},
					agentAssistExample: {$set: customExample},
					agentAssistFormatting: {$set: customFormatting},
				}
			});
		}
		return st;
	}
	return st;
}

const agentReducers = (st = agentInitState, act) => {
	const p = act.payload;
	let prefMode = st.prefMode;
	if(act.type === done(keyAgentAdmin)) {
		if(prefMode){
			if(p.data) {
				let avatarPreviewUrl = "";
				if(p.data.avatarLocalName){
					avatarPreviewUrl = filePathPrefix() + "showAvatar?agent=true&localName="+ p.data.avatarLocalName+"&contentType="+p.data.avatarContentType;
				}
				let data = p.data, inboxPreviewShowInstantly = false;
				if(data.activatePreview == 1 && data.previewDelay == 0) {
					inboxPreviewShowInstantly = true
				}
				return update(st, {
					selectedAreaSalutationList: {$set: p.data.PreLoadedSalutationSpecialisations},
					selectedAreaSignatureList: {$set: p.data.PreLoadedSignatureSpecialisations},
					avatarPreview: {$set: avatarPreviewUrl},
					input: {
						loginName: {$set: data.loginName},
						userName: {$set: data.username},
						phoneNumber: {$set: data.phoneNumber},
						markAsCentionUser: {$set: data.markAsCentionUser},
						adminLevel: {$set: data.adminLevel},
						avatarLocalName: {$set: data.avatarLocalName},
						avatarContentType: {$set: data.avatarContentType},
						email: {$set: data.agentEmail},
						solidusAgentID: {$set: data.solidusAgentId.toString()},
						doQuickSearch: {$set: data.doQuickSearch ? 1 : 0},
						quickSearchAgentReferenceCode: {$set: data.quickSearchAgentReferenceCode},
						externalID: {$set: data.externalId},
						brightAgentReferenceCode: {$set: data.brightRefCode},
						sipExtensionId: {$set: data.sipExtensionId},
						sipDeviceId: {$set: data.sipDeviceId},
						sipPassword: {$set: data.sipPassword},
						hotkeys: {$set: data.activateHotkeys},
						hotkeys_markers: {$set: data.hotkeysMarkers},
						hotkeys_ctrl: {$set: data.useCtrl},
						hotkeys_alt: {$set: data.useAlt},
						hotkeys_shift: {$set: data.useShift},
						hotkeys_meta: {$set: data.useMeta},
						salutationPreview: {$set: data.salutationPreview},
						previewSummary: {$set: data.previewSummary},
						signaturePreview: {$set: data.signaturePreview},
						preferredArea: {$set: data.preferredArea.toString()},
						showOnlyWarnings: {$set: data.showOnlyWarnings}, //is this still used?
						salutation: {$set: (data.preferredSalutationActive ? parseInt(data.preferredSalutationActive) : UNSELECT)},
						signature: {$set: ( data.preferredSignatureActive ? parseInt(data.preferredSignatureActive) : UNSELECT)},
						signatureName: {$set: data.signatureName},
						chatName: {$set: data.chatAgentName},
						playSound: {$set: data.playChatSound},
						allowedChatAmount: {$set: data.maximumAllowedChat},
						chatMessagePreview: {$set: data.chatMessagePreview},
						openExternalSystem: {$set: data.openExternalSystem},
						interfaceLanguage: {$set: data.interfaceLanguage},
						spellingLanguage: {$set: data.spellingLanguage},
						sortHistoryAscending: {$set: data.sortHistoryAscending},
						timezoneID:{$set: data.usetimeZone},
						autoInsertPublishedAnswer: {$set: data.autoInsertPublishedAnswer}, //is this still used?
						listSize: {$set: data.errandListSize},
						inboxPreview: {$set: data.activatePreview},
						inboxPreviewShowInstantly: {$set: inboxPreviewShowInstantly},
						inboxPreviewTimeDelay: {$set: data.previewDelay},
						allowedIp: {$set: data.allowedIp},
						blueBubbleNotification: {$set: data.blueBubbleNotification ? 1 : 0},
						browserOsNotification: {$set: data.browserOsNotification ? 1 : 0},
						autoAnswer: {$set: data.autoAnswer},
						autoPickSuggestion: {$set: data.autoPickSuggestion},
						// darkMode: {$set: data.darkMode ? 1 : 0},
						darkModeAppearance: {$set: data.darkModeAppearance},
						automatedConversationBot: {$set: data.automatedConversationBot ? 1 : 0},
						selectedSipRingOutput: {$set: data.selectedSipRingOutput},
						twofa_flag: {$set: data.twofa_flag ? 1 : 0},
						twofa_secret: {$set: data.twofa_secret},
						skills: {$set: data.skills},
					}
				});
			}
		}
	} else if(act.type === done(keyTwoFANewSecret)) {
		if(p.data) {
			let data = p.data
			const s = update(st, {
				input: {
					twofa_secret: {$set: data.secret }
				}
			});
			return s
		}
	} else if(act.type === done(keyVerifyTwoFAToken)) {
		if(p.data) {
			let data = p.data
				const s = update(st, {
				input: {
					twofaTokenValid: {$set: data.twofaTokenValid }
				}
			});
			return s
		}
	} else if(act.type === TOGGLE_AGENT_EDIT){
		return update(st, {
				showEdit: {$set: p.show},
				prefMode: {$set: p.isPref}
			});
	} else if(act.type === done(keyAddAgentMP)){
		if(p && p.data){
			let type = p.data[0].type;
			if(type === 1){
				return update(st, {
					selectedAreaSalutationList: {$set: p.data}
				});
			}else{
				return update(st, {
					selectedAreaSignatureList: {$set: p.data}
				});
			}
		}
	} else if(act.type === done(keyRemoveAgentMP)){
		if(p && p.data) {
			if(!p.data.error && p.data.mpList){
				let type = p.data.mpList[0].type;
				if(type === 1){
					return update(st, {
						selectedAreaSalutationList: {$set: p.data.mpList}
					});
				}else{
					return update(st, {
						selectedAreaSignatureList: {$set: p.data.mpList}
					});
				}
			}
		}
	} else if(act.type === SET_AGENT_AVATAR_PREVIEW){
		const { img, isPref } = p;
		if(isPref) {
			return update(st, {
				avatarPreview: {$set: img}
			});
		}
	} else if(act.type === done(keyRemoveAgentAvatar)){
		return update(st, {
			avatarPreview: {$set: ""},
			input: {
				avatarLocalName: {$set: ""},
				avatarContentType: {$set: ""}
			}
		});
	} else if(act.type === SET_AGENT_INPUT) {
		let { field, value } = p;
		if(prefMode) {
			return update(st, {
				input: {
					[field]: {$set: value}
				}
			});
		}
	} else if(act.type === done(keyAddAgentAvatar)){
		let avatarLocalName = "", avatarContentType = "";
		if(p.data) {
			avatarLocalName = p.data.id;
			avatarContentType = p.data.value;
		}
		return update(st, {
			input: {
				avatarLocalName: {$set: avatarLocalName},
				avatarContentType: {$set: avatarContentType}
			}
		});
	}
	return st;
}

const agentInfo = () => defAsyncReducer(keyAgentAdmin, asyncInitState);
const agentList = () => defAsyncReducer(keyAgentAdminList, asyncInitState);
const agentCreateNew = () => defAsyncReducer(keyAgentNew, asyncInitState);

const agentAreaSalutations = () => defAsyncReducer(keyAgentAdminSalutations, asyncInitState);
const agentAreaSignatures = () => defAsyncReducer(keyAgentAdminSignatures, asyncInitState);
const agentWordlist = () => defAsyncReducer(keyAgentWordlist, asyncInitState);
const wordListData = () => defAsyncReducer(keyEditAgentWordlist, asyncInitState);
const signatures = () => defAsyncReducer(keyGetSignatures, asyncInitState);
const salutations = () => defAsyncReducer(keyGetSalutations, asyncInitState);
const addressBook = () => defAsyncReducer(keyGetAddressbook, asyncInitState);
const errandInternalState = () => defAsyncReducer(keyGetErrandInternalState, asyncInitState);
const externalExpertAddr = () => defAsyncReducer(keyGetExternalExpertAddressList, asyncInitState);
const accountEmail = () => defAsyncReducer(keyGetAccountEmail, asyncInitState);
const accountLine = () => defAsyncReducer(keyGetAccountLine, asyncInitState);
const accountLinkedIn = () => defAsyncReducer(keyGetAccountLinkedIn, asyncInitState);
const accountJira = () => defAsyncReducer(keyGetAccountJira, asyncInitState);
const accountHubSpot = () => defAsyncReducer(keyGetAccountHubSpot, asyncInitState);
const accountMSTeams = () => defAsyncReducer(keyGetAccountMSTeams, asyncInitState);
const accountGoogleReview = () => defAsyncReducer(keyGetAccountGoogleReview, asyncInitState);
const accountGoogleChat = () => defAsyncReducer(keyGetAccountGoogleChat, asyncInitState);
const accountGooglePlay = () => defAsyncReducer(keyGetAccountGooglePlay, asyncInitState);
const accountSMPP = () => defAsyncReducer(keyGetAccountSMPP, asyncInitState);
const accountTrustpilot = () => defAsyncReducer(keyGetAccountTrustpilot, asyncInitState);
const accountTelegram = () => defAsyncReducer(keyGetAccountTelegram, asyncInitState);
const accountWhatsApp = () => defAsyncReducer(keyGetAccountWhatsApp, asyncInitState);
const accountTwilio = () => defAsyncReducer(keyGetAccountTwilio, asyncInitState);
const accountViber = () => defAsyncReducer(keyGetAccountViber, asyncInitState);
const accountSlack = () => defAsyncReducer(keyGetAccountSlack, asyncInitState);
const accountYoutube = () => defAsyncReducer(keyGetAccountYoutube, asyncInitState);
const accountFacebook = () => defAsyncReducer(keyGetAccountFacebook, asyncInitState);
const accountTwitter = () => defAsyncReducer(keyGetAccountTwitter, asyncInitState);
const accountInstagram = () => defAsyncReducer(keyGetAccountInstagram, asyncInitState);
const fileArchive = () => defAsyncReducer(keyGetFileArchive, asyncInitState);
const routeKeywords = () => defAsyncReducer(keyGetRouteKeywords, asyncInitState);
const blacklist = () => defAsyncReducer(keyGetBlacklist, asyncInitState);
const toolManager = () => defAsyncReducer(keyGetToolManager, asyncInitState);
const whitelist = () => defAsyncReducer(keyGetWhitelist, asyncInitState);
const blacklistResp = () => defAsyncReducer(keyGetBlacklistResp, asyncInitState);
const routingGroups = () => defAsyncReducer(keyGetRoutingGroups, asyncInitState);
const routeAutoTags = () => defAsyncReducer(keyGetRouteAutoTags, asyncInitState);
const agentUploadList = () => defAsyncReducer(keyAgentUploadList, asyncInitState);
const routeSip = () => defAsyncReducer(keyGetRouteSip, asyncInitState);
const autoTagUploadList = () => defAsyncReducer(keyAutoTagUploadList, asyncInitState);
const agentImportStatus = () => defAsyncReducer(keyAgentImportStatus, asyncInitState);
const agentValidate = () => defAsyncReducer(keyAgentValidate, asyncInitState);
const agentEmailAvailability = () => defAsyncReducer(keyAgentEmailAvailability, asyncInitState);
const companyListData = () => defAsyncReducer(keyGetCompanyList, asyncInitState);
const configChangeLog = () => defAsyncReducer(keyGetConfigChangeLog, asyncInitState);
const stunturnListData = () => defAsyncReducer(keyGetStunTurnList, asyncInitState);
const chatWidgetCfgListData = () => defAsyncReducer(keyGetChatWidgetCfgList, asyncInitState);
const chatWidgetCfgDefListData = () => defAsyncReducer(keyGetChatWidgetCfgDefList, asyncInitState);
const faqWidgetCfgListData = () => defAsyncReducer(keyGetFaqWidgetCfgList, asyncInitState);
const faqWidgetCfgDefListData = () => defAsyncReducer(keyGetFaqWidgetCfgDefList, asyncInitState);
const voiceWidgetCfgListData = () => defAsyncReducer(keyGetVoiceWidgetCfgList, asyncInitState);
const voiceWidgetCfgDefListData = () => defAsyncReducer(keyGetVoiceWidgetCfgDefList, asyncInitState);
const agentSipStatus = () => defAsyncReducer(keyAgentSipStatus, asyncInitState);
const fetchAgentSipStatus = () => defAsyncReducer(keyFetchAgentSipStatus, asyncInitState);
const updateAgentSipStatus = () => defAsyncReducer(keyUpdateAgentSipStatus, asyncInitState);
const updateStunTurnStatus = () => defAsyncReducer(keyUpdateStunTurnStatus, asyncInitState);
const classifier = () => defAsyncReducer(keyClassifier, asyncInitState);
const fetchClassifier = () => defAsyncReducer(keyFetchClassifier, asyncInitState);
const updateClassifier = () => defAsyncReducer(keyUpdateClassifier, asyncInitState);
const tagKeywords = () => defAsyncReducer(keyGetTags, asyncInitState);
const tagAdminList = () => defAsyncReducer(keyGetTagsList, asyncInitState);
const corsWhitelist = () => defAsyncReducer(keyGetCORSWhitelist, asyncInitState);
const chatIPBlacklist = () => defAsyncReducer(keyGetChatIPAddressBlacklist, asyncInitState);
const callRecordings = () => defAsyncReducer(keyGetCallRecordings, asyncInitState);
const adminGroups = () => defAsyncReducer(keyGetAdminGroups, asyncInitState);
const groupFolders = () => defAsyncReducer(keyGetGroupFolders, asyncInitState);
const agentSipLogins = () => defAsyncReducer(keyGetAgentSipLogins, asyncInitState);
const sipPriorityNumbers = () => defAsyncReducer(keyGetSipPriorityNumbers, asyncInitState);
const sipOnePriorityNumber = () => defAsyncReducer(keyGetOneSipPrioNumber, asyncInitState);
const groupAgents = () => defAsyncReducer(keyGetGroupAgents, asyncInitState);
const contactCards = () => defAsyncReducer(keyGetContactCard, asyncInitState);
const organisationsKeywords = () => defAsyncReducer(keyGetOrganisations, asyncInitState);
const callAPIList = () => defAsyncReducer(keyGetCallbackAPIList, asyncInitState);
const jwtAPIList = () => defAsyncReducer(keyGetJWTList, asyncInitState);
const getIVRList = () => defAsyncReducer(keyCallIVRList, asyncInitState);
const getSipTrunkList = () => defAsyncReducer(keyCallSipTrunkList, asyncInitState);
const getSkillsCategoryList = () => defAsyncReducer(keyGetSkillsCategory, asyncInitState);
const getSkillAgents = () => defAsyncReducer(keyGetSkillAgents, asyncInitState);
const getAllSkills = () => defAsyncReducer(keyGetSkills, asyncInitState);
const getSkillProficiency = () => defAsyncReducer(keyGetSkillProficiency, asyncInitState);
const getSkillAreas = () => defAsyncReducer(keyGetSkillAreas, asyncInitState);
const agentAssistConfig = () => defAsyncReducer(keyAgentAssist, asyncInitState);
const genaiDocument = () => defAsyncReducer(keyGetGenerativeAIDocument, asyncInitState);
const getLanguages = () => defAsyncReducer(keyLanguageList, asyncInitState);
const genaiDocumentPost = () => defAsyncReducer(keyPostGenerativeAIDocument, asyncInitState);

let admin = combineReducers({
	ui: uiReducers,
	admin: reduceReducers(
		adminReducers
		, createReducer(null, _adminReducers)
	),
	agent: agentReducers,
	[AGENT_ACCESS_KEY_BRANCH]: adminAgentAccessKey,
	[AWSSES_BRANCH]: adminAWSSES,
	[CHATBOT_BRANCH]: adminChatbot,
	[RECEIPT_GREETING_BRANCH]: adminReceiptGreeting,
	[SMTP_BRANCH]: adminSmtp,
	[stateName(keyAgentAdmin)]: agentInfo(),
	[stateName(keyAgentAdminList)]: agentList(),
	[stateName(keyAgentNew)]: agentCreateNew(),
	[stateName(keyAgentAdminSalutations)]: agentAreaSalutations(),
	[stateName(keyAgentAdminSignatures)]: agentAreaSignatures(),
	[stateName(keyAgentWordlist)]: agentWordlist(),
	[stateName(keyEditAgentWordlist)]: wordListData(),
	[stateName(keyGetReviewAddresses)]: reduceReducers(
		defAsyncReducer(
			keyGetReviewAddresses
			, asyncInitState
		)
		, (state, action) => {
			const { payload, type } = action;
			if (type !== done(keySaveReviewAddress)) {
				return state;
			}
			const { data } = payload
				, { id } = data
				, exist = !!state.data.map[id]
				, updater = {data: {map: {$merge: {[id]: data}}}}
				;
			if (!exist) {
				updater.data.list = {$push: [id]};
			}
			return update(state, updater);
		}
		, (state, action) => {
			const { payload, type } = action;
			if (type !== done(keyDeleteReviewAddress)) {
				return state;
			}
			const { list, map } = state.data
				, { id } = payload.data
				, exist = !!map[id]
				, updater = {data: {map: {$unset: [id]}}}
				;
			for (let i=0; i<list.length; i++) {
				if (list[i] === id) {
					updater.data.list = {$splice: [[i, 1]]}
					break;
				}
			}
			return update(state, updater);
		}
		, (state, action) => {
			const { payload, type } = action;
			if (type !== done(keyUploadReviewAddressFile)) {
				return state;
			}
			const { data } = payload
				, { map, list } = data
				, notExist = []
				, updater = {data: {map: {$merge: map}}}
				;
			for (let i=0; i<list.length; i++) {
				const id = list[i];
				if (!state.data.map[id]) {
					notExist.push(id);
				}
			}
			if (notExist.length) {
				updater.data.list = {$push: notExist};
			}
			return update(state, updater);
		}
	),
	[stateName(keyGetReviewAgents)]: reduceReducers(
		defAsyncReducer(
			keyGetReviewAgents
			, asyncInitState
		)
		, (state, action) => {
			const { payload, type } = action;
			if (type !== done(keySaveAgentForReview)) {
				return state;
			}
			const { data } = payload;
			for(let i = 0; i < data.length; i++){
				if(state.data.list.includes(data[i].id)){
					console.log("This agent already included: ", data[i].agentName);
					return state;
				}
			}
			return state;
		}
		, (state, action) => {
			const { payload, type } = action;
			if (type !== done(keyDeleteReviewAgents)) {
				return state;
			}
			const { list, map } = state.data
				, { id } = payload.data
				, exist = !!map[id]
				, updater = {data: {map: {$unset: [id]}}}
				;
			for (let i=0; i<list.length; i++) {
				if (list[i] === id) {
					updater.data.list = {$splice: [[i, 1]]}
					break;
				}
			}
			return update(state, updater);
		}
	),
	[stateName(keyGetSignatures)] : signatures(),
	[stateName(keyGetSalutations)] : salutations(),
	[stateName(keyGetTemplates)]: defAsyncReducer(keyGetTemplates, asyncInitState),
	[stateName(keyGetQuickReplies)]: defAsyncReducer(keyGetQuickReplies, asyncInitState),
	[stateName(keyGetAddressbook)] : addressBook(),
	[stateName(keyGetErrandInternalState)] : errandInternalState(),
	[stateName(keyGetExternalExpertAddressList)] : externalExpertAddr(),
	[stateName(keyAgentUploadList)]: agentUploadList(),
	[stateName(keyAutoTagUploadList)]: autoTagUploadList(),
	[stateName(keyAgentImportStatus)]: agentImportStatus(),
	[stateName(keyAgentValidate)]: agentValidate(),
	[stateName(keyAgentEmailAvailability)]: agentEmailAvailability(),
	[stateName(keyGetAccountEmail)] : accountEmail(),
	[stateName(keyGetAccountLine)] : accountLine(),
	[stateName(keyGetAccountLinkedIn)] : accountLinkedIn(),
	[stateName(keyGetAccountJira)] : accountJira(),
	[stateName(keyGetAccountHubSpot)] : accountHubSpot(),
	[stateName(keyGetAccountMSTeams)] : accountMSTeams(),
	[stateName(keyGetAccountGoogleReview)] : accountGoogleReview(),
	[stateName(keyGetAccountGoogleChat)] : accountGoogleChat(),
	[stateName(keyGetAccountGooglePlay)] : accountGooglePlay(),
	[stateName(keyGetAccountSMPP)] : accountSMPP(),
	[stateName(keyGetAccountYoutube)] : accountYoutube(),
	[stateName(keyGetAccountFacebook)] : accountFacebook(),
	[stateName(keyGetAccountTrustpilot)] : accountTrustpilot(),
	[stateName(keyGetAccountTelegram)] : accountTelegram(),
	[stateName(keyGetAccountWhatsApp)] : accountWhatsApp(),
	[stateName(keyGetAccountTwilio)] : accountTwilio(),
	[stateName(keyGetAccountTwitter)] : accountTwitter(),
	[stateName(keyGetAccountViber)] : accountViber(),
	[stateName(keyGetAccountSlack)] : accountSlack(),
	[stateName(keyGetAccountInstagram)] : accountInstagram(),
	[stateName(keyGetFileArchive)] : fileArchive(),
	[stateName(keyGetRouteKeywords)] : routeKeywords(),
	[stateName(keyGetBlacklist)] : blacklist(),
	[stateName(keyGetToolManager)] : toolManager(),
	[stateName(keyGetWhitelist)] : whitelist(),
	[stateName(keyGetBlacklistResp)] : blacklistResp(),
	[stateName(keyGetRoutingGroups)] : routingGroups(),
	[stateName(keyGetRouteAutoTags)] : routeAutoTags(),
	[stateName(keyGetRouteSip)] : routeSip(),
	[stateName(keyGetCompanyList)]: companyListData(),
	[stateName(keyGetConfigChangeLog)]: configChangeLog(),
	[stateName(keyGetStunTurnList)]: stunturnListData(),
	[stateName(keyGetChatWidgetCfgList)]: chatWidgetCfgListData(),
	[stateName(keyGetChatWidgetCfgDefList)]: chatWidgetCfgDefListData(),
	[stateName(keyGetFaqWidgetCfgList)]: faqWidgetCfgListData(),
	[stateName(keyGetFaqWidgetCfgDefList)]: faqWidgetCfgDefListData(),
	[stateName(keyGetVoiceWidgetCfgList)]: voiceWidgetCfgListData(),
	[stateName(keyGetVoiceWidgetCfgDefList)]: voiceWidgetCfgDefListData(),
	[stateName(keyAgentSipStatus)]: agentSipStatus(),
	[stateName(keyFetchAgentSipStatus)]: fetchAgentSipStatus(),
	[stateName(keyUpdateAgentSipStatus)]: updateAgentSipStatus(),
	[stateName(keyUpdateStunTurnStatus)]: updateStunTurnStatus(),
	[stateName(keyClassifier)]: classifier(),
	[stateName(keyFetchClassifier)]: fetchClassifier(),
	[stateName(keyUpdateClassifier)]: updateClassifier(),
	[stateName(keyGetTags)] : tagKeywords(),
	[stateName(keyGetTagsList)]: tagAdminList(),
	[stateName(keyGetAdminGroups)] : adminGroups(),
	[stateName(keyGetGroupFolders)] : groupFolders(),
	[stateName(keyGetGroupAgents)] : groupAgents(),
	[stateName(keyGetCORSWhitelist)] : corsWhitelist(),
	[stateName(keyGetChatIPAddressBlacklist)] : chatIPBlacklist(),
	[stateName(keyGetCallRecordings)] : callRecordings(),
	[stateName(keyGetAgentSipLogins)] : agentSipLogins(),
	[stateName(keyGetSipPriorityNumbers)] : sipPriorityNumbers(),
	[stateName(keyGetOneSipPrioNumber)] : sipOnePriorityNumber(),
	[stateName(keyGetContactCard)] : contactCards(),
	[stateName(keyGetOrganisations)]: organisationsKeywords(),
	[stateName(keyGetCallbackAPIList)]: callAPIList(),
	[stateName(keyGetJWTList)]: jwtAPIList(),
	[stateName(keyCallSipTrunkList)]: getSipTrunkList(),
	[stateName(keyCallIVRList)]: getIVRList(),
	[stateName(keyGetSkillsCategory)]: getSkillsCategoryList(),
	[stateName(keyGetSkills)]: getAllSkills(),
	[stateName(keyGetSkillProficiency)]: getSkillProficiency(),
	[stateName(keyGetSkillAgents)]: getSkillAgents(),
	[stateName(keyGetSkillAreas)]: getSkillAreas(),
	[stateName(keyAgentAssist)]: agentAssistConfig(),
	[stateName(keyGetGenerativeAIDocument)]: genaiDocument(),
	[stateName(keyLanguageList)]: getLanguages(),
	[stateName(keyPostGenerativeAIDocument)]: genaiDocumentPost(),
});

admin = reduceReducers(admin);

export { admin };
