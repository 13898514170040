import {
	ACQUIRE_ERRAND_OPERATION,
	ACQUIRING_ERRANDS,
	ACTIVATE_ANSWERED_VIEW,
	ADD_FILE_ARCHIVE_TO_ANSWER,
	ADD_LIBRARY_FILE_TO_ANSWER,
	ADD_LIBRARY_FILE_TO_COLLABORATE,
	SELECTED_LIBRARY_QUESTION_TO_ANSWER,
	AGENT_BUSY_TYPING,
	AGENT_NOT_TYPING,
	CHANGE_OTHER_CONTACT_SHOW_IDS,
	CHANGE_REPLY_OPTION,
	CHAT_SWITCH_OC_ERRAND,
	CKEDITOR_REFORMAT_INPUT,
	CLASSIFICATION_TAGS_OPERATION,
	CLEAR_SELECTED_ARCHIVE,
	CLEAR_SELECTED_LIBRARY,
	CLEAR_COLLABORATE_ANS_ATTACHMENT,
	COLLAB_QUERY_THREAD_EXPAND,
	CONTROL_FORWARD_TO_ACTION,
	DELETE_SAVED_ATTACHMENT,
	DELETE_SAVED_COL_ATTACHMENT,
	DELETE_SAVED_INTERNAL_COMMENT_ATTACHMENT,
	DELETE_UPLOADED_ATTACHMENT,
	DELETE_UPLOADED_INTERNAL_COMMENT_ATTACHMENT,
	DELETE_NOTE_ATTACHMENT,
	EDIT_ERRAND_NOTES,
	ERRAND_ANSWER_STATE,
	ERRAND_AREA_OPERATION,
	ERRAND_ATTACHMENT_LIST_DISPLAY,
	ERRAND_CONFIRM_CANCEL,
	ERRAND_CONFIRM_YES,
	ERRAND_DUEDATE_OPERATION,
	ERRAND_ERROR_NOTIF_POPUP,
	ERRAND_NOTES_OPERATION,
	ERRAND_OPENED_AND_READY,
	ERRAND_POST_ANSWER,
	ERRAND_SHOULD_POP_PRINT,
	ERRAND_TAGS_OPERATION,
	EXPAND_HIDE_OPTION,
	HAS_OTHER_OPEN,
	INITIAL_OPEN_ERRAND_THREAD_ID,
	INPUT_TEXT_CHANGES,
	INPUT_TEXT_RESET,
	INSERT_COLLABORATE_ANS_ATTACHMENT,
	MARK_LAST_SAVE_TRIGGERED,
	MARK_EE_LAST_SAVE_TRIGGERED,
	MOVE_OPEN_TO_ASSOCIATE,
	MOVE_LINKED_TO_ASSOCIATE,
	NO_SCROLL_TO_QUESTION,
	NOTE_SIZE,
	PREPARE_POST_LINK,
	RECIPIENTS_CHANGE,
	SAVE_ERRAND,
	SELECT_EXIST_ATTACHMENT,
	SELECT_FORWARD_ALL_HISTORIES,
	SELECT_FORWARD_ALL_Q_ATTACHMENTS,
	SELECT_FORWARD_HISTORY,
	SELECT_INCLUDE_COLLAB_HISTORY,
	SELECT_INCLUDE_ERRAND_HISTORY,
	SET_CLEAR_PREVIEW_SAVE_EML_BUSY,
	PRE_SELECT_FORWARD_HISTORY,
	SELECT_EXTERNALFORWARD_ATTACHMENT,
	SELECT_QUESTION_ATTACHMENT,
	SELECT_ALL_QUESTION_ATTACHMENT,
	SELECT_MANUAL_REPLY,
	SELECT_REPLY_CHANNEL,
	SELECT_COLLAB_REPLY_CHANNEL,
	SELECT_REPLY_TOGGLE_SHOW,
	SELECT_TOGGLE_REPLY_ACTION,
	SELECT_TOGGLE_REPLY_CHANNEL,
	SELECTED_PERSONALIZATION,
	SELECTED_PERSONALIZATION_DEFAULT,
	SET_CSV_FILE_PREVIEW_CONTENT,
	SET_CURRENT_ERRAND,
	SET_PREVIOUS_ERRAND,
	CURRENT_ERRAND_OPENING,
	SHOW_HIDE_OCE_POPUP,
	SYNC_OTHER_CONTACT_ERRANDS,
	SYNC_RELATED_ERRANDS,
	SYNC_REVIEW_DATA,
	UPDATE_CURRENT_ERRAND_HAS_EE,
	SET_ERRAND_HEADER_MOBILE_VIEW,
	SET_ERRAND_OPTION_MOBILE_VIEW,
	TOGGLE_REPLYBOX_ERRANDS_MENU,
	SET_ERRAND_WF_SETTINGS,
	SET_VIEW_SINGLE_ERRAND_ONLY,
	SET_POST_MESSAGE_CHAT,
	SET_TEXT_FILE_PREVIEW_CONTENT,
	SET_NAV_BUTTON_BUSY,
	SHOW_UPLOADED_ATTACHMENT_BOX,
	SOCIAL_MEDIA_ERRANDS_ACTIONS,
	SOCIAL_MEDIA_MSG_SAVE_ACTION,
	SOCIAL_MEDIA_UI_ACTION,
	SYNC_ACQUIRE_DATA,
	SYNC_ACQUIRED_STATE,
	SYNC_ACQUIRED_OWNER,
	SYNC_ALL_BASIC_ERRAND_DATA,
	SYNC_EXTENDED_DATA,
	TOGGLE_ATTACHMENT_PLUS_MINUS,
	TOGGLE_ERRAND_ATTACHMENT_LIST,
	TOGGLE_ERRAND_VISITOR_PATH,
	TOGGLE_ERRAND_PRIORITY,
	TOGGLE_FILE_ARCHIVE,
	TOGGLE_LOCK_TO_ME,
	TOGGLE_PINTOP_ERRAND,
	TOGGLE_SHOW_RECIPIENTS,
	TOGGLE_SHOW_REPLY_TOOLBAR,
	TOGGLE_SHOW_REPLY_ASSIST,
	TOGGLE_SHOW_SUBJECT,
	TOGGLE_REPLY_OPTION_TAB,
	TOGGLE_POSTPONE_ERRAND,
	UPDATE_INPUT_TAGS,
	UPDATE_REPLY_CHECKBOXES,
	UPLOAD_INTERNAL_COMMENT_ATTACHMENT,
	UPLOAD_MANUAL_INTERNAL_COMMENT_ATTACHMENT,
	DELETE_UPLOADED_MANUAL_INTERNAL_COMMENT_ATTACHMENT,
	UPLOAD_CHAT_ATTACHMENT,
	UPLOAD_ERRAND_ANSWER_ATTACHMENT,
	UPLOAD_CUSTOMER_NOTE_ATTACHMENT,
	SET_ANSWER_TRANSLATION,
	SELECT_ERRAND_LIBRARY,
	SEARCH_ERRAND_LIBRARY,
	SHOW_ERRAND_LIBRARY_POPUP,
	SHOW_ERRAND_LIBRARY_LIST,
	RESET_LIBRARY_SEARCH,
	CLEAR_LIBRARY_SEARCH_TIMEOUT,
	SET_LIBRARY_SEARCH_TEXT,
	SET_SHOW_LIBRARY_ANSWER,
	SHOW_CONTACT_BOOK,
	SHOW_CONTACT_CARD,
	SHOW_CONTACT_CARD_HISTORY,
	SHOW_CONTACT_CARD_CHANNEL,
	SET_CONTACT_CARD,
	SELECT_CONTACT_CARD_ANSWERED_HISTORY,
	SELECT_CONTACT_CARD_UNANSWERED_HISTORY,
	UPDATE_CONTACT_CARD,
	UPDATE_ANNOUNCEMENT,
	SET_CUSTOMER_NOTES,
	UPDATE_CUSTOMER_NOTE,
	UPDATE_CONTACT_BOOK,
	APPEND_REPLY_ADDRESS,
	SET_REPLY_ADDRESS,
	SET_REPLY_ADDRESS_TYPE,
	ADD_TO_EXISTING_CONTACTCARD,
	SET_CHAT_TRANSLATION,
	SET_UI_KB_NODE_QUESTION,
	GRANT_EXPORT,
	REVOKE_GRANTED_ACCESS,
	SET_SELECTED_LANG,
	SET_SELECTED_TZ,
	SET_MANUAL_ERRAND_TRANSLATE_TO,
	SET_ERRAND_COUNTDOWN_TIMER,
	HANDLE_POPUP_FILTER_BY_HISTORY_ELEMENTS,
	ERRAND_NOTES_COUNT,
	UPDATE_REPLY_TO_AND_CHANNEL,
	ADD_LIBRARY_FILE_TO_MANUAL,
	UPDATE_TWEET_BOX_TOGGLE_NUMBER_OF_TWEET,
	TOGGLE_EXTERNAL_DATA,
	TOGGLE_CHAT_RATING_BOX,
	SET_CHAT_SATISFACTION_INPUT,
	RESET_AGENT_SATISFACTION_INPUT,
	TOGGLE_CLIENT_CHAT_RATING_BOX,
	SET_CONTACT_BOOK_UI,
	SET_CHAT_EMOTICON_BY_MESSAGE_ID,
	KB_UPDATE_USERVOTE_COMMENT,
	KB_UPDATE_USERVOTE_VOTE,
	KB_UPDATE_USERVOTE_EDIT,
	KB_UPDATE_USERVOTE_WARNING,
	SELECT_HISTORY_ATTACHMENT,
	SELECT_ALL_HISTORY_ATTACHMENT,
	SHOW_COMPANY_INFO,
	ERRAND_TOGGLE_RIGHT_PANEL,
	ERRAND_TOGGLE_CHAT_SIDEPANEL,
	ERRAND_SET_FOLDER_INDEX,
	ERRAND_TOGGLE_RIGHT_SIDEPANEL,
	TOGGLE_CHAT_REPLY_NAV_DD,
	SELECT_CHAT_REPLY_TABS,
	CHAT_VIDEO_CLIENT_READY,
	CHAT_VIDEO_AGENT_SHOW,
	CHAT_VIDEO_CALL_INPROGRESS,
	CHAT_VIDEO_CALL_FULLSCREEN,
	CHAT_VIDEO_CALL_CLIENT_MUTED,
	CHAT_VIDEO_CALL_CLIENT_WEBCAM,
	CHAT_VIDEO_BG_EXTRACTION_STATUS,
	CHAT_VIDEO_UPDATE_TIMER,
	CHAT_AGENT_UNABLE_ANSWER_VIDEO,
	CLIENT_REJECT_VIDEO_CALL,
	CLIENT_VID_CALLING,
	CHAT_AGENT_RECORD_VID,
	CHAT_VIDEO_REQUEST_WIP,
	CHAT_AGENT_SCREEN_SHARING,
	CHAT_AGENT_SCREEN_SHARING_OFFER,
	CHAT_CLIENT_SCREEN_SHARING,
	CHAT_CLIENT_SCREEN_SHARING_OFFER,
	CHAT_CLIENT_COBROWSE_OFFER,
	CHAT_CLIENT_SELECTED_DISPLAY,
	CHAT_TOGGLE_KEYBOARD_CONTROL,
	CHAT_TOGGLE_MOUSE_CONTROL,
	SHOW_REPLY_SEARCH_SHORTCUT_DROPDOWN,
	SHOW_REPLY_SEARCH_INPUT,
	HIDE_REPLY_SEARCH,
	INPUT_CK_RESET,
	WHATSAPP_TEMPLATE_POPUP,
	SET_WHATSAPP_TEMPLATE_CONTENT,
	SAVE_WHATSAPP_TEMPLATE_CODE,
	SHOW_HIDE_ACQUIRE_POPUP,
	SELECT_ALL_ACQUIRE_ERRAND,
	SELECT_ALL_LINK_ERRAND_MY_ERRAND,
	SELECT_ALL_LINKED_ERRAND,
	DESELECT_ALL_ACQUIRE_ERRAND,
	SELECT_MORE_ACQUIRE_ERRAND,
	SAVE_WHATSAPP_TEMPLATE_ID,
	SELECT_MANUAL_CALL_REPLY,
	SET_QUICK_REPLY_CONTENT,
	SET_CRM_EMAILTRIGGER_CONTENT,
	SHOW_ERRAND_REWRITE_ANSWER_POPUP,
	SET_REWRITE_ANSWER_QUESTION,
	UPDATE_REWRITE_ANSWER_STATE,
	RESET_REWRITE_ANSWER,
	EXPAND_KB_PANEL,
	SET_KB_TREE_PARENT,
	FETCH_SUMMARIZED_HISTORY_REQUEST,
	FETCH_SUMMARIZED_HISTORY_SUCCESS,
	FETCH_SUMMARIZED_HISTORY_FAILURE,
	SET_SUGGESTED_ANSWER_USED,
	FETCH_AGENT_ASSIST_REQUEST,
	FETCH_AGENT_ASSIST_SUCCESS,
	FETCH_AGENT_ASSIST_FAILURE,
	FETCH_AGENT_ASSIST_INFO,
	UPDATE_AGENT_ASSIST_PROMPT,
	UPDATE_AGENT_ASSIST_RECEIVED,
	UPDATE_AGENT_ASSIST_RESPONSE,
	FETCH_AGENT_ASSIST_DONE
} from '../constants/constants';
import {
	D_ERRAND_NOTES,
	DEL_ANSWER_ATTACHMENT,
	NOTEOPR_CREATE_NEW,
	RPLY_COLLABORATE,
	RPLY_EXT_FWD,
	RPLY_QUESTION,
	RPLY_COMMENT,
	RPLY_MANUAL,
	THROTTLED_TMR,
	RPLY_ERRAND,
	ECB_INC_HISTORIES,
	MP_BASIC_CALL
} from '../../common/v5/constants';
import {
	moreFileDetail
} from '../../common/v5/helpers';
import { actionReducerCreator, throttledDispatch } from '../util';
import {
	colClearSelectedArchive
} from './collaborate';
import {
	clearSelectedManualArchive,
	defaultManualAccountAndAddress
} from './manual';
import {
	detectLanguage,
	updateShowReplyPanelPref
} from './async/errand';
import {
	showExtTransferKeypad
} from './async/sippRtc';
import {
	agentTimezoneMemoize,
	getCurrentErrand,
	isCurrentErrandWasExternalForwarded,
	replyOptionsMemoize,
	autoOpenExtSystemSelector
} from '../selectors/errand';
import {
	canTranslateChatSelector
} from '../selectors/translation';
import { isCallMemoize } from '../selectors/manual';

const [_uiKbActionMap, _uiKbReducerMap] = actionReducerCreator([
	[SET_UI_KB_NODE_QUESTION, node => ({ questionNode: { $set: node ? node : null } })]
])

export const {
	[SET_UI_KB_NODE_QUESTION]: setKnowledgebaseQuestionNode
} = _uiKbActionMap

export const uiKnowledgebaseReducerMap = _uiKbReducerMap;

export const activateAnsweredView = () => ({
	type: ACTIVATE_ANSWERED_VIEW
	, payload: true
});

export const fetchSummarizedHistoryRequest = () => ({
	type: FETCH_SUMMARIZED_HISTORY_REQUEST,
});

export const fetchSummarizedHistorySuccess = (summarizedHistory) => ({
	type: FETCH_SUMMARIZED_HISTORY_SUCCESS,
	payload: summarizedHistory,
});

export const fetchSummarizedHistoryFailure = (error) => ({
	type: FETCH_SUMMARIZED_HISTORY_FAILURE,
	payload: error,
});

export const agentNotTyping = () => ({type: AGENT_NOT_TYPING});

const throttledAgentIsTyping = throttledDispatch(() => ({type: AGENT_BUSY_TYPING}), THROTTLED_TMR);

export const agentIsTyping = () => throttledAgentIsTyping;

export const cancelScrollToQuestion = () => ({type: NO_SCROLL_TO_QUESTION});

const checkAndToggleAttachmentBox = () => (dispatch, getState) => {
	if(!getState().app.errand.ui.reply.showAttachment) {
		return dispatch(toggleAttachmentList(false));
	}
};

export const changeOtherContactShowIDs = (errand, thread) => ({
	type: CHANGE_OTHER_CONTACT_SHOW_IDS
	, payload: {errand, thread}
});

export const clearCollaborateAnsAttachment = id => ({
	type: CLEAR_COLLABORATE_ANS_ATTACHMENT,
	payload: {id}
});

export const expandCollabQueryThread = (threadID, expand, qId) => ({
	type: COLLAB_QUERY_THREAD_EXPAND
	, payload: {threadID, expand, qId}
});

export const showErrandAttachmentList = show => ({
	type: ERRAND_ATTACHMENT_LIST_DISPLAY,
	payload: show
});

export const setAcquiringErrandsProgress = start => ({
	type: ACQUIRING_ERRANDS,
	payload: {start}
});

const doSetCurrentErrand = (id, hasExternalExpert, chat, isSwitching) => ({
	type: SET_CURRENT_ERRAND
	, payload: {id, ee: hasExternalExpert, chat, isSwitching}
});

const doSetCRMTrigger = (crmValue) => ({
	type: EXPAND_HIDE_OPTION
	, payload: {part: "crmEmailTriggerPanel", value: crmValue}
});

export const doSetAIAnswerPanel = (toggle) => ({
	type: EXPAND_HIDE_OPTION
	, payload: {part: "AIAnswerPanel", value: toggle}
});

export const doSetKnowledgeBasePanel = (toggle) => ({
	type: EXPAND_HIDE_OPTION
	, payload: {part: "KnowledgeBasePanel", value: toggle}
});

export const doExpandKnowledgeBasePanel = (toggle) => ({
	type: EXPAND_KB_PANEL
	, payload: toggle
})

export const setCurrentErrand = (
	id
	, hasExternalExpert
	, chat
	, isSwitching
) => (dispatch, getState) => {
	if (chat) {
		const state = getState();
		let canTranslate = canTranslateChatSelector(state);
		if(canTranslate){
			let fromLang =
				{from: state.app.workflow.fetchWfSettings.data.interface};
			dispatch(setChatTranslation(chat.sessionId, fromLang));
			/* disabled for CEN-2851
			if(chat.messages && chat.messages.length > 0){
				for(let i=0; i < chat.messages.length; i++){
					let msg = chat.messages[i];
					if(msg.umid == "first-message" || i === 0){
						let sessionId = chat.sessionId
						, detected = state.app.errand.ui.chatTranslation[sessionId];
						if(typeof detected === "undefined"){
							dispatch(detectLanguage(msg.text, chat.sessionId));
						}
						break;
					}
				}
			}
			*/
		}
	} else {
		if(features['sip.show-forward-dialer']) {
			dispatch(showExtTransferKeypad(false));
		}
		if (features['triggers.data-fetching']){
			dispatch(doSetCRMTrigger(features['triggers.data-fetching']));
		}
	}
	const pinnedAgentAssistPanel = getState().app.workflow.fetchWfSettings.data.showAgentAssistPanel;
	if(features['openai-rewrite-answer']){
		//when switching errands, reset ai answer base and toggle AI right panel off
		//to avoid confusion
		dispatch(resetAIAnswer('', false, false));
		if(!pinnedAgentAssistPanel) {
			dispatch(expandHideOption('AIAnswerPanel', id, false));
		} else {
			dispatch(expandHideOption('AIAnswerPanel', id, true));
		}
	}
	if(features['cention-library']) {
		dispatch(expandHideOption('KnowledgeBasePanel', id, false));
		dispatch(setErrandLibrarySearch(false));
		dispatch(resetErrandLibrarySearch(true));
	}

	if(features['openai-rewrite-answer'] && pinnedAgentAssistPanel) {
		dispatch(toggleRightSidePanel(true));
	} else {
		if(getState().app.errand.ui.rightSidePanelOpen){
			dispatch(toggleRightSidePanel(false));
		};
	}
	return dispatch(doSetCurrentErrand(
		id
		, hasExternalExpert
		, chat
		, isSwitching
	));
};

export const setPreviousErrand = id => ({
	type: SET_PREVIOUS_ERRAND
	, payload: id
});

export const setCurrentErrandOpening = isOpening => ({
	type: CURRENT_ERRAND_OPENING
	, payload: isOpening
});

export const setInitialThreadID = id => ({
	type: INITIAL_OPEN_ERRAND_THREAD_ID
	, payload: id
});

export const chatSwitchOtherContactErrand = id => ({
	type: CHAT_SWITCH_OC_ERRAND
	, payload: {id}
});

export const showHideOtherContactErrand = show => ({
	type: SHOW_HIDE_OCE_POPUP
	, payload: show
});

export const updateCurrentErrandHasEE = (hasExternalExpert) => ({
	type: UPDATE_CURRENT_ERRAND_HAS_EE,
	payload: {ee: hasExternalExpert}
});

export const setHasAcquirableOther = (id, value) => ({
	type: HAS_OTHER_OPEN,
	payload: {id, value}
});

export const signalViewErrandOnly = value => ({
	type: SET_VIEW_SINGLE_ERRAND_ONLY,
	payload: {value}
});

export const setPostMessageChat = value => ({
	type: SET_POST_MESSAGE_CHAT,
	payload: {value}
});

export const setNavButtonBusy = value => ({
	type: SET_NAV_BUTTON_BUSY,
	payload: {value}
});

const changeReplyOption = value => ({
	type: CHANGE_REPLY_OPTION
	, payload: value
});

export const saveCRMData = (data) => ({
	type: SET_CRM_EMAILTRIGGER_CONTENT
	, payload: data

});


export const changeHistorySelections = (dispatch, state, which) => {
	const { errand, workflow } = state.app
		, currentReply = errand.ui.reply.current
		;
	if ((which === RPLY_EXT_FWD && currentReply !== RPLY_EXT_FWD)
		|| (which === RPLY_COLLABORATE && currentReply !== RPLY_COLLABORATE)) {
		if (workflow.fetchWfSettings.data.fwdToExtAllHistorySelected) {
			dispatch(changeExternalForwardAll(true));
		} else {
			dispatch(selectDefaultForwardHistory(errand.currentErrand.id));
		}
	} else if (which === RPLY_ERRAND && currentReply !== RPLY_ERRAND) {
		dispatch(changeErrandHistorySelections());
	}
};

export const changeErrandHistorySelections = () => (dispatch, getState) => {
	let state = getState(), selectAll = false;
	const { errand, workflow } = state.app;
	const wfData = workflow.fetchWfSettings.data;
	if (wfData) {
		selectAll = wfData.showIncludeAllHistories && wfData.includeAllHistorySelected;
	}
	if (selectAll) {
		dispatch(changeExternalForwardAll(true));
		dispatch(updateReplyCheckboxes(ECB_INC_HISTORIES, state));
	} else {
		dispatch(selectDefaultForwardHistory(errand.currentErrand.id));
	}
};

export const checkAndSwithToExternalForward = () => (dispatch, getState) => {
	const state = getState();
	if (!isCurrentErrandWasExternalForwarded(state)) {
		return;
	}
	changeHistorySelections(dispatch, state, RPLY_EXT_FWD);
	dispatch(changeReplyOption(RPLY_EXT_FWD));
};

// NOTE: this can only signal the errand opened with area, basic, extended and
// acquire AJAX fetched. Other endpoints can not be sure.
export const signalOpenErrandReady = chat => (dispatch, getState) => {
	const state = getState();
	return dispatch({
		type: ERRAND_OPENED_AND_READY
		, payload: {
			chat
			, time: Date.now()
			, replyOptions: replyOptionsMemoize(state)
			, hasAutoExtSystem: autoOpenExtSystemSelector(state)
		}
	});
};
export const setErrandWFSettings = (id, extendedErrand, wfSettings, chat) => ({
	type: SET_ERRAND_WF_SETTINGS,
	payload: {id, extendedErrand, wfSettings, chat}
});

export const markLastSaveTriggered = triggered => ({
	type: MARK_LAST_SAVE_TRIGGERED
	, payload: triggered
});

export const markEELastSaveTriggered = triggered => ({
	type: MARK_EE_LAST_SAVE_TRIGGERED
	, payload: triggered
});

export const noteSize = (size, type) => ({
	type: NOTE_SIZE,
	payload: {size, type}
});

export const expandHideOption = (part, id, value) => ({
	type: EXPAND_HIDE_OPTION,
	payload: {part, id, value}
});

export const saveErrandStart = () => ({type: SAVE_ERRAND});

export const selectExistAttachment = (id, value) => ({
	type: SELECT_EXIST_ATTACHMENT,
	payload: {id, value}
});

export const selectQuestionAttachment = (id, value) => ({
	type: SELECT_QUESTION_ATTACHMENT,
	payload: {id, value}
});

export const selectAllQuestionAttachment = (value, reply) => ({
	type: SELECT_ALL_QUESTION_ATTACHMENT,
	payload: {value, reply}
});

export const selectHistoryAttachment = (id, value) => ({
	type: SELECT_HISTORY_ATTACHMENT,
	payload: {id, value}
});

export const selectAllHistoryAttachemnt = () => ({
	type: SELECT_ALL_HISTORY_ATTACHMENT,
	payload: null
});

export const selectReplyChannel = (channel, manual) => (dispatch, getState) => {
	const state = getState();
	const currentReply = state.app.errand.ui.reply.current;
	const isCall = isCallMemoize(getState());
	if(currentReply == RPLY_COLLABORATE){
		dispatch({type: SELECT_COLLAB_REPLY_CHANNEL,
			payload: {channel, manual, isCall}});
		return;
	}
	dispatch({type: SELECT_REPLY_CHANNEL, payload: {channel, manual, isCall}});
	if (!manual) {
		return;
	}
	let inpt = state.app.errand.manualInputs;
	if(isCall) {
		inpt = state.app.errand.manualCallInputs;
	}
	if (channel !== inpt.reply_channel) {
		dispatch(defaultManualAccountAndAddress());
	}
};

// this control the reply box where both 'which' and 'show' are optional. But if
// 'show' is supplied then 'which' must be supplied too. If no argument provided
// then it will toggle the reply box display without changing existing reply
// selection. If show is not supplied then toggle effect will take over else
// display appearance follow 'show'.
export const selectShowReply = (which, show) => (dispatch, getState) => {
	const state = getState()
	, errand = state.app.errand
	, uiReply = errand.ui.reply;

	let toggleTo = false;
	if(typeof show !== 'undefined') {
		toggleTo = show;
	} else {
		if(which){
			if(which === uiReply.current){
				toggleTo = !uiReply.show;
			}else{
				if(!uiReply.show){
					toggleTo = true;
				}else{
					toggleTo = uiReply.show;
				}
			}
		}else{
			toggleTo = !uiReply.show;
		}
	}
	dispatch({type: SELECT_REPLY_TOGGLE_SHOW, payload: {current: which, show}});
	if(which === RPLY_COMMENT || uiReply.current === RPLY_COMMENT) {
		if(toggleTo) {
			dispatch(toggleRightPanel(true));
		}
	}else{
		if(uiReply.current !== RPLY_COMMENT){
			dispatch(toggleRightPanel(false));
		}
	}
	// TODO: check all the code use this select show reply to avoid unnecessary
	// backend async trigger.
	dispatch(updateShowReplyPanelPref(toggleTo));
};

export const toggleChatNavDD = toggle => ({
	type: TOGGLE_CHAT_REPLY_NAV_DD,
	payload: toggle
});
export const selectChatReplyTab = toggle => ({
	type: SELECT_CHAT_REPLY_TABS,
	payload: toggle
});

// export const selectShowReplyChannel = data => ({
// 	type: SELECT_TOGGLE_REPLY_CHANNEL,
// 	payload: data
// });

export const showHideReplyChannel = show => ({
	type: SELECT_TOGGLE_REPLY_CHANNEL,
	payload: show
});

export const selectReplyChannelByType = typeId => (dispatch, getState) => {
	const c = getState().server.services.byType[typeId].channel;
	// dispatch(selectShowReplyChannel(c));
	dispatch(selectReplyChannel(c));
};

export const selectShowReplyAction = data => ({
	type: SELECT_TOGGLE_REPLY_ACTION,
	payload: data
});

export const syncRelatedErrands = (id, errands, isSwitching) => ({
	type: SYNC_RELATED_ERRANDS
	, payload: {id, errands, isSwitching}
});

export const syncOtherContactErrands = (id, errands) => ({
	type: SYNC_OTHER_CONTACT_ERRANDS
	, payload: {id, errands}
});

export const toggleRecipients = toggle => ({
	type: TOGGLE_SHOW_RECIPIENTS,
	payload: toggle
});

export const toggleReplyToolbar = toggle => ({
	type: TOGGLE_SHOW_REPLY_TOOLBAR,
	payload: toggle
});

export const toggleReplyAssist = toggle => ({
	type: TOGGLE_SHOW_REPLY_ASSIST,
	payload: toggle
});

export const toggleSubject = toggle => ({
	type: TOGGLE_SHOW_SUBJECT,
	payload: toggle
});

export const toggleReplyOptionTab = toggle => ({
	type: TOGGLE_REPLY_OPTION_TAB,
	payload: toggle
})

export const inputTextChanges = (which, value, plainOrReply, reply) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	const isAIAnswerPanelOpened = getState().app.errand.ui.rightSidePanelOpen;
	let plain;
	if(which !== 'update_answer' && which !== 'update_question' &&
		which !== 'update_answer_append' && which !== 'internal_comment') {
		reply = plainOrReply;
	} else {
		plain = plainOrReply;
	}
	if(which === 'update_answer' && isAIAnswerPanelOpened){
		dispatch(setSetRAQuestion(plain));
	}
	return dispatch({
		type: INPUT_TEXT_CHANGES
		, payload: {which, value, plain, reply, isCall}
	});
}

export const inputTextChangesForCall = (which, value, plainOrReply, reply) => (dispatch, getState) => {
	const isCall = true;
	let plain;
	if(which !== 'update_answer' && which !== 'update_question' &&
		which !== 'update_answer_append' && which !== 'internal_comment') {
		reply = plainOrReply;
	} else {
		plain = plainOrReply;
	}
	return dispatch({
		type: INPUT_TEXT_CHANGES
		, payload: {which, value, plain, reply, isCall}
	});
}

export const clearInputText = (which, reply, isCall) => ({
	type: INPUT_TEXT_CHANGES,
	payload: {which, value: '', plain: '', reply, isCall}
});

export const resetInputText = (which) => ({
	type: INPUT_TEXT_RESET,
	payload: {which}
});

export const resetCK = () => ({
	type: INPUT_CK_RESET,
	payload: ""
});

// this action only trigger when reply is errand or external forward. It is
// triggered when Ckeditor reformat the text that pass to it. This auto reformat
// input text (which is NOT trigger by user typing) will not count as dirty
// flag caused by agent and thus switching errand will not trigger changes
// detection.
export const updateReformatAnswer = (value, plain) => (dispatch, getState) => {
	if(!getState().app.errand.ui.lastSave.ckeditor) {
		dispatch(inputTextChanges('update_answer', value, plain));
	} else {
		dispatch({
			type: CKEDITOR_REFORMAT_INPUT,
			payload: {value, plain}
		});
	}
};

export const insertCollaborateAnsAttachment = attachment => (dispatch, getState) => {
	dispatch({
		type: INSERT_COLLABORATE_ANS_ATTACHMENT,
		payload: attachment
	});
	dispatch(checkAndToggleAttachmentBox());
};

export const showUploadAttachment = show => ({
	type: SHOW_UPLOADED_ATTACHMENT_BOX,
	payload: show
});

export const cancelConfirm = () => ({type: ERRAND_CONFIRM_CANCEL});

export const confirmYes = () => ({type: ERRAND_CONFIRM_YES});

export const notesOperation = (opr, value) => ({
	type: ERRAND_NOTES_OPERATION,
	payload: {opr, value}
});

export const confirmEditNote = (note, errand, isChat) => (dispatch, getState) => {
	if(note === 0) {
		return dispatch(notesOperation(NOTEOPR_CREATE_NEW));
	}
	dispatch(selectShowReply('comment', true));
	let value = '', attachments = [];
	let notes = [];
	if(isChat == true){
		notes = getState().app.errand.chat.errand.notes;
	} else {
		notes = getState().domain[D_ERRAND_NOTES].byId[errand].notes;
	}
	$.each(notes, (i,v) => {
		if(v.id === note) {
			value = v.note;
			if(v.attachments && v.attachments.length) {
				attachments = v.attachments;
			}
			return false;
		}
	});
	dispatch({type: EDIT_ERRAND_NOTES, payload: {note, errand, value,
		attachments}});
	if(attachments.length || getState().app.errand.inputs.internal_comment_uploaded_attachments.length) {
		// force attachment upload box to show up as there is attachment.
		dispatch(showUploadAttachment(true));
	}
};

export const tagsOperation = (opr, value, manual) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	dispatch({
		type: ERRAND_TAGS_OPERATION
		, payload: {opr, value, manual, isCall}
	});
}

export const moveOpenToAssociate = (errands, select, related) => ({
	type: MOVE_OPEN_TO_ASSOCIATE
	, payload: {errands, select, related}
});

export const moveLinkedToAssociate = (errands, select, related) => ({
	type: MOVE_LINKED_TO_ASSOCIATE
	, payload: {errands, select, related}
});

export const acquireOperations = (opr, value) => ({
	type: ACQUIRE_ERRAND_OPERATION,
	payload: {opr, value}
});

export const acquireAllOperations = selectAll => ({
	type: SELECT_ALL_ACQUIRE_ERRAND,
	payload: selectAll
});

export const linkErrandMyErrandAllOperations = selectAll => ({
	type: SELECT_ALL_LINK_ERRAND_MY_ERRAND,
	payload: selectAll
});

export const linkedErrandAllOperations = selectAll => ({
	type: SELECT_ALL_LINKED_ERRAND,
	payload: selectAll
});

export const acquireMoreOperations = () => ({
	type: SELECT_MORE_ACQUIRE_ERRAND,
	payload: null
});

export const acquireDeselectAllOperations = select => ({
	type: DESELECT_ALL_ACQUIRE_ERRAND,
	payload: select
});

export const AO_CHANGE = 'change' // ao = area operation
	, AO_SHOW = 'show'
	, areaOperation = (opr, value, manual, isCall) => ({
		type: ERRAND_AREA_OPERATION
		, payload: {opr, value, manual, isCall}
	})
	;
// 'id' is optional, if omit then toggle will be either toggle all or toggle
// none.
export const toggleAllErrandHistories = (value, id) => ({
	type: SELECT_FORWARD_ALL_HISTORIES,
	payload: { id, value }
});

export const changeExternalForwardAll = value => (
	dispatch,
	getState
) => dispatch(toggleAllErrandHistories(value, getCurrentErrand(getState())));

export const changeIncludeCollabHistory = value =>
	(dispatch, getState) =>
		dispatch({
			type: SELECT_INCLUDE_COLLAB_HISTORY
			, payload: {id: getCurrentErrand(getState()), value
		}});

export const changeIncludeErrandHistory = value =>
	(dispatch, getState) =>
		dispatch({
			type: SELECT_INCLUDE_ERRAND_HISTORY
			, payload: {id: getCurrentErrand(getState()), value
		}});

export const changeExternalForwardQuestionAllAttachmentsSelection = value => ({
	type: SELECT_FORWARD_ALL_Q_ATTACHMENTS
	, payload: {value}
});

export const selectForwardHistory = (id, value) => ({
	type: SELECT_FORWARD_HISTORY,
	payload: {id, value}
});

const selectDefaultForwardHistory = (id) => ({
	type: PRE_SELECT_FORWARD_HISTORY,
	payload: {id}
});

export const selectExternalForwardAttachment = (id, value) => ({
	type: SELECT_EXTERNALFORWARD_ATTACHMENT,
	payload: {id, value}
});

export const toggleErrandHeaderMobile = toggle => ({
	type: SET_ERRAND_HEADER_MOBILE_VIEW,
	payload: toggle
});

export const toggleErrandOptionMobile = toggle => ({
	type: SET_ERRAND_OPTION_MOBILE_VIEW,
	payload: toggle
});
export const toggleReplyBoxErrandMenu = toggle => ({
	type: TOGGLE_REPLYBOX_ERRANDS_MENU,
	payload: toggle
});

export const updateAllBasicErrandData = data => ({
	type: SYNC_ALL_BASIC_ERRAND_DATA,
	payload: {data}
});

const updateErrandsAcquiredState = (agentId, agent) => ({
	acquired: {$set: true}
	, data: {$merge: {agentId, agent}}
});

export const syncAcquireData = data => ({
	type: SYNC_ACQUIRE_DATA
	, payload: {data}
});

export const syncAcquiredState = (errandIDs, agentID, name) => ({
	type: SYNC_ACQUIRED_STATE,
	payload: {errandIDs, data: updateErrandsAcquiredState(agentID, name)}
});

export const syncAcquiredOwner = (owner) => ({
	type: SYNC_ACQUIRED_OWNER,
	payload: {owner}
});

export const syncExtendedData = data => ({
	type: SYNC_EXTENDED_DATA,
	payload: {data}
});

export const syncReviewData = payload => ({type: SYNC_REVIEW_DATA, payload});

export const checkInternalCommentState = errand => (dispatch, getState) => {
	const en = getState().domain[D_ERRAND_NOTES].byId[errand];
	if(!en || !en.notes || !en.notes.length) {
		dispatch(noteSize(0, 'errand'));
		return;
	}
	dispatch(noteSize(en.notes.length, 'errand'));
};

export const errandNotesCount = (errand_notes) => ({
	type: ERRAND_NOTES_COUNT,
	payload: {errand_notes}
});

export const toggleLockToMe = (id, lock) => ({
	type: TOGGLE_LOCK_TO_ME,
	payload: {id, lock}
});

export const togglePostponeErrand = toggle => ({
    type: TOGGLE_POSTPONE_ERRAND
    , payload: toggle
});

export const togglePriority = (data) => ({
	type: TOGGLE_ERRAND_PRIORITY,
	payload: data
});

export const pinErrandToTop = (id, toggle) =>({
	type: TOGGLE_PINTOP_ERRAND,
	payload: toggle
})

export const classificationTagging = (opr, value, id, isCurrentErrand, isDeleting = false) => ({
	type: CLASSIFICATION_TAGS_OPERATION,
	payload: {opr, value, id, isCurrentErrand, isDeleting}
});

export const recipientsChange = (value, which, reply) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	dispatch({
		type: RECIPIENTS_CHANGE
		, payload: {value, which, reply, isCall}
	});
}

export const callRecipientsChange = (value, which, reply) => (dispatch, getState) => {
	dispatch({
		type: RECIPIENTS_CHANGE
		, payload: {value, which, reply, isCall: true}
	});
}

export const answerState = (state, id) => ({
	type: ERRAND_ANSWER_STATE,
	payload: {state, id}
});

export const controlActionForward = (actionFor, applyWith) => ({
	type: CONTROL_FORWARD_TO_ACTION,
	payload: {actionFor, applyWith}
});

export const postReplyActions = reply => ({
	type: ERRAND_POST_ANSWER,
	payload: {reply}
});

export const printErrandAction = doPrint => ({
	type: ERRAND_SHOULD_POP_PRINT,
	payload: {doPrint}
});

export const updateInputTags = (areaTags, manual, isCall) => ({
	type: UPDATE_INPUT_TAGS,
	payload: {areaTags, manual, isCall}
});

export const updateReplyCheckboxes = (type, value) => ({
	type: UPDATE_REPLY_CHECKBOXES,
	payload: {type, value}
});

export const dueDateOperation = value => ({
	type: ERRAND_DUEDATE_OPERATION,
	payload: value
});

export const handleMediaActions = (value, which) =>({
	type: SOCIAL_MEDIA_ERRANDS_ACTIONS,
	payload:{value, which}
});

export const toggleSocialMediaUI = (tgl, whom) =>({
	type: SOCIAL_MEDIA_UI_ACTION,
	payload:{tgl, whom}
});
export const toggleWaTemplPopup = (tgl) =>({
	type: WHATSAPP_TEMPLATE_POPUP,
	payload: tgl
});
export const setWaTemplContent = (content, id) =>({
	type: SET_WHATSAPP_TEMPLATE_CONTENT,
	payload: {id, content}
});

export const setQuickReplyContent = (id, content) =>({
	type: SET_QUICK_REPLY_CONTENT,
	payload: {id, content}
});
export const saveWATemplateCode = (code) =>({
	type: SAVE_WHATSAPP_TEMPLATE_CODE,
	payload: {code}
});
export const saveWATemplateId = (tid, eId) =>({
	type: SAVE_WHATSAPP_TEMPLATE_ID,
	payload: {tid: tid, errandId: eId}
});
export const messageSocialMedia =(acType, msg, whom) =>({
	type: SOCIAL_MEDIA_MSG_SAVE_ACTION,
	payload:{acType, msg, whom}
});

export const handleErrorNotifPopup = value => ({
	type: ERRAND_ERROR_NOTIF_POPUP,
	payload: value
})

export const setPersonalization = (field, value, reply) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	dispatch({
		type: SELECTED_PERSONALIZATION
		, payload: {field, value, reply, isCall}
	});
}

export const setPersonalizationDefault = (field, value, reply) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	dispatch({
		type: SELECTED_PERSONALIZATION_DEFAULT
		, payload: {field, value, reply, isCall}
	});
}

export const detailAttachment = (type, file, chat) => (dispatch, getState) => {
	const tz = agentTimezoneMemoize(getState());
	return dispatch({type, payload: moreFileDetail(file, tz), chat});
};

export const uploadedAnswerAttachments = file => detailAttachment(UPLOAD_ERRAND_ANSWER_ATTACHMENT, file);

export const uploadedInternalCommentAttachments = file => detailAttachment(UPLOAD_INTERNAL_COMMENT_ATTACHMENT, file);

export const uploadedManualInternalCommentAttachments = file => detailAttachment(UPLOAD_MANUAL_INTERNAL_COMMENT_ATTACHMENT, file);

export const uploadedChatAttachments = (chat, file) => detailAttachment(UPLOAD_CHAT_ATTACHMENT, file, chat);

export const uploadedNoteAttachments = file => detailAttachment(UPLOAD_CUSTOMER_NOTE_ATTACHMENT, file);

export const toggleAttachmentList = tgl =>({
	type: TOGGLE_ERRAND_ATTACHMENT_LIST,
	payload: tgl
});

export const togglePopupFilterByElem = (elem, toggle) =>({
	type: HANDLE_POPUP_FILTER_BY_HISTORY_ELEMENTS,
	payload: {elem, toggle}
});

export const toggleVisitorPath = tgl =>({
	type: TOGGLE_ERRAND_VISITOR_PATH,
	payload: tgl
});

export const deleteUploadedAnswerAttachment = (which, aId) => ({
	type: DELETE_UPLOADED_ATTACHMENT,
	payload:{which, id: aId}
});

// NOTE: which arg is not use here.
export const deleteUploadedInternalCommentAttachment = (id, which) => ({
	type: DELETE_UPLOADED_INTERNAL_COMMENT_ATTACHMENT,
	payload: {id}
});

export const deleteUploadedManualInternalCommentAttachment = (id) => ({
	type: DELETE_UPLOADED_MANUAL_INTERNAL_COMMENT_ATTACHMENT,
	payload: {id}
});

// TODO: which arg is redudant, should be removed.
export const deleteSavedAnswerAttachment = (id, which) => ({
	type: DELETE_SAVED_ATTACHMENT,
	payload: {id, which}
});

export const deleteSavedColAttachment = (id, which) => ({
	type: DELETE_SAVED_COL_ATTACHMENT,
	payload: {id, which}
});

// NOTE: which arg is not use here.
export const deleteSavedInternalCommentAttachment = (id, which) => ({
	type: DELETE_SAVED_INTERNAL_COMMENT_ATTACHMENT,
	payload: {id}
});

export const deleteSavedAttachment = (reply, id) => (dispatch, getState) => {
	if(reply === RPLY_COMMENT) {
		return dispatch(deleteSavedInternalCommentAttachment(id));
	} else if(reply === RPLY_COLLABORATE) {
		return dispatch(deleteSavedColAttachment(id, DEL_ANSWER_ATTACHMENT));
	} else {
		// NOTE: saved attachment is only being deleted from front-end RAM and
		// no interaction with backend as the backend only search for temporary
		// upload attachments for deletion. However, once it is removed, and
		// save/update errand endpoint will handle this deleted saved
		// attachment properly.
		return dispatch(deleteSavedAnswerAttachment(id, DEL_ANSWER_ATTACHMENT));
	}
};

export const deleteNoteAttachment = (id) => ({
	type: DELETE_NOTE_ATTACHMENT,
	payload:id
});

export const handleFileArchives = (selectedId, archiveList) => ({
	type: ADD_FILE_ARCHIVE_TO_ANSWER,
	payload:{selectedId, archiveList}
});

export const handleLibraryAttachments = (libraryList, reply) => {
	if(reply === RPLY_MANUAL) {
		return {
			type: ADD_LIBRARY_FILE_TO_MANUAL,
			payload:{libraryList}
		};
	} if (reply == RPLY_COLLABORATE){
		return {
			type: ADD_LIBRARY_FILE_TO_COLLABORATE,
			payload:{libraryList}
		};
	}else {
		return {
			type: ADD_LIBRARY_FILE_TO_ANSWER,
			payload:{libraryList}
		};
	}
};

export const selectedLibraryQuestion = id => {
	return {
		type: SELECTED_LIBRARY_QUESTION_TO_ANSWER,
		payload:{selectedId: id}
	}
};

export const preparePostLink = (format, data) => ({
	type: PREPARE_POST_LINK
	, payload: {format, data}
});

export const clearPostLink = () => ({type: PREPARE_POST_LINK});

export const setClearPreivewOrSaveEmlBusy = busy => ({
	type: SET_CLEAR_PREVIEW_SAVE_EML_BUSY
	, payload: busy
});

export const toggleFileArchive = tgl =>({
	type: TOGGLE_FILE_ARCHIVE,
	payload:tgl
});

export const clearSelectedArchive = (which, afId) => ({
	type: CLEAR_SELECTED_ARCHIVE,
	payload: {which, id: afId}
});

export const clearSelectedLibrary = (which, afId) => ({
	type: CLEAR_SELECTED_LIBRARY,
	payload: {which, id: afId}
});

export const removeSelectedArchive = (reply, id) => (dispatch, getState) => {
	if(reply === RPLY_MANUAL) {
		return dispatch(clearSelectedManualArchive(id));
	} else if(reply === RPLY_COMMENT) {
		// do nothing as internal comment no archive supported.
		return;
	} else if(reply !== RPLY_COLLABORATE) {
		return dispatch(clearSelectedArchive(DEL_ANSWER_ATTACHMENT, id));
	} else {
		return dispatch(colClearSelectedArchive(DEL_ANSWER_ATTACHMENT, id));
	}
};

export const handleAttachmentToggleBox = tgl =>({
	type: TOGGLE_ATTACHMENT_PLUS_MINUS,
	payload: tgl
});
export const setTextFilePreviewContent = txt => ({
	type: SET_TEXT_FILE_PREVIEW_CONTENT,
	payload: txt
});
export const setCSVFilePreviewContent = txt => ({
	type: SET_CSV_FILE_PREVIEW_CONTENT,
	payload: txt
});
export const setAnswerTranslation = (field, value) => ({
	type: SET_ANSWER_TRANSLATION,
	payload: {field, value}
});
export const selectErrandLibrary = tgl => ({
	type: SELECT_ERRAND_LIBRARY,
	payload: tgl
});
export const setErrandLibrarySearch = tgl => ({
	type: SEARCH_ERRAND_LIBRARY,
	payload: tgl
});
export const showErrandLibraryPopup = (tgl, reply) => ({
	type: SHOW_ERRAND_LIBRARY_POPUP,
	payload: {tgl, reply}
});
export const showErrandRewriteAnswerPopup = (tgl, reply) => ({
	type: SHOW_ERRAND_REWRITE_ANSWER_POPUP,
	payload: {tgl, reply}
});
export const updateAIAnswerstate = tgl => ({
	type: UPDATE_REWRITE_ANSWER_STATE,
	payload: {tgl}
});
export const resetErrandLibrarySearch = resetAttachment => ({
	type: RESET_LIBRARY_SEARCH,
	payload: resetAttachment
});
export const clearLibrarySearchTimeout = () => ({
	type: CLEAR_LIBRARY_SEARCH_TIMEOUT,
	payload: ""
});
export const showLibraryAnswer = p => ({
	type: SET_SHOW_LIBRARY_ANSWER,
	payload: p
});
export const setSearchText = text => ({
	type: SET_LIBRARY_SEARCH_TEXT,
	payload: text
})
export const setSetRAQuestion = text => ({
	type: SET_REWRITE_ANSWER_QUESTION,
	payload: text
})
export const resetAIAnswer = (text, closePopup, startOver) => ({
	type: RESET_REWRITE_ANSWER,
	payload: {text, closePopup, startOver}
})
export const showErrandLibraryList = tgl => ({
	type: SHOW_ERRAND_LIBRARY_LIST,
	payload: tgl
});
export const showContactBook = tgl => ({
	type: SHOW_CONTACT_BOOK,
	payload: tgl
});
export const setReplyAddressType = (replyType, context) => ({
	type: SET_REPLY_ADDRESS_TYPE,
	payload: {replyType, context}
});
export const showContactCard = (tgl, obj) => ({
	type: SHOW_CONTACT_CARD,
	payload: tgl
});
export const showContactCardHistory = (tgl) => ({
	type: SHOW_CONTACT_CARD_HISTORY,
	payload: tgl
});
export const showContactCardChannel = tgl => ({
	type: SHOW_CONTACT_CARD_CHANNEL,
	payload: tgl
});
export const showCompanyInfo = tgl => ({
	type: SHOW_COMPANY_INFO,
	payload: tgl
});
export const setContactCard = (field, value, filename, filetype) => ({
	type: SET_CONTACT_CARD,
	payload: {field, value,filename, filetype}
});
export const selectContactCardAnsweredHistory = tgl => ({
	type: SELECT_CONTACT_CARD_ANSWERED_HISTORY,
	payload: tgl
});
export const selectContactCardUnansweredHistory = tgl => ({
	type: SELECT_CONTACT_CARD_UNANSWERED_HISTORY,
	payload: tgl
});
export const updateContactCard = (field, obj) => ({
	type: UPDATE_CONTACT_CARD,
	payload: {field, obj}
});
export const updateAnnouncement = (field, obj) => ({
	type: UPDATE_ANNOUNCEMENT,
	payload: {field, obj}
});

export const setCustomerNotes = obj => ({
	type: SET_CUSTOMER_NOTES,
	payload: obj
});
export const updateCustomerNote = (field, obj) => ({
	type: UPDATE_CUSTOMER_NOTE,
	payload: {field, obj}
});
export const updateContactBook = (field, obj) => ({
	type: UPDATE_CONTACT_BOOK,
	payload: {field, obj}
});
export const appendReplyAddress = (id, email) => (dispatch, getState) => {
	const isCall = isCallMemoize(getState());
	let updatetype = APPEND_REPLY_ADDRESS;
	if(getState().app.workflow.ui.showManualCall === MP_BASIC_CALL) {
		updatetype = SET_REPLY_ADDRESS;
	}
	return dispatch({
		type: updatetype,
		payload: {id, email, isCall}
	});
};
export const setChatTranslation = (id, obj) => ({
	type: SET_CHAT_TRANSLATION,
	payload: {id: id, obj: obj}
});
export const setExportLog = (data) => ({
	type: GRANT_EXPORT,
	payload: data
})
export const revokeGrantedAccess = (data) => ({
	type: REVOKE_GRANTED_ACCESS,
	payload: data
});
export const setSelectedTZ = (tz) => ({
	type: SET_SELECTED_TZ,
	payload: tz
})
export const setSelectedLang = (lang) => ({
	type: SET_SELECTED_LANG,
	payload: lang
})
export const setErrandCloseTimer = (eid, ts) => ({
	type: SET_ERRAND_COUNTDOWN_TIMER,
	payload: {eid, ts}
})
export const setManualErrandTranslateTo = (lang) => ({
	type: SET_MANUAL_ERRAND_TRANSLATE_TO,
	payload: lang
})
export const updateReplyToAndChannel = (service, toAddress) => ({
	type: UPDATE_REPLY_TO_AND_CHANNEL,
	payload: {service, toAddress}
})
export const updateTweetWarning = (tgl, nt) => ({
	type: UPDATE_TWEET_BOX_TOGGLE_NUMBER_OF_TWEET,
	payload: {tgl, nt}
})
export const toggleExternalData = () => ({
	type: TOGGLE_EXTERNAL_DATA,
	payload: ""
})
export const toggleChatRatingBox = (t) => ({
	type: TOGGLE_CHAT_RATING_BOX,
	payload: t
})
export const toggleClientChatRatingBox = (t) => ({
	type: TOGGLE_CLIENT_CHAT_RATING_BOX,
	payload: t
})
export const handleChatRating = (rt, type) => ({
	type: SET_CHAT_SATISFACTION_INPUT,
	payload: {type: type, value: rt}
})
export const handleChatRatingBody = (b) => ({
	type: SET_CHAT_SATISFACTION_INPUT,
	payload: {body: true, value: b}
})
export const resetAgentSatisfaction = () => ({
	type: RESET_AGENT_SATISFACTION_INPUT,
	payload: ""
})
export const setContactBookUI = (field, type) => ({
	type: SET_CONTACT_BOOK_UI,
	payload: {field, type}
})

export const selectManualReply = (reply) => ({
	type: SELECT_MANUAL_REPLY,
	payload: reply
})

export const selectManualCallReply = (reply) => ({
	type: SELECT_MANUAL_CALL_REPLY,
	payload: reply
})

export const updateUserVoteComment = (value) => ({
	type: KB_UPDATE_USERVOTE_COMMENT
	, payload: value
});

export const updateUserVoteVote = (vote) => ({
	type: KB_UPDATE_USERVOTE_VOTE
	, payload: vote
});

export const updateUserVoteEdit = (toggle) => ({
	type: KB_UPDATE_USERVOTE_EDIT
	, payload: toggle
});

export const updateUserVoteWarning = (toggle) => ({
	type: KB_UPDATE_USERVOTE_WARNING
	, payload: toggle
});

export const toggleRightPanel = (toggle) => ({
	type: ERRAND_TOGGLE_RIGHT_PANEL
	, payload: toggle
});

export const toggleErrandChatSidePanel = (toggle) => ({
	type: ERRAND_TOGGLE_CHAT_SIDEPANEL
	, payload: toggle
});

export const setGridFolderIndex = (idx) => ({
	type: ERRAND_SET_FOLDER_INDEX
	, payload: idx
});

export const toggleRightSidePanel = (toggle) => ({
	type: ERRAND_TOGGLE_RIGHT_SIDEPANEL
	, payload: toggle
});

export const showVideoCallFrame = (sessionId, show, fromAgent) => ({
	type: CHAT_VIDEO_AGENT_SHOW,
	payload: {sessionId, show, fromAgent}

});

export const updateVideoInProgress = (sessionId, status) => ({
	type: CHAT_VIDEO_CALL_INPROGRESS,
	payload: {sessionId, status}
});

export const toggleVidChatFullScreen = (toggle) => ({
	type: CHAT_VIDEO_CALL_FULLSCREEN,
	payload: toggle
});

export const updateVideoExtractingStatus = (status) => ({
	type: CHAT_VIDEO_BG_EXTRACTION_STATUS,
	payload: status
})

export const toggleVidChatClientMute = (toggle) => ({
	type: CHAT_VIDEO_CALL_CLIENT_MUTED,
	payload: toggle
});

export const toggleVidChatClientWebcam = (toggle) => ({
	type: CHAT_VIDEO_CALL_CLIENT_WEBCAM,
	payload: toggle
});

export const updateVideoCallTimer = (time) => ({
	type: CHAT_VIDEO_UPDATE_TIMER,
	payload: time
});

export const updateAgentUnAvailOnVidCall = (status) => ({
	type: CHAT_AGENT_UNABLE_ANSWER_VIDEO,
	payload: status
});

export const handleClientRejectVidCall = (status) => ({
	type: CLIENT_REJECT_VIDEO_CALL,
	payload: status
})

export const handleClientVideoCalling = (status) => ({
	type: CLIENT_VID_CALLING,
	payload: status
});

export const handleAgentRecordVid = (sessionId, status) => ({
	type: CHAT_AGENT_RECORD_VID,
	payload: {sessionId, status}
});

export const handleVideoCallRequest = (status) => ({
	type: CHAT_VIDEO_REQUEST_WIP,
	payload: status
});

export const handleAgentScreenShare = (status) => ({
	type: CHAT_AGENT_SCREEN_SHARING,
	payload: status
});

export const handleAgentScreenShareOffer = (status) => ({
	type: CHAT_AGENT_SCREEN_SHARING_OFFER,
	payload: status
});

export const handleClientScreenShare = (status) => ({
	type: CHAT_CLIENT_SCREEN_SHARING,
	payload: status
});

export const handleClientScreenShareOffer = (status) => ({
	type: CHAT_CLIENT_SCREEN_SHARING_OFFER,
	payload: status
});

export const handleClientReadyForVideoCall = (sessionId, status) => ({
	type: CHAT_VIDEO_CLIENT_READY,
	payload: {sessionId, status}
});

export const handleClientOfferCoBrowsing = (sessionId, status) => ({
	type: CHAT_CLIENT_COBROWSE_OFFER,
	payload: {sessionId, status}
});

export const handleClientSelectedDisplay = (display) => ({
	type: CHAT_CLIENT_SELECTED_DISPLAY,
	payload: display
});

export const handleAgentMouseAccess = (enable) => ({
	type: CHAT_TOGGLE_MOUSE_CONTROL,
	payload: enable
});

export const handleAgentKeyboardAccess = (enable) => ({
	type: CHAT_TOGGLE_KEYBOARD_CONTROL,
	payload: enable
});


export const showSearchShortcutDropdown = tgl => ({
	type: SHOW_REPLY_SEARCH_SHORTCUT_DROPDOWN,
	payload: tgl
});

export const showSearchInput = tgl => ({
	type: SHOW_REPLY_SEARCH_INPUT,
	payload: tgl
});

export const handleReplySearchHide = val => ({
	type: HIDE_REPLY_SEARCH,
	payload: val
});

export const showHideAcquireErrand = show => ({
	type: SHOW_HIDE_ACQUIRE_POPUP,
	payload: show
})

export const setKBTreeParent = (category) => ({
	type: SET_KB_TREE_PARENT,
	payload: category
});

export const setSuggestedAnswerUsed = (answer) => ({
	type: SET_SUGGESTED_ANSWER_USED,
	payload: answer
});

export const fetchAgentAssistRequest = (errandId, context, prompt, streamId) => ({
	type: FETCH_AGENT_ASSIST_REQUEST,
	payload: ({errandId, context, prompt, streamId}),
});

export const fetchAgentAssistSuccess = (streamId, response) => ({
	type: FETCH_AGENT_ASSIST_SUCCESS,
	payload: ({streamId, response}),
});

export const fetchAgentAssistDone = (response) => ({
	type: FETCH_AGENT_ASSIST_DONE,
	payload: ({response}),
});

export const fetchAgentAssistFailure = (error) => ({
	type: FETCH_AGENT_ASSIST_FAILURE,
	payload: error,
});

export const fetchAgentAssistInfo = (info) => ({
	type: FETCH_AGENT_ASSIST_INFO,
	payload: info,
});

export const updateAgentAssistPrompt = (prompt, streamId) => ({
	type: UPDATE_AGENT_ASSIST_PROMPT,
	payload: ({prompt, streamId}),
});

export const updateAgentAssistResponse = (response) => ({
	type: UPDATE_AGENT_ASSIST_RESPONSE,
	payload: response,
});

export const updateAgentAssistReceived = (received, streamId) => ({
	type: UPDATE_AGENT_ASSIST_RECEIVED,
	payload: ({received, streamId}),
});
